/* eslint-disable  @typescript-eslint/no-explicit-any */
import api from '@/plugins/axiosApi';
import { TopBrandsAdvancedSearchReq, TreeChartItemResponse } from '@/api/types';

export const fetchTopClaimsTreeChartData = async (params: any) => {
  const jsonDataParams = params.params;
  const jsonDataCountries = jsonDataParams.countryCodes
    ? jsonDataParams.countryCodes
    : [];
  const jsonDataDefinition = jsonDataParams.reqDefinition;
  const jsonDataFilters = jsonDataParams.filterIds
    ? jsonDataParams.filterIds
    : [];
  const jsonData = {
    _page: 1,
    _limit: 50,
    'filter-ids': jsonDataFilters,
    'country-codes': jsonDataCountries,
    'request-definition-name': jsonDataDefinition,
    timeRange: jsonDataParams.timeRange,
  };
  const resObj = await api.post(`/api/insights/top-ranking`, jsonData);
  return { chartData: resObj.data.results as TreeChartItemResponse[] };
};

export const fetchTopBrands = async (params: TopBrandsAdvancedSearchReq) => {
  const options: any = params.params;
  const jsonData = {
    lang: options.filter(function (item: any) {
      return item.name === 'lang';
    })[0].value,
    topicIds: params.topicIds,
    categoryId: params.categoryId,
    subCategoryId: params.subCategoryId,
    marketId: params.marketId,
    timeRange: params.timeRange,
  };

  const resObj: any = await api.post(
    '/api/insights/topic-top-brands',
    jsonData
  );
  if (resObj) {
    return resObj.data.brands;
  }
};
