import { ReleasedRegion } from '@/typings/common';

export const GREY_IMAGE =
  'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAIAAAC2BqGFAAABhGlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV/TSkupiNhBxCFD62RBVMRRqlgEC6Wt0KqDyaVf0KQhaXFxFFwLDn4sVh1cnHV1cBUEwQ8QJ0cnRRcp8X9JoUWMB8f9eHfvcfcOEFpVppq+CUDV6kY6ERdz+VXR/wofBhFEFAGJmXoys5iF6/i6h4evdzGe5X7uz9GvFEwGeETiOaYbdeIN4pnNus55nzjMypJCfE48btAFiR+5Ljv8xrlks8Azw0Y2PU8cJhZLPSz3MCsbKvE0cURRNcoXcg4rnLc4q9UG69yTvzBU0FYyXKc5igSWkEQKImQ0UEEVdcRo1Ugxkab9uIt/xPanyCWTqwJGjgXUoEKy/eB/8Ltbszg16SSF4kDfi2V9RAH/LtBuWtb3sWW1TwDvM3Cldf21FjD7SXqzq0WOgIFt4OK6q8l7wOUOMPykS4ZkS16aQrEIvJ/RN+WBoVsguOb01tnH6QOQpa6Wb4CDQ2CsRNnrLu8O9Pb275lOfz9IRHKWSYM6vQAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAAd0SU1FB+QEEQosBRjof/kAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAsElEQVR42u3QMREAAAgEILV/3d9N4blABDpJcW8UiBaNaNGiES0a0aJFI1o0okWLRrRoRIsWjWjRiBYtGtGiES1aNKJFI1q0aESLRrRo0YgWjWjRohEtGtGiRSNaNKJFi0a0aESLFo1o0YgWLRrRohEtWjSiRSNatGhEi0a0aNGIFo1o0aIRLRrRokUjWjSiRYtGtGhEixaNaNGIFi0a0aIRLVo0okUjWrRoRItG9L8F7p0Dz8eWKaIAAAAASUVORK5CYII=';

export const GREY_IMAGE_240X120 =
  'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAPAAAAB4CAIAAABD1OhwAAABhGlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV/TSkupiNhBxCFD62RBVMRRqlgEC6Wt0KqDyaVf0KQhaXFxFFwLDn4sVh1cnHV1cBUEwQ8QJ0cnRRcp8X9JoUWMB8f9eHfvcfcOEFpVppq+CUDV6kY6ERdz+VXR/wofBhFEFAGJmXoys5iF6/i6h4evdzGe5X7uz9GvFEwGeETiOaYbdeIN4pnNus55nzjMypJCfE48btAFiR+5Ljv8xrlks8Azw0Y2PU8cJhZLPSz3MCsbKvE0cURRNcoXcg4rnLc4q9UG69yTvzBU0FYyXKc5igSWkEQKImQ0UEEVdcRo1Ugxkab9uIt/xPanyCWTqwJGjgXUoEKy/eB/8Ltbszg16SSF4kDfi2V9RAH/LtBuWtb3sWW1TwDvM3Cldf21FjD7SXqzq0WOgIFt4OK6q8l7wOUOMPykS4ZkS16aQrEIvJ/RN+WBoVsguOb01tnH6QOQpa6Wb4CDQ2CsRNnrLu8O9Pb275lOfz9IRHKWSYM6vQAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB+QEEQosME5bu9oAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAA+ElEQVR42u3SQQ0AAAjEMMC/3ftjg5BWwrJOUvDFSIChwdBgaDA0hgZDg6HB0GBoDA2GBkODocHQGBoMDYYGQ4OhMTQYGgwNhgZDY2gwNBgaDA2GxtBgaDA0GBoMjaHB0GBoMDSGBkODocHQYGgMDYYGQ4OhwdAYGgwNhgZDg6ExNBgaDA2GBkNjaDA0GBoMDYbG0GBoMDQYGgyNocHQYGgwNBgaQ4OhwdBgaAwNhgZDg6HB0BgaDA2GBkODoTE0GBoMDYYGQ2NoMDQYGgwNhsbQYGgwNBgaDI2hwdBgaDA0GBpDg6HB0GBoDA2GBkODocHQGBoMDbcsUbgDzzQxf4gAAAAASUVORK5CYII=';

export const CHART_COLORS = {
  red: '#B71C1C',
  orange: '#E65100',
  yellow: 'rgb(255, 205, 86)',
  pink: 'rgb(216, 27, 96)',
  green: 'rgb(67,160,71)',
  lightgreen: 'rgb(124,179,66)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  brown: 'rgb(109, 76, 65)',
  grey: 'rgb(201, 203, 207)',
};

export const SHADES_OF_RED = [
  '#c50022',
  '#D02F4B',
  '#D54861',
  '#DD6A7E',
  '#e27f90',
  '#E6919F',
  '#EBA6B2',
  '#f1bfc7',
  '#f4cfd5',
  '#f8e0e4',
];

export const WORLD_MAP_COUNTRIES: Record<string, string> = {
  Afghanistan: 'AF',
  Albania: 'AL',
  Algeria: 'DZ',
  'American Samoa': 'AS',
  Andorra: 'AD',
  Angola: 'AO',
  Argentina: 'AR',
  Armenia: 'AM',
  Australia: 'AU',
  Austria: 'AT',
  Azerbaijan: 'AZ',
  Bahamas: 'BS',
  Bahrain: 'BH',
  Bangladesh: 'BD',
  Barbados: 'BB',
  Belarus: 'BY',
  Belgium: 'BE',
  Belize: 'BZ',
  Benin: 'BJ',
  Bermuda: 'BM',
  Bhutan: 'BT',
  Bolivia: 'BO',
  'Bosnia and Herz.': 'BA',
  Botswana: 'BW',
  Brazil: 'BR',
  Brunei: 'BN',
  Bulgaria: 'BG',
  'Burkina Faso': 'BF',
  Burundi: 'BI',
  Cambodia: 'KH',
  Cameroon: 'CM',
  Canada: 'CA',
  'Cayman Is.': 'KY',
  'Central African Rep.': 'CF',
  Chad: 'TD',
  Chile: 'CL',
  China: 'CN',
  Colombia: 'CO',
  Comoros: 'KM',
  Congo: 'CG',
  'Dem. Rep. Congo': 'CD',
  'Costa Rica': 'CR',
  "Côte d'Ivoire": 'CI',
  Croatia: 'HR',
  Cuba: 'CU',
  Curaçao: 'CW',
  Cyprus: 'CY',
  'Czech Rep.': 'CZ',
  'Dem. Rep. Korea': 'KP',
  Denmark: 'DK',
  Djibouti: 'DJ',
  Dominica: 'DM',
  'Dominican Rep.': 'DO',
  Ecuador: 'EC',
  Egypt: 'EG',
  'El Salvador': 'SV',
  'Eq. Guinea': 'GQ',
  Eritrea: 'ER',
  Estonia: 'EE',
  Ethiopia: 'ET',
  'Falkland Is.': 'FK',
  Fiji: 'FJ',
  Finland: 'FI',
  France: 'FR',
  Gabon: 'GA',
  Gambia: 'GM',
  Georgia: 'GE',
  Germany: 'DE',
  Ghana: 'GH',
  Greece: 'GR',
  Greenland: 'GL',
  Grenada: 'GD',
  Guam: 'GU',
  Guatemala: 'GT',
  Guinea: 'GN',
  'Guinea-Bissau': 'GW',
  Guyana: 'GY',
  Haiti: 'HT',
  'Heard I. and McDonald Is.': 'HM',
  Honduras: 'HN',
  Hungary: 'HU',
  Iceland: 'IS',
  India: 'IN',
  Indonesia: 'ID',
  Iran: 'IR',
  Iraq: 'IQ',
  Ireland: 'IE',
  'Isle of Man': 'IM',
  Israel: 'IL',
  Italy: 'IT',
  Jamaica: 'JM',
  Japan: 'JP',
  Jersey: 'JE',
  Jordan: 'JO',
  Kazakhstan: 'KZ',
  Kenya: 'KE',
  Kiribati: 'KI',
  Korea: 'KR',
  Kuwait: 'KW',
  Kyrgyzstan: 'KG',
  'Lao PDR': 'LA',
  Latvia: 'LV',
  Lebanon: 'LB',
  Lesotho: 'LS',
  Liberia: 'LR',
  Libya: 'LY',
  Liechtenstein: 'LI',
  Lithuania: 'LT',
  Luxembourg: 'LU',
  Macedonia: 'MK',
  Madagascar: 'MG',
  Malawi: 'MW',
  Malaysia: 'MY',
  Mali: 'ML',
  Malta: 'MT',
  Mauritania: 'MR',
  Mauritius: 'MU',
  Mexico: 'MX',
  Micronesia: 'FM',
  Moldova: 'MD',
  Monaco: 'MC',
  Mongolia: 'MN',
  Montenegro: 'ME',
  Montserrat: 'MS',
  Morocco: 'MA',
  Mozambique: 'MZ',
  Myanmar: 'MM',
  Namibia: 'NA',
  Nepal: 'NP',
  Netherlands: 'NL',
  'New Caledonia': 'NC',
  'New Zealand': 'NZ',
  Nicaragua: 'NI',
  Niger: 'NE',
  Nigeria: 'NG',
  Niue: '',
  'N. Mariana Is.': 'MP',
  Norway: 'NO',
  Oman: 'OM',
  Pakistan: 'PK',
  Palau: 'PW',
  Palestine: 'PS',
  Panama: 'PA',
  'Papua New Guinea': 'PG',
  Paraguay: 'PY',
  Peru: 'PE',
  Philippines: 'PH',
  Poland: 'PL',
  Portugal: 'PT',
  'Puerto Rico': 'PR',
  Qatar: 'QA',
  Romania: 'RO',
  Russia: 'RU',
  Rwanda: 'RW',
  'Saint Helena': 'SH',
  'Saint Lucia': 'LC',
  Samoa: 'WS',
  'Saudi Arabia': 'SA',
  Senegal: 'SN',
  Serbia: 'RS',
  Seychelles: 'SC',
  'Sierra Leone': 'SL',
  Singapore: 'SG',
  Slovakia: 'SK',
  Slovenia: 'SI',
  'Solomon Is.': 'SB',
  Somalia: 'SO',
  'South Africa': 'ZA',
  'S. Sudan': 'SS',
  Spain: 'ES',
  'Sri Lanka': 'LK',
  Sudan: 'SD',
  Suriname: 'SR',
  Sweden: 'SE',
  Switzerland: 'CH',
  Syria: 'SY',
  Tajikistan: 'TJ',
  Tanzania: 'TZ',
  Thailand: 'TH',
  'Timor-Leste': 'TL',
  Togo: 'TG',
  Tonga: 'TO',
  'Trinidad and Tobago': 'TT',
  Tunisia: 'TN',
  Turkey: 'TR',
  Turkmenistan: 'TM',
  'Turks and Caicos Is.': 'TC',
  Uganda: 'UG',
  Ukraine: 'UA',
  'United Arab Emirates': 'AE',
  'United Kingdom': 'GB',
  'United States': 'US',
  Uruguay: 'UY',
  Uzbekistan: 'UZ',
  Vanuatu: 'VU',
  Venezuela: 'VE',
  Vietnam: 'VN',
  'U.S. Virgin Is.': 'VI',
  'W. Sahara': 'EH',
  Yemen: 'YE',
  Zambia: 'ZM',
  Zimbabwe: 'ZW',
};

export const COUNTRY_NUMBER_BY_KEY = {
  AF: 4,
  AL: 8,
  DZ: 12,
  AS: 16,
  AD: 20,
  AO: 24,
  AR: 32,
  AM: 51,
  AU: 36,
  AT: 40,
  AZ: 31,
  BS: 44,
  BH: 48,
  BD: 50,
  BB: 52,
  BY: 112,
  BE: 56,
  BZ: 84,
  BJ: 204,
  BM: 60,
  BT: 64,
  BO: 68,
  BA: 70,
  BW: 72,
  BR: 76,
  BN: 96,
  BG: 100,
  BF: 854,
  BI: 108,
  KH: 116,
  CM: 120,
  CA: 124,
  KY: 136,
  CF: 140,
  TD: 148,
  CL: 152,
  CN: 156,
  CO: 170,
  KM: 174,
  CG: 178,
  CD: 180,
  CR: 188,
  CI: 384,
  HR: 191,
  CU: 192,
  CW: 531,
  CY: 196,
  CZ: 203,
  KP: 408,
  DK: 208,
  DJ: 262,
  DM: 212,
  DO: 214,
  EC: 218,
  EG: 818,
  SV: 222,
  GQ: 226,
  ER: 232,
  EE: 233,
  ET: 231,
  FK: 238,
  FJ: 242,
  FI: 246,
  FR: 250,
  GA: 266,
  GM: 270,
  GE: 268,
  DE: 276,
  GH: 288,
  GR: 300,
  GL: 304,
  GD: 308,
  GU: 316,
  GT: 320,
  GN: 324,
  GW: 624,
  GY: 328,
  HT: 332,
  HM: 334,
  HN: 340,
  HU: 348,
  IS: 352,
  IN: 356,
  ID: 360,
  IR: 364,
  IQ: 368,
  IE: 372,
  IM: 833,
  IL: 376,
  IT: 380,
  JM: 388,
  JP: 392,
  JE: 832,
  JO: 400,
  KZ: 398,
  KE: 404,
  KI: 296,
  KR: 410,
  KW: 414,
  KG: 417,
  LA: 418,
  LV: 428,
  LB: 422,
  LS: 426,
  LR: 430,
  LY: 434,
  LI: 438,
  LT: 440,
  LU: 442,
  MK: 807,
  MG: 450,
  MW: 454,
  MY: 458,
  ML: 466,
  MT: 470,
  MR: 478,
  MU: 480,
  MX: 484,
  FM: 583,
  MD: 498,
  MC: 492,
  MN: 496,
  ME: 499,
  MS: 500,
  MA: 504,
  MZ: 508,
  MM: 104,
  NA: 516,
  NP: 524,
  NL: 528,
  NC: 540,
  NZ: 554,
  NI: 558,
  NE: 562,
  NG: 566,
  MP: 580,
  NO: 578,
  OM: 512,
  PK: 586,
  PW: 585,
  PS: 275,
  PA: 591,
  PG: 598,
  PY: 600,
  PE: 604,
  PH: 608,
  PL: 616,
  PT: 620,
  PR: 630,
  QA: 634,
  RO: 642,
  RU: 643,
  RW: 646,
  SH: 654,
  LC: 662,
  WS: 882,
  SA: 682,
  SN: 686,
  RS: 688,
  SC: 690,
  SL: 694,
  SG: 702,
  SK: 703,
  SI: 705,
  SB: 90,
  SO: 706,
  ZA: 710,
  SS: 728,
  ES: 724,
  LK: 144,
  SD: 729,
  SR: 740,
  SE: 752,
  CH: 756,
  SY: 760,
  TJ: 762,
  TZ: 834,
  TH: 764,
  TL: 626,
  TG: 768,
  TO: 776,
  TT: 780,
  TN: 788,
  TR: 792,
  TM: 795,
  TC: 796,
  UG: 800,
  UA: 804,
  AE: 784,
  GB: 826,
  US: 840,
  UY: 858,
  UZ: 860,
  VU: 548,
  VE: 862,
  VN: 704,
  VI: 850,
  EH: 732,
  YE: 887,
  ZM: 894,
  ZW: 716,
};

export const WORLD_MAP_COUNTRIES_BY_KEY = Object.keys(
  WORLD_MAP_COUNTRIES
).reduce((ret, key) => {
  const val = WORLD_MAP_COUNTRIES[key];
  ret[val] = key;
  return ret;
}, {} as Record<string, string>);

export const INGREDIENT_BLACK_LIST = [
  'aqua',
  'aqua (purified)',
  'parfum',
  'fragrance',
  'sodium chloride',
];

export const WOLRD_MAP_BLACK_LIST = [
  'HK', //Honk Kong
  'MC', //Monaco
  //'SM', //San Marino
];

export const DEFAULT_WORLD_MAP_COUNTRY = {
  value: 'S:S:50B344808D84E492505C01EA2A81B0EB',
  label: 'USA',
};

export const COUNTRY_WHITE_LIST = [
  'Jpn:Jpn:A90EF527A62B03839C3D9003746EB7C0',
  'SthFrc:Zf:F9CE79C01DAF5F98D9CB68999538545D',
  'Frnc:Fr:09148A9828DE4DC310069E214C84E4D8',
  'Spn:Sp:E0EB33EC8E3875C59204D0BECEC1060E',
  'Rgntn:Rg:1FCC7089386EDCC043F3A92333E2F055',
  'Tl:T:574B3F99C7A44173B9BCD35BF0A1732B',
  'Cnd:Cn:C86D61912C4AA085D32C581E56A850C4',
  'Rss:Rs:14611AFAA6EF836C156DBABFB28C1DB3',
  'NtdKngdm:Gbr:BC0495FAB0EA95F18B4AF8913B0D98F5',
  'SthKr:Kr:49D3B516D6B6ED096156C229C46AFAE4',
  'Mxc:Mx:57272E212CAEFD2BE7324506541CD308',
  'Ngr:Ng:713BD41D719DF13BE4ED47CFAA7B6F44',
  'Trk:Tr:2E667BA38FCE8C572633B5F236CD9A79',
  'Grmn:D:5822C3F9A31877A79D9BC4B435B8B81B',
  'Brzl:Br:96DD9EBB3183D4B27E331097CE88DE25',
  'Nd:Nd:5C2973796D3EF8D7F36DC890F789EE89',
  'S:S:50B344808D84E492505C01EA2A81B0EB',
  'Chn:Chn:931DB814C9F651297FC1B3F2053A8BB0',
  'Strl:S:708965CCC57E66AD198C4BE612F1F685',
  'Crt:Hrv:4649736882F3A0F2FCED5851FD926084',
  'Plnd:Pl:08D37EDEF087817D4D612735174AB79E',
];

export const RELEASED_REGIONS: ReleasedRegion[] = [
  {
    id: 'SPcfc:Rgn:E937C38F5B907CC61D2CEE50C971E043',
    name: 'Asia Pacific',
    i18n: 'asia-pacific',
    selectable: true,
  },
  {
    id: 'MddlStFrc:Rgn:2CFA0B0F9E935AF7472F2BF16DC5D0F0',
    name: 'Middle East & Africa',
    i18n: 'middle-east-africa',
    selectable: true,
  },
  {
    id: 'Rp:Rgn:18E2B08CDB6F1863C5BEE875C68B21DF',
    name: 'Europe',
    i18n: 'europe',
    selectable: true,
  },
  {
    id: 'NrthMrc:Rgn:D691563DFD302B97DF2AC723CAF20BFA',
    name: 'North America',
    i18n: 'north-america',
    selectable: true,
  },
  {
    id: 'LtnMrc:Rgn:37AAC8446D66D5CEC139DF732E67786B',
    name: 'Latin America',
    i18n: 'latin-america',
    selectable: true,
  },
];

export enum EMOLLIENT_JOCKEY_FILTERS {
  AVERAGE_MOLECULAR_WEIGHT = 'averageMolecularWeight',
  EQUIVALENT_ALKANE_CARBON_NUMBER = 'equivalentAlkaneCarbonNumber',
  INTERFACIAL_TENSION = 'interfacialTension',
  REFRACTIVE_INDEX = 'refractiveIndex',
  SPREADABILITY = 'spreadability',
  SURFACE_TENSION = 'surfaceTension',
  VISCOSITY = 'viscosity',
  DENSITY = 'density',
  DISTRIBUTION = 'distribution',
  GLIDING = 'gliding',
  STICKINESS = 'stickiness',
  WAXINESS = 'waxiness',
  ACCEPTANCE = 'acceptance',
  ABSORPTION1MIN = 'absorption1min',
  ABSORPTION3MIN = 'absorption3min',
  STICKINESS3MIN = 'stickiness3min',
  WAXINESS3MIN = 'waxiness3min',
  POWDERY3MIN = 'powdery3min',
  LIGHTSKINFEEL3MIN = 'lightSkinFeel3min',
  CAREDFORFEEL3MIN = 'caredForFeel3min',
}

export const ROLES = {
  DL_FREEMIUM: 'DL_FREEMIUM',
  DL_DEMO: 'DL_DEMO',
  DL_STANDARD_3RD: 'DL_STANDARD_3RD',
  DL_MAESTRO: 'DL_MAESTRO',
  DL_TRIAL: 'DL_TRIAL',
};

export const RESTRICTED_ROLES = [ROLES.DL_FREEMIUM, ROLES.DL_DEMO];

export const ANALYTIC_OPTIONS = {
  SECTIONS: {
    BRAND_ANALYTICS: 'Brand Analytics',
    CONCEPT_COLLECTION: 'Concepts Collection',
    CONSUMER_INSIGHTS: 'Consumer Insights',
    CONSUMER_PRODUCTS: 'Consumer Products',
    LEARN_MORE: 'Learn More',
    EMOLLIENT_JOCKEY: 'Emollient Jockey',
    EMOLLIENT_MAESTRO: 'Emollient Maestro',
    FORMULATION_DESIGN: 'Formulation Design',
    INGREDIENT_SELECTION: 'Ingredient Selection',
    MARKET_INSIGHTS: 'Market Insights',
    MY_DLITE: 'My Dlite',
    SURFACTANT_NAVIGATOR: 'Surfactant Navigator',
    COMPOSITIONS: 'Ingredients Revealed',
    TRENDS: 'Trends',
  },
  // Tabs
  SUB_SECTIONS: {
    BASF_PRODUCTS: 'BASF Products',
    BRAND_LIST: 'Brand List',
    CONSUMER_TYPE: 'Consumer Type',
    LIFESTYLE: 'Lifestyle',
    INCI: 'INCI',
    PRODUCT_LIST: 'Product List',
    TOP_DISCUSSED_TOPICS: 'Top discussed topics',
    TOP_BRANDS: 'Top Brands',
    NEW_CONCEPTS: 'New Concepts',
    NEW_TRENDS: 'New Trends',
    FORMULATIONS_OVERVIEW: 'Formulation Design Overview',
    MY_SEARCH: 'My Search',
    MY_COMPARISON: 'My Comparison',
    MY_PROJECTS: 'My Projects',
    SURFACTANT_FINDER: 'Surfactant Finder',
    SURFACTANT_OPTIMIZER: 'Smart Surfactant Replacement',
    SURFACTANT_EXTENDED: 'Smart Surfactant Optimizer',
    EMOLLIENT_JOCKEY_LIST: 'LIST',
    EMOLLIENT_JOCKEY_RADAR: 'RADAR CHART',
    EMOLLIENT_JOCKEY_MATRIX: 'MATRIX',
    EMOLLIENT_MAESTRO_EASY: 'EASY MODE',
    EMOLLIENT_MAESTRO_ADVANCED: 'ADVANCED MODE',
    CUSTOM_FORMULATIONS: 'Formulations',
    CUSTOM_INGREDIENTS: 'Ingredients',
  },
  PAGE_SUBCATEGORY: {
    INGREDIENT_DETAILS: 'Ingredient Details',
    BASF_PRODUCT_DETAIL: 'BASF Product Details',
    BRAND_DETAILS: 'Brand Details',
    CONSUMER_PRODUCT_DETAIL: 'Consumer Product Details',
    CONCEPT_COLLECTION_DETAIL: 'Concepts Collection Details',
    TREND_DETAIL: 'Trend Detail',
    FORMULATION_DETAIL: 'Formulation Details',
  },
  PAGE_TYPE: {
    SELECTION: 'selection page',
    PRODUCT: 'product page',
  },
  PAGE_IDENTIFIER: {
    APP: 'app',
  },
};

// TODO: temporarily disable these categories until the backend it's fixed
export const categoriesIdsBlacklist = [
  'DprsFmn:WrldCtg:59BD56415D8678BFE7C4677191B4C7AB',
  'ShvngDp:WrldCtg:D8793A3824D0FB8BF06BFFE401208C43',
  'Frgrncs:WrldCtg:0F0A4EC3CAB2907CB6D5397E59A78879',
  'PtPrdct:WrldCtg:860EE4488B6DB7053D8D6E35E41C5681', //Pet Products
];
// TODO: temporarily disable these sub-categories until the backend it's fixed
export const subCategoriesIdsBlacklist = [
  // Colour cosmetics
  'ClrCsmt:WrldCtg:5B982CD5A4FB9EF6AD1CDB6338EEDC7F',
  'BdClrCs:WrldCtg:011B1BECCA978C3C80DC7219DA078AB1',
  'FcBlshB:WrldCtg:C3D06A79E5617F6AAE3D600DB699577C',
  'FcFndtn:WrldCtg:9463C9678F2682358F1B0059DD170F8D',
  'FcPrmrL:WrldCtg:256062E638A84133FB6A0BE8B8494D27',
  'ClrCsmt:WrldCtg:CEBF3E0874E05098DBCAFBCDCF810FFF',
  'FcClrCs:WrldCtg:34DA47AD46ECC135A094FF30593B79A5',
  'FcClrCs:WrldCtg:47851E9E4239EC33D647972E2C509594',
  'MltS:WrldCtg:D4108D84E794795C6B4CE52CE00FCBFE',
  'FcClrCs:WrldCtg:2ADA554BE03EBC39B5DB8BF04ADF6CB2',
  // Oral Hygiene
  'DntlNcl:WrldCtg:A3F0C4344BC2A7A81D9C0846FE7A970B',
  'Tthbrsh:WrldCtg:856FBC927DF24C75A0D5A3F4CF9566FB',
  // Skincare
  'Clnsrs:WrldCtg:9AF34E5B0A66A7C845269451C7B8F40C',
  'LpCr:WrldCtg:C9BFDC892F6E0D3A36BDEBD0689B8515',
  'NlNmlRm:WrldCtg:7C70901815164A5E641BE124E0DD7A3A',
  'Sts:WrldCtg:B1AA66B2FDAF472D0AB272690D3873F9',
  'Cr:WrldCtg:27C48922B70C5EFC5122ECA9B26608A0',
  // Soap & bath
  'BrSp:WrldCtg:5B88BBDE07E2377E07A16584F4D7897F',
  'BthDdtv:WrldCtg:AD0FD4810ABB6DBBAEFDDCB16F8C9398',
  'LqdSp:WrldCtg:7686C1E32F0D3A6AA846562924BD13B8',
  //Pet Products
  'PtPrdct:WrldCtg:860EE4488B6DB7053D8D6E35E41C5681',
];
// TODO: temporarily disable these formats until the backend it's fixed
export const formatsBlacklist = [
  'Mpl:WrldFrm:C4EBADBA5CB8A84DF1AC47E8823E0718',
  'Bttl:WrldFrm:90C6823B4005257590BC6AC7E6673BDA',
  'Ck:WrldFrm:B0ACDA2D47704C6071999ECDBB0E6962',
  'Cpsl:WrldFrm:9A28ADEEC3087B0FAE3BB5F6A7F8B857',
  'Cshn:WrldFrm:2EA62E7B63CAA0D88F860500794C953B',
  'Drps:WrldFrm:4BDFD3ECEA2ACADFB83478DC5B2616C7',
  'GrnlsSl:WrldFrm:F415C4E20DA7BD3DCE6DFD6B61741103',
  'JrPt:WrldFrm:D1509DCD7AB805FAC41C0A9FD4BDF114',
  'Lzng:WrldFrm:272E12F7A1FE848C006F736383B3836B',
  'NnNgstd:WrldFrm:51BD747A8AA059EE168DDD20AAE5E860',
  'Thr:WrldFrm:5C1650F9D09573E3BFD4B8795F4DEA78',
  'Pd:WrldFrm:4DA454082EE6B4C2E058E15B9DF3DD12',
  'PprShts:WrldFrm:732040EB4F55F2F1C54DAF5E07000175',
  'PstPttG:WrldFrm:6EFE49EA0036A9A5AB03EAFBE48F19B7',
  'Pn:WrldFrm:8F1F300B7BD0E7CB3FB093CA901E5F0C',
  'Pmd:WrldFrm:DF8F822CD108EDD2461B06492EF23B88',
  'Sltn:WrldFrm:5F374436E3FFCEB8C1C199B57361181E',
  'Srbt:WrldFrm:1D6D7004822608D3E1E2AC1D6C194192',
  'StrpsPt:WrldFrm:C052A35CFE9DC0F69A4DE311C6687255',
  'TssClth:WrldFrm:47EF869B811A4AED4303FFB4773C07EF',
  'Wtr:WrldFrm:7A8C42031D16AF0055200A350ECB0C85',
  'Whppd:WrldFrm:79793FC236BBEEAFCA496AB2536BF9CB',
  'PnclCrn:WrldFrm:4F813FF4033BA73BE5325B35B50780B9',
];
