<template>
  <button
    :class="[
      'base-button',
      {
        'base-button--regular': regular,
        'base-button--rounded': rounded,
        'base-button--outlined': outlined,
        'base-button--link': link,
        'base-button--uppercase': uppercase,
        'base-button--disabled': disabled,
      },
    ]"
    :disabled="disabled"
    @click="($event) => onClick($event)"
  >
    <BaseIcon
      v-if="iconLeft"
      :icon="iconLeft"
      class="base-button__icon"
    ></BaseIcon>
    <slot>{{ label }}</slot>
    <BaseIcon
      v-if="iconRight"
      :icon="iconRight"
      class="base-button__icon"
    ></BaseIcon>
  </button>
</template>

<script setup lang="ts">
import BaseIcon from '@/components/common/base/BaseIcon.vue';

interface Props {
  label?: string;
  iconLeft?: string;
  iconRight?: string;
  rounded?: boolean;
  outlined?: boolean;
  link?: boolean;
  regular?: boolean;
  uppercase?: boolean;
  disabled?: boolean;
  stop?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits(['click']);

const onClick = (evt: MouseEvent) => {
  if (props.stop) {
    evt.stopPropagation();
  }
  emit('click');
};
</script>

<style lang="scss" scoped>
.base-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding-inline: 1rem;
  padding-block: 0.5rem;
  background-color: var(--primary-color, var(--base));
  color: var(--white, white);
  font-size: 1rem;
  font-weight: 500;
  user-select: none;

  &--regular {
    font-weight: unset;
  }

  &--uppercase {
    text-transform: uppercase;
    letter-spacing: 0.0892857143em;
  }

  &--rounded {
    border-radius: 2rem;
  }

  &--outlined {
    color: var(--primary-color, var(--base));
    background-color: transparent;
    border: 1px solid var(--primary-color, var(--base));
  }

  &--link {
    color: var(--primary-color, var(--base));
    background-color: transparent;
    border: none;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: transparent;
    transition: text-decoration-color 0.15s ease-out;

    &:hover {
      text-decoration-color: unset;
    }
  }

  &--disabled {
    cursor: default;
    background-color: var(--atoms-basf-gray-200);
    border-color: transparent;
    color: var(--atoms-basf-gray-600);

    .base-button__icon {
      color: var(--atoms-basf-gray-600);
    }
  }

  &__icon {
    width: 1rem;
    height: 1rem;
  }
}
</style>
