/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia';
import { MyProjectBase, MyProjectType, MyProjectDetail } from '@/api/types';
import { deliverPreferences, fetchPreferences } from '@/api';

interface State {
  myProjectBases: Array<MyProjectBase>;
  myProjectType: MyProjectType;
  myProjectDetail: MyProjectDetail;
}

export const useMyProjectStore = defineStore('myProject', {
  state: (): State => ({
    myProjectBases: [
      { id: '0', name: 'DLITE 1', active: true },
      { id: '1', name: 'DLITE 2', active: false },
      { id: '2', name: 'DLITE 3', active: false },
      { id: '3', name: 'DLITE 4', active: false },
      { id: '4', name: 'DLITE 5', active: false },
      { id: '5', name: 'DLITE 6', active: false },
    ],
    myProjectType: {
      id: '0',
      name: 'Default',
      active: true,
      type: '',
      details: [],
    },
    myProjectDetail: {
      id: '',
      type: '',
      img: '',
      size: 3,
      data: [],
    },
  }),
  getters: {
    getActiveProject() {
      let id = '0';
      this.myProjectBases.forEach((prj: any) => {
        if (prj.active) {
          id = prj.id;
        }
      });
      return id;
    },
  },

  actions: {
    async getProjectType(params: any) {
      const projectId = params[0].value;
      const projectType = params[1].value;
      const param = { lang: 'en', 'no-cache': true };
      const key = 'my-projects-' + projectId + '-' + projectType;
      const responseData = await fetchPreferences(key, param);
      if (responseData) {
        this.myProjectType = JSON.parse(responseData.value);
      } else {
        this.myProjectType = {
          id: '0',
          name: 'Default',
          active: true,
          type: '',
          details: [],
        };
      }
    },

    async addImage(myProjectDetail: MyProjectDetail) {
      let projectBases = this.myProjectBases;
      let projectType = this.myProjectType;
      const projectDetail = myProjectDetail;
      let projectId = '0';
      const params = { lang: 'en' };
      const lang = 'en';

      const key1 = 'my-projects-all';
      const responseProjects = await fetchPreferences(key1, params);
      if (responseProjects) {
        projectBases = JSON.parse(responseProjects.value);
      }
      projectBases.forEach((snapshot: any) => {
        if (snapshot.active === true) {
          projectId = snapshot.id;
        }
      });

      const key2 = 'my-projects-' + projectId + '-' + projectDetail.type;
      const responseTypes = await fetchPreferences(key2, params);
      if (responseTypes) {
        projectType = JSON.parse(responseTypes.value);
      }

      projectType.id = projectId;
      projectType.name = '';
      projectType.active = false;
      projectType.type = projectDetail.type;
      projectType.details.push(projectDetail);

      await deliverPreferences(key2, lang, projectType);
    },

    async saveTypes(myProjectType: MyProjectType) {
      const lang = 'en';
      const key2 = 'my-projects-' + myProjectType.id + '-' + myProjectType.type;
      const responseData = await deliverPreferences(key2, lang, myProjectType);
      if (responseData) {
        this.myProjectType = JSON.parse(responseData.value);
      }
    },

    async saveProjects(myProjectBases: MyProjectBase[]) {
      const key = 'my-projects-all';
      const lang = 'en';
      const responseData = await deliverPreferences(key, lang, myProjectBases);
      if (responseData) {
        this.myProjectBases = JSON.parse(responseData.value);
      }
    },

    async getProjects() {
      const params = { lang: 'en' };
      const key = 'my-projects-all';
      const responseData = await fetchPreferences(key, params);
      if (responseData) {
        this.myProjectBases = JSON.parse(responseData.value);
      }
    },
  },
});
