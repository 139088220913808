<template>
  <button
    ref="moreInformationDialogButton"
    class="more-information-button"
    @click="handleMoreInformationButton"
    @keydown.esc="closeMoreInformationDialog"
  >
    <BaseIcon :icon="ICONS.MORE_VERT" />
    <transition name="fade">
      <dialog
        v-show="showMoreInformationDialog"
        ref="moreInformationDialog"
        class="more-information-dialog"
        :open="showMoreInformationDialog"
      >
        <div class="more-information-dialog__content">
          <SourceDialogMD
            v-if="hasSource"
            in-chart
            :folder="folderNameComputed.sourceFolder"
            :source-content="sourceContent"
            :is-bevol="isBevol"
          >
            {{ $t('app.common.dialog.source') }}
          </SourceDialogMD>
          <InfoDialogMD in-chart :folder="folderNameComputed.infoFolder">
            {{ $t('app.common.dialog.information') }}
          </InfoDialogMD>
          <button
            v-if="hasSnapshot && !isMobile"
            class="more-information-dialog__item"
          >
            <CanvasDialog
              in-chart
              :img-config="snapshotPath"
              :canvas-ref="chartId"
              :img-data="snapshotDataComputed"
              :img-factor="imgFactor"
              @click="showMoreInformationDialog = false"
            >
              {{ $t('app.common.dialog.add-to-my-dlite') }}
            </CanvasDialog>
          </button>
        </div>
      </dialog>
    </transition>
  </button>
</template>

<script setup lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, ref } from 'vue';
import { useI18n } from '@/composables/useI18n';
import { ICONS } from '@/typings/enums';
import { onClickOutside } from '@vueuse/core';
import SourceDialogMD from '@/components/common/SourceDialogMD.vue';
import InfoDialogMD from '@/components/common/InfoDialogMD.vue';
import CanvasDialog from '@/components/common/CanvasDialog.vue';
import BaseIcon from '@/components/common/base/BaseIcon.vue';
import { useResponsive } from '@/composables/useResponsive';

interface FolderStruc {
  infoFolder: string;
  sourceFolder: string;
}

interface Props {
  title?: string;
  chartId?: string;
  snapshotPath?: string;
  snapshotData?: any[];
  folderName?: string | FolderStruc;
  sourceContent?: string;
  imgFactor?: number;
  isBevol?: boolean;
  hasSnapshot?: boolean;
  hasSource?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  folderName: '',
  sourceContent: '',
  snapshotData: () => [],
  imgFactor: 4,
  isBevol: false,
  hasSnapshot: true,
  hasSource: true,
});

const { $t } = useI18n();
const { isMobile } = useResponsive();

const folderNameComputed = computed(() => {
  if (typeof props.folderName === 'string') {
    return {
      infoFolder: props.folderName,
      sourceFolder: props.folderName,
    };
  }
  return props.folderName;
});

const showMoreInformationDialog = ref(false);
const moreInformationDialogButton = ref<HTMLElement | null>(null);
const moreInformationDialog = ref<HTMLDialogElement | null>(null);

const snapshotDataComputed = computed(() => {
  if (props.snapshotData.length > 0) {
    return props.snapshotData;
  }
  return [
    {
      name: 'title',
      textKey: props.title,
    },
  ];
});

onClickOutside(
  moreInformationDialog,
  () => {
    showMoreInformationDialog.value = false;
  },
  { ignore: [moreInformationDialogButton] }
);

const windowScrollEvent = (addScrollEventListener = false) => {
  if (addScrollEventListener) {
    window.addEventListener('scroll', closeMoreInformationDialog);
  } else {
    window.removeEventListener('scroll', closeMoreInformationDialog);
  }
};

const handleMoreInformationButton = () => {
  showMoreInformationDialog.value = !showMoreInformationDialog.value;
  if (showMoreInformationDialog.value) {
    showMoreInformationDialog.value = true;
  }
  windowScrollEvent(showMoreInformationDialog.value);
};

const closeMoreInformationDialog = () => {
  showMoreInformationDialog.value = false;
  windowScrollEvent();
};
</script>

<style lang="scss" scoped>
.more-information-button {
  position: relative;
  width: 20px;
  height: 24px;
}

.more-information-dialog {
  position: absolute;
  border: 0;
  z-index: 10;

  &__content {
    position: absolute;
    right: -16px;
    top: 4px;
    display: grid;
    border: 3px solid rgba(0, 0, 0, 0.25);
    background-color: white;
    padding: 1rem;
  }
}
</style>
