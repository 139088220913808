/* eslint-disable  @typescript-eslint/no-explicit-any */
import { getBase64FromArrayBuffer } from '@/lib/utils';
import api from '@/plugins/axiosApi';

export const fetchImageContentPublic = async (md: string, params: any) => {
  const { data } = await api({
    url: '/public/assets/' + md,
    responseType: 'arraybuffer',
    method: 'get',
    params,
  });
  if (data) return getBase64FromArrayBuffer(data);

  return null;
};

export const fetchMarkdownContentPublic = async (md: string, params: any) => {
  const resObj: any = await api({
    url: '/public/assets/' + md,
    method: 'get',
    params,
  });
  if (resObj && resObj.data) {
    return resObj.data;
  }
};

export const fetchMarkdownContentApi = async (params: any) => {
  const resObj: any = await api({
    url: '/api/assets',
    method: 'get',
    params,
  });
  if (resObj && resObj.data) {
    return resObj.data;
  }
};
