<template>
  <div
    :class="[
      'search-input-wrapper',
      { 'search-active': !isOnlyButton && isExpanded },
    ]"
  >
    <label
      ref="searchInputLabel"
      :class="[
        'search-input-label',
        {
          'rectangle-border': showRectangleBorder,
          colorized,
        },
      ]"
    >
      <button
        v-if="searchIcon"
        class="search-icon-button search-input-mag-button"
        :type="isExpanded ? 'submit' : 'button'"
        :aria-label="isExpanded ? 'submit' : 'expand'"
        @click="searchButtonHandler"
      >
        <img
          :src="`${require('@/assets/icons/search_icon.svg')}`"
          alt="search"
        />
      </button>
      <span v-if="!isOnlyButton" class="search-input">
        <input
          ref="searchBoxInput"
          type="text"
          :value="searchInputField"
          :placeholder="searchPlaceholder"
          :disabled="isDisabled || !isExpanded"
          :class="{ colorized }"
          @keydown.enter="doSearch"
          @[searchEvent]="doSearch"
      /></span>
      <button
        v-if="
          !isOnlyButton && (closeIcon || (closeIconIfText && searchInputField))
        "
        type="button"
        class="search-icon-button search-input-close-button"
        :disabled="!isExpanded"
        aria-label="clear search"
        @pointerdown="closeButtonHandler"
        @keydown.space="closeButtonHandler"
        @keydown.enter="closeButtonHandler"
      >
        <img
          :src="`${require('@/assets/icons/close_icon.svg')}`"
          alt="close search"
        />
      </button>
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watchEffect } from 'vue';
import animejs from 'animejs';
import { onClickOutside } from '@vueuse/core';
import { useResponsive } from '@/composables/useResponsive';
import { useI18n } from '@/composables/useI18n';

interface Props {
  initialValue?: string;
  placeholder?: string;
  expandable?: boolean;
  searchIcon?: boolean;
  closeIcon?: boolean;
  closeIconIfText?: boolean;
  showRectangleBorder?: boolean;
  showSearchButton?: boolean;
  colorized?: boolean;
  isOnlyButton?: boolean;
  ignoreLastSearch?: boolean;
  inmediateSearch?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  initialValue: undefined,
  expandable: false,
  searchIcon: true,
  closeIcon: false,
  closeIconIfText: false,
  showRectangleBorder: false,
  showSearchButton: false,
  colorized: false,
  isOnlyButton: false,
  ignoreLastSearch: false,
  inmediateSearch: false,
});

const emit = defineEmits([
  'handleSearch',
  'handleClick',
  'expand',
  'handleClearSearch',
]);

const { mediumDurationMS, longDurationMS, defaultEaseOut, mediumDuration } =
  useResponsive();
const { $t } = useI18n();

const searchPlaceholder = computed(
  () => props.placeholder || $t('app.common.value.quick-search')
);

const searchInputLabel = ref<HTMLDivElement>();
const searchBoxInput = ref<HTMLInputElement>();
const searchInputField = ref('');
let lastSearch = '';

const isActive = ref(false);
const isDisabled = ref(false);
const isExpanded = computed(() => !props.expandable || isActive.value);
const borderColor = computed(() =>
  props.colorized ? 'var(--base)' : 'var(--atoms-basf-gray-825)'
);
let isClearingSearchField = false;

const searchEvent = computed(() => {
  return props.inmediateSearch ? 'input' : 'change';
});

const expand = (toExpand = true) => {
  emit('expand', toExpand);
  isDisabled.value = !toExpand;
  isActive.value = toExpand;
  animejs({
    targets: ['.search-input', '.search-input-close-button'],
    easing: defaultEaseOut,
    duration: mediumDuration,
    opacity: toExpand ? 1 : 0,
    complete: () => {
      if (toExpand) {
        isDisabled.value = false;
        nextTick(() => {
          if (searchBoxInput.value) {
            searchBoxInput.value.focus();
          }
        });
      }
    },
  });
};

const clearAndRefocus = () => {
  setTimeout(() => {
    searchInputField.value = '';
    isActive.value = true;
    if (searchBoxInput.value) {
      searchBoxInput.value.focus();
    }
    isClearingSearchField = false;
  }, 10);
};

const doSearch = () => {
  searchInputField.value = searchBoxInput.value.value;

  if (props.ignoreLastSearch || searchInputField.value !== lastSearch) {
    setTimeout(() => {
      lastSearch = searchInputField.value;
      if (!isClearingSearchField) {
        emit('handleSearch', searchInputField.value);
      }
      isClearingSearchField = false;
    }, 10);
  }
};

const searchButtonHandler = () => {
  if (props.isOnlyButton) {
    emit('handleClick');
    return;
  }
  if (props.expandable && !isExpanded.value) {
    expand(true);
    return;
  }
  doSearch();
};

const closeButtonHandler = () => {
  isClearingSearchField = true;
  clearAndRefocus();
  emit('handleClearSearch');
  lastSearch = '';
};

onClickOutside(searchInputLabel, () => {
  if (props.expandable && isExpanded.value) {
    expand(false);
  }
});

watchEffect(() => {
  searchInputField.value = props.initialValue || '';
});
</script>

<style scoped lang="scss">
.search-input-wrapper {
  overflow: hidden;
  width: 25px;
  transition: width v-bind(longDurationMS) ease-out;
}
.search-active.search-input-wrapper {
  width: 100%;
}
.search-icon-button {
  height: 18px;
}
.search-input-close-button {
  transform: scale(0.9);
}
.search-input-label {
  border-bottom: 1px solid transparent;
  width: 100%;
  max-width: 640px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  transition: border-color v-bind(mediumDurationMS) ease-out;
}
.search-input-label.rectangle-border {
  border: 1px solid var(--base);
  padding: 0.25rem 0.25rem 0 0.25rem;
}
.search-active .search-input-label {
  border-color: v-bind(borderColor);
}
.search-input {
  width: 100%;
}
.rectangle-border .search-input {
  margin-top: 0.125rem;
}
.search-input input {
  box-sizing: border-box;
  outline-color: transparent;
  outline-width: 0;
  margin: 0 0.5rem;
  width: calc(100% - 1rem);
  height: 100%;
}
.search-input input.colorized::placeholder {
  color: var(--base);
  opacity: 0.6;
}
</style>
