import { defineStore } from 'pinia';
import { Route } from 'vue-router';
import { useProductListStore } from '@/stores/productList';
import { useBrandAnalyticsStore } from '@/stores/brandAnalytics';
import { useTopClaimsStore } from '@/stores/topClaims';
import { useTopBrandsStore } from '@/stores/topBrands';
import { useFormulationListStore } from '@/stores/formulationList';
import { useBasfProductList } from '@/stores/basfProductList';
import { useIngredientStore } from '@/stores/ingredient';
import { useTrendsStore } from '@/stores/trends';
import { useConceptCollectionStore } from '@/stores/conceptCollection';
import { useEmollientJockeyStore } from './emollientJockey';
import { HomeItem } from '@/api/types';
import { useRouter } from '@/composables/useRouter';
import { SectionsIds } from '@/typings/enums';

interface Theme {
  name: string;
  color: string;
}
interface State {
  theme: Theme;
  transitionName: string;
  maintenanceMode: boolean;
  locale: string;
  redirectPath: string;
  appDialog: HTMLDialogElement;
  dialogSectionMobileItem: HomeItem;
}

export const useAppStore = defineStore('app', {
  state: (): State => ({
    theme: {
      name: 'default',
      color: '',
    },
    transitionName: 'fade',
    maintenanceMode: false,
    locale: localStorage.getItem('i18n') || 'en',
    redirectPath: '',
    appDialog: undefined,
    dialogSectionMobileItem: undefined,
  }),

  getters: {
    themeColor(): string {
      return this.theme.name;
    },

    themeHEX(): string {
      return this.theme.color;
    },

    isMaintenanceMode(): boolean {
      return this.maintenanceMode === true;
    },
  },

  actions: {
    setTheme(theme: Theme) {
      this.theme = theme;
    },

    setTransitionName(name: string) {
      this.transitionName = name;
    },

    setMaintenance(isActive: boolean) {
      this.maintenanceMode = isActive;
    },

    setRedirectPath(path: string) {
      this.redirectPath = path;
    },

    updateLocale(locale: string) {
      this.locale = locale;
    },

    resetStoresFilters() {
      const consumerProductsListStore = useProductListStore();
      const brandAnalyticStore = useBrandAnalyticsStore();
      const topClaimsStore = useTopClaimsStore();
      const topBrandsStore = useTopBrandsStore();
      const formulationsListStore = useFormulationListStore();
      const basfProductsListStore = useBasfProductList();
      const ingredientsStore = useIngredientStore();
      const emollientJockeyStore = useEmollientJockeyStore();
      const trendsStore = useTrendsStore();
      const conceptsStore = useConceptCollectionStore();

      consumerProductsListStore.setFetchedDataFilters(false);
      brandAnalyticStore.setFetchedDataFilters(false);
      topClaimsStore.setFetchedDataFilters(false);
      topBrandsStore.setFetchedDataFilters(false);
      formulationsListStore.setFetchedDataFilters(false);
      basfProductsListStore.setFetchedDataFilters(false);
      ingredientsStore.setFetchedDataFilters(false);
      trendsStore.setFetchedDataFilters(false);
      conceptsStore.setFetchedDataFilters(false);

      emollientJockeyStore.setProducts([]);
    },

    changeTheme(route: Route) {
      if (route.name === SectionsIds.LANDING) return;

      const metaInfo = route.matched.find((m) => m.meta)?.meta;

      const appEl = document.querySelector('#main') as HTMLElement;
      const baseColor = getComputedStyle(appEl)
        .getPropertyValue('--base')
        .trim();
      document.documentElement.style.setProperty('--v-primary-base', baseColor);

      this.setTheme({
        name: metaInfo?.themeColor || 'default',
        color: baseColor,
      });
    },

    showDialogSectionMobile(item: HomeItem) {
      // TODO: Remove when FFirefox adds "has" pseudo-class
      document.documentElement.style.setProperty('overflow-y', 'hidden');
      this.dialogSectionMobileItem = item;
      if (this.appDialog) {
        this.appDialog.showModal();
      }
    },

    hideDialogSectionMobile() {
      if (this.appDialog) {
        this.appDialog.close();
      }
    },

    resetDialogSectionMobile() {
      // TODO: Remove when FFirefox adds "has" pseudo-class
      document.documentElement.style.setProperty('overflow-y', 'auto');
      this.dialogSectionMobileItem = undefined;
    },

    ctaClickDialogSectionMobile() {
      const { router } = useRouter();
      router.push(this.dialogSectionMobileItem.path);
      this.hideDialogSectionMobile();
    },
  },
});
