<template>
  <v-app-bar
    app
    flat
    :class="['base-header', { 'big-header': bigHeader }]"
    :style="isHome ? 'background-color: transparent' : ''"
    :height="headerHeight"
  >
    <template v-if="!isHome || (isHome && !bigHeader)">
      <div
        class="base-header-shadow"
        :style="`height: ${headerHeight}px;`"
      ></div>
      <DliteHeaderBackground
        v-if="!isHome"
        :is-home="false"
        :header-height="headerHeight"
      />
      <div
        v-if="isHome && !bigHeader"
        class="base-header-home-background--small"
      >
      </div>
    </template>
    <v-container
      fluid
      class="header-container"
      :style="`height: ${headerHeight}px`"
    >
      <v-row no-gutters class="header-main-row">
        <div ref="headerLeft" class="header-left">
          <button
            v-if="lowerResolution"
            ref="drawerButtonTrigger"
            :class="['mt-2', bigHeader ? 'mr-6' : 'mr-4']"
            :id="mainDrawerMobileButtonTriggerId"
            :aria-expanded="isDrawerExpanded"
            :aria-controls="mainDrawerId"
            :title="`${$t('app.accessibility.open-close-navigation')}`"
            @click="toggleDrawer"
          >
            <svg
              v-show="isDrawerExpanded"
              width="18"
              height="18"
              :alt="`${$t('app.accessibility.close-navigation')}`"
            >
              <use
                :xlink:href="`${require('@/assets/icons/close_icon.svg')}#close_icon`"
                :href="`${require('@/assets/icons/close_icon.svg')}#close_icon`"
              />
            </svg>
            <svg
              v-show="!isDrawerExpanded"
              width="18"
              height="18"
              :alt="`${$t('app.accessibility.open-navigation')}`"
            >
              <use
                :xlink:href="`${require('@/assets/icons/hamburger_icon.svg')}#hamburger_icon`"
                :href="`${require('@/assets/icons/hamburger_icon.svg')}#hamburger_icon`"
              />
            </svg>
          </button>
          <router-link to="/home" class="header-dlite">
            {{ $t('app.common.value.name') }}
          </router-link>
          <div
            v-if="!isHome && !bigHeader"
            :class="[
              'header-module-title',
              screenWidth < Breakpoints.XL && isSearchExpanded
                ? 'opacity-0'
                : 'opacity-100',
            ]"
            v-html="headerAndFooter.moduleTitle"
          ></div>
          <div class="header-icons">
            <SearchBox
              v-if="showSearchBox"
              class="header-search-box"
              :placeholder="`${$t('app.header.search')}`"
              :expandable="searchIsExpandable"
              :close-icon="searchIsExpandable"
              :is-only-button="searchIsOnlyButton"
              @expand="handleExpand"
              @handleSearch="handleSearchQuery"
              @handleClick="handleSearchClick"
            />
            <div class="settings-wrapper">
              <LanguageSelector :hide-selector="hideLanguageSelector" />
              <div class="user-settings">
                <button
                  class="header-user-icon"
                  type="button"
                  aria-label="expand configuration"
                  @click="isConfigurationOpen = true"
                >
                  <img
                    :src="`${require('@/assets/icons/user_icon.svg')}`"
                    alt="expand configuration"
                  />
                </button>
                <SimpleTooltip
                  v-if="isConfigurationOpen"
                  :alignment="bigHeader ? 'center' : 'right'"
                  @close="isConfigurationOpen = false"
                >
                  <slot name="config"></slot>
                </SimpleTooltip>
              </div>
            </div>
          </div>
        </div>
        <transition name="fade-translate-right">
          <div v-if="bigHeader" class="logo-basf-wrapper themed-bg-header">
            <div id="baseHeaderLogos">
              <router-link :to="{ name: SectionsIds.HOME }" class="mx-3">
                <img
                  alt="BASF"
                  class="logo-basf"
                  :src="require('@/assets/logo_basf.svg')"
                />
              </router-link>
            </div>
          </div>
        </transition>
      </v-row>
      <template v-if="!isHome">
        <v-row v-if="bigHeader" no-gutters class="subheader-wrapper">
          <div><!-- space --></div>
          <!-- TODO: Use teleport when available -->
          <div class="subheader" v-html="headerAndFooter.subheader"></div>
          <div><!-- space --></div>
        </v-row>
        <div class="header-bottom-line"></div>
        <v-row
          class="progress-bar"
          no-gutters
          :style="`top: ${headerHeight}px`"
        >
          <v-col cols="12">
            <BaseProgressBar :loading="loading" />
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-app-bar>
</template>

<script setup lang="ts">
import BaseProgressBar from '@/components/common/BaseProgressBar.vue';
import SimpleTooltip from '@/components/common/SimpleTooltip.vue';
import { useI18n } from '@/composables/useI18n';
import { useRouter } from '@/composables/useRouter';
import { useLoaderStore } from '@/stores/loader';
import { useSearchStore } from '@/stores/siteSearch';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import SearchBox from '@/components/common/SearchBox.vue';
import animejs from 'animejs';
import { useWindowScroll } from '@vueuse/core';
import { useHeaderAndFooterStore } from '@/stores/headerAndFooter';
import { storeToRefs } from 'pinia';
import { useResponsive } from '@/composables/useResponsive';
import DliteHeaderBackground from '@/components/common/DliteHeaderBackground.vue';
import LanguageSelector from '@/components/common/LanguageSelector.vue';
import { useMainDrawer } from '@/composables/useMainDrawer';
import { SectionsIds } from '@/typings/enums';

interface Props {
  isLoggedIn: boolean;
  showSearchBox: boolean;
}

const props = defineProps<Props>();

const { router, route } = useRouter();
const { $t } = useI18n();
const {
  defaultEaseOut,
  shortDuration,
  Breakpoints,
  screenWidth,
  screenHeight,
} = useResponsive();
const loaderStore = useLoaderStore();

// HOME EXCEPTION
const isHome = computed(() => route.value.name === SectionsIds.HOME);

// SEARCH COMPONENT PARAMETERS
const searchIsExpandable = ref(true);
const isSearchExpanded = ref(false);
const searchIsOnlyButton = ref(false);

const changeSearchBehaviour = () => {
  if (!searchIsOnlyButton.value && screenWidth.value < Breakpoints.SM) {
    searchIsExpandable.value = false;
    searchIsOnlyButton.value = true;
  } else if (searchIsOnlyButton.value && screenWidth.value >= Breakpoints.SM) {
    searchIsExpandable.value = true;
    searchIsOnlyButton.value = false;
  }
};

// RESOLUTION
const bigHeader = ref(true);
const hideLanguageSelector = ref(false);
const headerAndFooter = useHeaderAndFooterStore();
const { headerHeight } = storeToRefs(headerAndFooter);
const {
  toggleDrawer,
  drawerButtonTrigger,
  mainDrawerId,
  mainDrawerMobileButtonTriggerId,
  isDrawerExpanded,
} = useMainDrawer();

const lowerResolution = computed(() => headerAndFooter.width < Breakpoints.MD);

const heightNotLoggedIn = 70;
const heightNotBigHeader = 56;
const heightBigHeaderMD = 150;
const heightBigHeader = 169;

const getHeaderHeight = () => {
  if (!props.isLoggedIn) {
    return heightNotLoggedIn;
  }
  if (!bigHeader.value) {
    return heightNotBigHeader;
  }
  if (screenWidth.value < Breakpoints.MD) {
    return heightBigHeaderMD;
  }
  return heightBigHeader;
};

// SCROLL
const headerLeft = ref<HTMLDivElement | null>(null);
const setDrawerPosition = () => {
  if (headerLeft.value !== null) {
    headerAndFooter.setDrawerPosition(
      headerLeft.value.getBoundingClientRect().top +
        headerLeft.value.getBoundingClientRect().height
    );
  }
};
const { y: scrollY } = useWindowScroll();
const animationParams: animejs.AnimeParams = {
  easing: defaultEaseOut,
  duration: shortDuration,
};
let isAnimationRunning = false;
const commonHeaderAnimation = () => {
  animejs({
    ...animationParams,
    targets: headerHeight,
    value: getHeaderHeight(),
    update: setDrawerPosition,
    complete: () => {
      isAnimationRunning = false;
      headerAndFooter.setHeaderHeight(Math.round(headerHeight.value));
      setDrawerPosition();
    },
  });
};
const headerAnimation = () => {
  isAnimationRunning = true;
  nextTick(() => {
    commonHeaderAnimation();
    animejs({
      ...animationParams,
      targets: ['.header-main-row', '.header-left'],
      height: bigHeader.value
        ? screenWidth.value < Breakpoints.MD
          ? 64
          : 80
        : heightNotBigHeader,
    });
    animejs({
      ...animationParams,
      targets: '.header-main-row',
      paddingTop: bigHeader.value ? '1rem' : 0,
    });
    animejs({
      ...animationParams,
      targets: ['.base-header-home-background--small', '.base-header-shadow'],
      opacity: [0, 1],
      delay: shortDuration / 2,
    });
    animejs({
      ...animationParams,
      targets: '.header-icons',
      gap: '0.5rem',
      complete: () => {
        isScrollingTop = false;
      },
    });
  });
};
let isScrollingTop = false;
const scrollTop = () => {
  if (!isScrollingTop) {
    if (!bigHeader.value) {
      isScrollingTop = true;
      bigHeader.value = true;
      headerAnimation();
    } else if (
      !isAnimationRunning &&
      headerHeight.value !== getHeaderHeight()
    ) {
      isScrollingTop = true;
      isAnimationRunning = true;
      commonHeaderAnimation();
      animejs({
        ...animationParams,
        targets: ['.header-main-row', '.header-left'],
        height: screenWidth.value < Breakpoints.MD ? 64 : 80,
        complete: () => {
          isScrollingTop = false;
        },
      });
    }
  }
};
const onScroll = () => {
  if (scrollY.value < heightNotBigHeader) {
    scrollTop();
  } else if (
    bigHeader.value &&
    scrollY.value > heightBigHeader - heightNotBigHeader
  ) {
    bigHeader.value = false;
    headerAnimation();
  }
};
watch(scrollY, onScroll);

watch(bigHeader, () => {
  hideLanguageSelector.value = true;
  setTimeout(() => {
    hideLanguageSelector.value = false;
  }, 0);
});

// RESIZE
watch(screenHeight, () => {
  changeSearchBehaviour();
  onScroll();
  setDrawerPosition();
});
watch(screenWidth, () => {
  changeSearchBehaviour();
  if (headerHeight.value !== getHeaderHeight()) {
    headerAndFooter.setFixedHeaderHeight(getHeaderHeight());
    headerAndFooter.setHeaderHeight(getHeaderHeight());
    headerAnimation();
  } else if (headerHeight.value === heightNotBigHeader) {
    headerAndFooter.setFixedHeaderHeight(
      screenWidth.value < Breakpoints.MD ? heightBigHeaderMD : heightBigHeader
    );
  }
});

// LOADING
const loading = ref(false);

const getLoading = computed(() => {
  return loaderStore.loading;
});

watch(getLoading, (newVal, oldVal) => {
  if (newVal === 0) return (loading.value = false);
  if (newVal < 0) return (loading.value = false);
  if (oldVal === 0) return (loading.value = true);
});

// SEARCH
const handleSearchQuery = (searchQuery: string) => {
  if (!searchQuery) return;

  const siteSearchModule = useSearchStore();

  siteSearchModule.setSearchQuery(searchQuery);
  handleSearchClick();
};
const handleSearchClick = () => {
  if (route.value.name !== SectionsIds.MY_SEARCH) {
    router.push({ name: SectionsIds.MY_SEARCH });
  }
};

const handleExpand = (isExpanded: boolean) => {
  isSearchExpanded.value = isExpanded;
};

// CONFIGURATION (user icon)
const isConfigurationOpen = ref(false);

onMounted(() => {
  headerAndFooter.setFixedHeaderHeight(getHeaderHeight());
  headerAndFooter.setHeaderHeight(getHeaderHeight());
  changeSearchBehaviour();
  onScroll();
  setDrawerPosition();
});
</script>

<style scoped lang="scss">
.base-header {
  --subheader-substraction: 315px;
  color: $copytext;
  background-blend-mode: difference;
}
.base-header-shadow {
  position: absolute;
  width: 100%;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
  z-index: -2;
}
.base-header-home-background--small {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: -2;
}
.header-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.header-bottom-line {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: var(--base-light);
  opacity: 0.6;
}
.header-main-row {
  display: grid;
  gap: 0;
  height: 80px;
  max-height: 80px;
  padding-top: 1rem;
  grid-template-columns: 1fr 0;
  transition: grid-template-columns 0.2s ease-out;
}
.header-left {
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255 255 255 / 0.8);
  scroll-padding-top: 2rem;
  padding: 0 1rem;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
  transition: padding 0.2s ease-out;
}
.header-dlite {
  color: $copytext;
  padding: 4px 0;
  margin-top: 0.25rem;
}
.header-icons {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-left: 2rem;
  margin-top: 4px;
}
.header-user-icon {
  height: 18px;
}
.header-search-box {
  max-width: 350px;
}
.subheader {
  width: 100%;
  height: 56px;
  max-height: 56px;
  background-color: rgb(255 255 255 / 0.9);
  margin-top: auto;
  padding: 0.6rem 1rem;
  display: grid;
  align-items: center;
}
.subheader-wrapper {
  display: grid;
  grid-template-columns: minmax(80px, 1fr) minmax(auto, 1472px) minmax(
      1rem,
      1fr
    );
  z-index: -1;
}
.header-module-title {
  position: absolute;
  left: 240px;
  color: var(--base);
  font-size: 24px;
  font-weight: 700;
  text-decoration: none;
  margin-top: 0.25rem;
  max-width: calc(100vw - var(--subheader-substraction));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.2s ease-out, opacity 0.2s 0.2s ease-out;
}
.big-header .base-header-background {
  mask-position: 0 30%;
}
.big-header .header-left {
  background-color: rgb(255 255 255 / 0.9);
  padding: 2rem;
}
.big-header .header-main-row {
  gap: 1rem;
  grid-template-columns: 1fr 420px;
}
.progress-bar {
  position: absolute;
  min-height: 5px;
}

#baseHeaderLogos {
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 10px;
  padding-left: 10px;
}

.logo-basf {
  height: 42px;
}
@media only screen and (max-width: 1279.5px) {
  .big-header .header-main-row,
  .big-header .header-left {
    height: 64px;
  }
  .big-header .header-main-row {
    gap: 1rem;
    grid-template-columns: 1fr max(288px, 25%);
  }
  .subheader-wrapper {
    grid-template-columns: 0.5rem 1fr 0.5rem;
  }
  #baseHeaderLogos a:last-child::before {
    content: '';
    height: 40px;
    margin-top: -4px;
  }

  #baseHeaderLogos {
    padding-top: 8px;
    padding-left: 8px;
  }
  .logo-basf {
    height: 32px;
  }
  .logo-care-creations {
    height: 31px;
  }
}
@media only screen and (max-width: 1632px) {
  .header-module-title {
    left: 164px;
  }
}
@media only screen and (max-width: 744px) {
  .header-module-title {
    font-size: 20px;
  }
  .big-header .header-module-title {
    font-size: 24px;
    left: 113px;
  }
  .big-header .header-main-row,
  .header-user-icon,
  .logo-care-creations {
    grid-template-columns: 1fr 144px;
  }
}
@media only screen and (max-width: 499px) {
  .logo-basf-wrapper {
    display: none;
  }
  .big-header .header-main-row {
    gap: 0;
    grid-template-columns: 1fr 0;
  }
  .base-header:not(.big-header) {
    & .header-dlite {
      display: none;
    }
    & .subheader-wrapper {
      grid-template-columns: 0 1fr 0;
    }
  }
  .header-module-title {
    --subheader-substraction: 210px;
    left: 56px;
    transform: translateY(-2px);
  }
}

@media only screen and (max-width: 430px) {
  .big-header .header-dlite {
    display: unset;
  }
}

.settings-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.user-settings {
  height: 18px;
}

.fade-translate-right-enter-active,
.fade-translate-right-leave-active {
  transform: translateX(0);
  transition: opacity 0.4s ease-out, transform 0.2s ease-out;
}
.fade-translate-right-leave-active {
  opacity: 0;
}
.fade-translate-right-enter,
.fade-translate-right-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
