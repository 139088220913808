import { DateTimeFormats } from 'vue-i18n/types';

const dateTimeFormats: DateTimeFormats = {
  en: {
    short: { year: 'numeric', month: 'numeric', day: 'numeric' },
  },
  pt: {
    short: { year: 'numeric', month: '2-digit', day: '2-digit' },
  },
  es: {
    short: { year: 'numeric', month: '2-digit', day: '2-digit' },
  },
  it: {
    short: { year: 'numeric', month: '2-digit', day: '2-digit' },
  },
  fr: {
    short: { year: 'numeric', month: '2-digit', day: '2-digit' },
  },
  pl: {
    short: { year: 'numeric', month: '2-digit', day: '2-digit' },
  },
};

export default dateTimeFormats;
