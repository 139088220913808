/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthStore } from '@/stores/auth';
import { isPerformanceConsent } from './cookies';
import { ROLES } from '@/mixins';

const canTrack = () => {
  const { isProd } = useAuthStore();
  return isPerformanceConsent() && isProd;
};

const trackEvent = (event: any): void => {
  if (!canTrack()) return;

  const utag = (window as any).utag;
  utag.link(event);
};

const trackView = (event: any): void => {
  if (!canTrack()) return;

  const utag = (window as any).utag;

  // Delete event data from utag.data to avoid triggering another event in Piano
  delete utag.data['event_type'];
  delete utag.data['event_category'];
  delete utag.data['event_action'];
  delete utag.data['event_value'];

  utag.view(event);
};

const trackUserLogginLogout = (loggedIn = true) => {
  if (!canTrack()) return;

  const { user, isFreemiumUser } = useAuthStore();

  if (!user.email) return;

  const [_, domain] = user.email.split(/@/);
  const customerSubscription = isFreemiumUser
    ? 'freemium'
    : user.roles.includes(ROLES.DL_TRIAL)
    ? 'trial'
    : 'paid';
  const customerType = user.properties.DEPARTMENT;

  trackEvent({
    customer_status: loggedIn ? 'loggedin' : 'loggedout',
    customer_subscription: customerSubscription,
    customer_type: customerType,
    ...(user.performanceDomain && { customer_organization: domain }),
    ...(user.performanceCountry && { customer_country: user.countryBuid }),
  });
};

export { trackEvent, trackView, trackUserLogginLogout };
