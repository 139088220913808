/* eslint-disable  @typescript-eslint/no-explicit-any */
import api from '@/plugins/axiosApi';
import {
  BasfProductAdvancedSearchReq,
  BasfProductDocumentReq,
} from '@/api/types';
import { BasfProductSustainabilityInformation } from '@/typings/compositions';

export const fetchBasfProductDetail = async (
  basfProductId: string,
  params = {}
) => {
  const resObj = await api({
    url: `/api/basfproducts/${basfProductId}`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchBasfProductDetailInformation = async (params: any) => {
  const resObj: any = await api({
    url: '/api/basfproducts/information',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchBasfProductMintelCategories = async (params: any) => {
  const resObj: any = await api({
    url: '/api/basfproducts/getcategories',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchAllProductGroups = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/basf_prd_product_group',
    method: 'get',
    params,
  });

  if (resObj) {
    return resObj;
  }
};

export const fetchAllApplications = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/basf_prd_application',
    method: 'get',
    params,
  });

  if (resObj) {
    return resObj;
  }
};

export const fetchAllCertificateTypes = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/basf_prd_certificate_type',
    method: 'get',
    params,
  });

  if (resObj) {
    return resObj;
  }
};

export const fetchAllActiveSubstanceTechnologies = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/basf_prd_active_substance_technology',
    method: 'get',
    params,
  });

  if (resObj) {
    return resObj;
  }
};

export const fetchAllRelatedBasfProductLabels = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/basf_prd_related_product_label',
    method: 'get',
    params,
  });

  if (resObj) {
    return resObj;
  }
};

export const fetchAllFunctions = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/ingredient_function',
    method: 'get',
    params,
  });

  if (resObj) {
    return resObj;
  }
};

export const fetchAllFunctionsBasfProducts = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/BASF_PRD_FUNCTION',
    method: 'get',
    params,
  });

  if (resObj) {
    return resObj;
  }
};

export const fetchAllConsumerBenefits = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/basf_prd_consumer_benefit',
    method: 'get',
    params,
  });

  if (resObj) {
    return resObj;
  }
};

export const fetchBasfProductsAdvanceSearch = async (
  params: BasfProductAdvancedSearchReq
) => {
  const options: any = params.params;

  const jsonData = {
    lang: options.filter(function (item: any) {
      return item.name === 'lang';
    })[0].value,
    _limit: options.filter(function (item: any) {
      return item.name === '_limit';
    })[0].value,
    _page: options.filter(function (item: any) {
      return item.name === '_page';
    })[0].value,
    _sort: options.filter(function (item: any) {
      return item.name === '_sort';
    })[0].value,

    categories: params.categoryIds,
    subcategories: params.subCategoryIds,
    productGroups: params.productGroupIds,
    applicationAreas: params.applicationIds,
    certificateTypes: [
      ...(params.ethicLabelIds ?? []),
      ...(params.retailLabelIds ?? []),
    ],
    includedSustainabilityIds: [
      ...(params.ecoLabelIds ?? []),
      ...(params.naturalityLabelsIds ?? []),
      ...(params.sustainableSourcingStandardsLabelsIds ?? []),
      ...(params.sustainabilityBiodegradabilityLabelsIds ?? []),
    ],
    activeSubstanceTechnologyIds: params.activeSubstanceTechnologyIds,
    relatedBasfProductLabels: params.relatedBasfProductLabelIds,
    excludeListComparabilityIds: params.excludeListComparabilityIds,
    functions: params.functionIds,
    consumerBenefits: params.consumerBenefitIds,
    includedIngredients: params.includedIngredientIds,
    excludedIngredients: params.excludedIngredientIds,
    ...(params.rcc && {
      rcc: {
        minValue: params.rcc[0],
        maxValue: params.rcc[1],
      },
    }),
    ...(params.biodegradability && {
      biodegradabilityBuid: params.biodegradability,
    }),
    q: params.q || '*',
  };

  const resObj: any = await api.post('/api/basfproducts', jsonData);
  if (resObj) {
    return resObj.data;
  }
};

export const fetchBasfProductDocumentsZip = async (
  reqObj: BasfProductDocumentReq
) => {
  const jsonData = {
    fileNames: reqObj.fileNames,
    prdNo: reqObj.prdNo,
    tradeName: reqObj.tradeName,
    documentType: reqObj.documentType,
  };
  const resObj: any = await api.post(
    '/api/basfproducts/document/multiple/download',
    jsonData,
    {
      responseType: 'arraybuffer',
    }
  );
  if (resObj) {
    return resObj.data;
  }
  return {};
};

export const fetchSingleBasfProductDocument = async (
  reqObj: BasfProductDocumentReq
) => {
  const jsonData = {
    fileNames: reqObj.fileNames,
    prdNo: reqObj.prdNo,
    tradeName: reqObj.tradeName,
    documentType: reqObj.documentType,
  };
  const resObj: any = await api.post(
    '/api/basfproducts/document/single/download',
    jsonData,
    {
      responseType: 'arraybuffer',
    }
  );
  if (resObj) {
    return resObj.data;
  }
  return {};
};

export const fetchBasfProductsPcf = async (params: any) => {
  const resObj: any = await api({
    url: '/api/productcarbonfootprint/data',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchBasfProductPcfDocument = async (params: any) => {
  const resObj: any = await api({
    url: '/api/productcarbonfootprint/document',
    method: 'get',
    responseType: 'arraybuffer',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchBasfProductsSustainabilityInformation = async (
  sustainabilityIdsListString: string
) => {
  const ids = sustainabilityIdsListString
    .split(',')
    .map((id) => `id=${encodeURIComponent(id)}`)
    .join('&');
  const { data } = await api({
    url: `/api/basfproducts/sustainability/multipleIds?${ids}`,
    method: 'get',
  });

  return data as BasfProductSustainabilityInformation[];
};
