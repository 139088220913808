/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { defineStore } from 'pinia';
import {
  TopClaimsAdvancedSearchPref,
  SkinType,
  HairType,
  Sentiment,
  Gender,
  TopClaimsTreeChartReq,
  TreeChartItemData,
} from '@/api/types';
import { ConsumerInsightDates } from '@/typings/enums';
import {
  fetchAllSkinTypes,
  fetchAllHairTypes,
  fetchAllSentiments,
  fetchAllGenders,
  fetchTopClaimsTreeChartData,
} from '@/api';
import { arrayToObject } from '@/lib/utils';

interface State {
  topClaimsAdvancedPref: TopClaimsAdvancedSearchPref;
  backupTopClaimsAdvancedPref: TopClaimsAdvancedSearchPref;
  openedFilters: string[];
  fetchedFiltersData: boolean;
  skinTypes: SkinType[];
  hairTypes: HairType[];
  sentiments: Sentiment[];
  genders: Gender[];
  topClaimsTreeChartData: TreeChartItemData[];
}

export const useTopClaimsStore = defineStore('topClaims', {
  state: (): State => ({
    topClaimsAdvancedPref: localStorage.getItem('TopClaimsAdvancedSearchPref')
      ? JSON.parse(localStorage.getItem('TopClaimsAdvancedSearchPref')!)
      : {
          params: [],
          dateSelection: [ConsumerInsightDates.LAST_MONTH],
          categorySelection: [],
          skinTypeSelection: [],
          hairTypeSelection: [],
          countrySelection: [],
          genderSelection: [],
          sentimentSelection: [],
        },
    backupTopClaimsAdvancedPref: localStorage.getItem(
      'TopClaimsAdvancedSearchPref'
    )
      ? JSON.parse(localStorage.getItem('TopClaimsAdvancedSearchPref')!)
      : {
          params: [],
          dateSelection: [ConsumerInsightDates.LAST_MONTH],
          categorySelection: [],
          skinTypeSelection: [],
          hairTypeSelection: [],
          countrySelection: [],
          genderSelection: [],
          sentimentSelection: [],
        },
    openedFilters: [],
    fetchedFiltersData: false,
    skinTypes: [],
    hairTypes: [],
    sentiments: [],
    genders: [],
    topClaimsTreeChartData: [],
  }),

  getters: {
    appliedFilters: (state) => () => {
      return Object.entries(state.backupTopClaimsAdvancedPref)
        .filter(([key]) => key !== 'q' && key !== 'params')
        .reduce((acc, [_key, val]) => {
          acc.push(val);
          return acc;
        }, [])
        .flat()
        .sort();
    },

    newFilters: (state) => () => {
      return Object.entries(state.topClaimsAdvancedPref)
        .filter(([key]) => key !== 'q' && key !== 'params')
        .reduce((acc, [_key, val]) => {
          acc.push(val);
          return acc;
        }, [])
        .flat()
        .sort();
    },
  },

  actions: {
    setFetchedDataFilters(value: boolean) {
      this.fetchedFiltersData = value;
    },

    setOpenedFilters(filters: string[]) {
      this.openedFilters = filters;
    },

    restoreFilters() {
      this.topClaimsAdvancedPref = JSON.parse(
        JSON.stringify(this.backupTopClaimsAdvancedPref)
      );
    },

    updateTopClaimsPreferences(
      topClaimsAdvancedPref: any,
      updateBackup = false
    ) {
      localStorage.setItem(
        'TopClaimsAdvancedSearchPref',
        JSON.stringify(topClaimsAdvancedPref)
      );
      this.topClaimsAdvancedPref = topClaimsAdvancedPref;

      if (updateBackup) {
        this.backupTopClaimsAdvancedPref = topClaimsAdvancedPref;
      }
    },

    async getSkinTypes(reqObj: any) {
      const reqParams = arrayToObject(reqObj);
      const { data } = await fetchAllSkinTypes(reqParams);

      this.skinTypes = data || [];
    },

    async getHairTypes(reqObj: any) {
      const reqParams = arrayToObject(reqObj);
      const { data } = await fetchAllHairTypes(reqParams);

      this.hairTypes = data || [];
    },

    async getSentiments(reqObj: any) {
      const reqParams = arrayToObject(reqObj);
      const { data } = await fetchAllSentiments(reqParams);

      this.sentiments = data || [];
    },

    async getGenders(reqObj: any) {
      const reqParams = arrayToObject(reqObj);
      const { data } = await fetchAllGenders(reqParams);

      this.genders = data || [];
    },

    async getTopClaimsTreeChartData(reqObj: TopClaimsTreeChartReq) {
      const reqParams = { ...arrayToObject(reqObj.params), ...reqObj };
      delete reqParams.params;

      const { chartData } = await fetchTopClaimsTreeChartData({
        params: reqParams,
      });

      this.topClaimsTreeChartData = chartData.map((data) => {
        const { id, name: label } = data.label;
        return {
          ...data,
          id,
          label,
        };
      });
    },
  },
});
