import { CompositionsAdvancedSearchFilters } from '@/typings/compositions';
import { defineStore } from 'pinia';
import { useSecurityStore } from './security';
import { SectionsIds } from '@/typings/enums';

interface State {
  categorySelection: string[];
  subCategorySelection: string[];
  formatSelection: string[];
  advancedFilters: CompositionsAdvancedSearchFilters;
  backupAdvancedFilters: CompositionsAdvancedSearchFilters;
  openedFilters: string[];
  activeTab: string;
  editFormulationId: string;
  showLabels: boolean;
  showBiodegradability: boolean;
  isSunCareSelected: boolean;
}

export const useCompositionsStore = defineStore('compositions', {
  state: (): State => ({
    categorySelection: [],
    subCategorySelection: [],
    formatSelection: [],
    advancedFilters: {
      consumerBenefitSelection: [],
      spfSelection: [],
      regionSelection: [],
      tradeNamesIncludeSelection: [],
      tradeNamesExcludeSelection: [],
      ingredientsIncludeSelection: [],
      ingredientsExcludeSelection: [],
      searchField: '',
    },
    backupAdvancedFilters: {
      consumerBenefitSelection: [],
      spfSelection: [],
      regionSelection: [],
      tradeNamesIncludeSelection: [],
      tradeNamesExcludeSelection: [],
      ingredientsIncludeSelection: [],
      ingredientsExcludeSelection: [],
      searchField: '',
    },
    openedFilters: [],
    activeTab: '',
    editFormulationId: '',
    showLabels: false,
    showBiodegradability: false,
    isSunCareSelected: false,
  }),

  getters: {
    appliedFilters: (state) => () => {
      return Object.entries(state.backupAdvancedFilters)
        .reduce((acc, [_key, val]) => {
          val && acc.push(val);
          return acc;
        }, [])
        .flat()
        .sort();
    },

    newFilters: (state) => () => {
      return Object.entries(state.advancedFilters)
        .reduce((acc, [_key, val]) => {
          val && acc.push(val);
          return acc;
        }, [])
        .flat()
        .sort();
    },

    // TODO: check after InCosmetics
    hasAccess() {
      const securityStore = useSecurityStore();

      return securityStore.isUserAllowedMenu([SectionsIds.COMPOSITIONS]);
    },
  },

  actions: {
    setCompositionsAdvancedFilters(
      filters: CompositionsAdvancedSearchFilters,
      updateBackup = false
    ) {
      this.advancedFilters = filters;

      if (updateBackup) {
        this.backupAdvancedFilters = filters;
      }
    },

    setOpenedFilters(filters: string[]) {
      this.openedFilters = filters;
    },

    restoreFilters() {
      this.advancedFilters = JSON.parse(
        JSON.stringify(this.backupAdvancedFilters)
      );
    },

    setActiveTab(tabName: string) {
      this.activeTab = tabName;
    },

    setEditFormulationId(formulationId: string) {
      this.editFormulationId = formulationId;
    },

    setShowLabels(showLabels: boolean) {
      this.showLabels = showLabels;
    },

    setSunCareSelected(value: boolean) {
      this.isSunCareSelected = value;
    },
  },
});
