<template>
  <v-footer ref="footer" color="#373737" class="size14">
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12">
          <div id="baseFooterNavItems" class="base-footer">
            <div class="base-footer-nav-items">
              <span v-for="(eco, i) in ecosystem" :key="i">
                <router-link class="noLinkColor subtitle-3 mr-3" :to="eco.href">
                  {{ $t(eco.text) }}
                </router-link>
              </span>
              <button class="cookie-item" @click="handleToggleInfoDisplay">
                {{ $t('app.footer.cookie-settings') }}
              </button>
            </div>
            <div class="base-footer-copyright">
              {{
                $t('app.footer.copyright', {
                  year: new Date().getFullYear(),
                })
              }}
            </div>
            <div v-if="isLoggedIn" class="base-footer-logo">
              <v-row no-gutters>
                <v-col cols="12" style="text-align: center">
                  <span style="color: #ccc" class="subtitle-4">
                    Partially powered by:
                  </span>
                </v-col>
              </v-row>
              <div class="logos-wrapper">
                <img
                  class="logos-wrapper__logo logos-wrapper__logo--bevol"
                  alt="logo_bevol"
                  :src="require('@/assets/logo_bevol_white.png')"
                />
                <img
                  class="logos-wrapper__logo"
                  alt="logo_mintel"
                  :src="require('@/assets/logo_mintel.png')"
                />
                <img
                  class="logos-wrapper__logo"
                  alt="logo_euromonitor"
                  :src="require('@/assets/logo_euromonitor_white.png')"
                />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script setup lang="ts">
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { EcoSystem } from '@/api/types';
import { useHeaderAndFooterStore } from '@/stores/headerAndFooter';
import { promiseTimeout, useResizeObserver } from '@vueuse/core';
import { ref } from 'vue';

interface Props {
  isLoggedIn: boolean;
}

withDefaults(defineProps<Props>(), {
  isLoggedIn: false,
});

const headerAndFooter = useHeaderAndFooterStore();

const footer = ref(null);
useResizeObserver(footer, async (entries) => {
  const height = entries[0]?.contentRect?.height;
  if (height !== headerAndFooter.footerHeight) {
    await promiseTimeout(100);
    headerAndFooter.setFooterHeight(height);
  }
  const width = window?.innerWidth;
  if (width !== headerAndFooter.width) {
    await promiseTimeout(100);
    headerAndFooter.setWidth(width);
  }
});

const ecosystem: EcoSystem[] = [
  {
    id: 'termsOfUse',
    href: '/public/terms-of-use',
    text: 'app.footer.terms-of-use',
    subsystem: [],
  },
  {
    id: 'imprint',
    href: '/public/imprint',
    text: 'app.footer.imprint',
    subsystem: [],
  },
  {
    id: 'privacy',
    href: '/public/privacy-statement',
    text: 'app.footer.privacy-statement',
    subsystem: [],
  },
  {
    id: 'contact',
    href: '/public/contact',
    text: 'app.footer.contact',
    subsystem: [],
  },
  {
    id: 'marketing',
    href: '/public/marketing-consent',
    text: 'app.footer.marketing-consent',
    subsystem: [],
  },
];

const handleToggleInfoDisplay = () => {
  const optanon = (window as any).Optanon;
  if (optanon) {
    optanon.ToggleInfoDisplay();
  }
};
</script>

<style scoped lang="scss">
.v-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10;
  color: white;
}

#baseFooterNavItemsWrapper {
  width: 100%;
  max-width: 1440px;
}
.v-application a {
  text-decoration: none !important;
  font-weight: 500;
}

.v-application .primary--text {
  color: $red !important;
  caret-color: $red !important;
}

.v-btn {
  text-transform: none !important;
}
.logo-basf {
  width: 120px;
  height: auto;
}
.base-footer {
  height: 80px;
  display: grid;
  grid-gap: 12px;
  grid-template-areas:
    'copyright logo'
    'nav-items logo';
  padding-block: 10px;
  max-width: 1440px;
  margin-inline: auto;
}
.base-footer-nav-items {
  grid-area: nav-items;
  display: grid;
  grid-auto-flow: column;
  gap: 40px;
  margin-right: auto;
  align-items: start;
}
.base-footer-copyright {
  grid-area: copyright;
  display: grid;
  align-items: center;
}
.base-footer-logo {
  grid-area: logo;
  display: grid;
  justify-content: end;
  align-items: center;
}

@media (max-width: 1680px) and (min-width: 1280px) {
  .base-footer {
    margin-left: 64px;
    padding-inline: 16px;
  }
}
@media (max-width: 960px) and (min-width: 651px) {
  .base-footer {
    height: 168px;
    grid-template-areas:
      'nav-items nav-items'
      'copyright logo';
    padding-top: 18px;
    padding-bottom: 25px;
  }
}

@media (max-width: 650px) {
  .base-footer {
    height: auto;
    grid-template-areas:
      'nav-items'
      'copyright'
      'logo';
    grid-gap: 48px;
    padding-top: 18px;
    padding-bottom: 25px;

    .cookie-item {
      display: none;
    }
  }

  .base-footer-nav-items {
    margin-right: 0;
    gap: 24px;
    grid-auto-flow: row;
    grid-template-columns: repeat(2, auto);
    text-align: center;
  }
  .base-footer-copyright {
    margin-right: 0;
    justify-content: center;
  }
  .base-footer-logo {
    margin-right: 0;
    justify-content: center;
    padding-bottom: 8px;
  }
}

.logos-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;

  &__logo {
    max-height: 22px;

    &--bevol {
      max-height: 16px;
    }
  }
}
</style>
