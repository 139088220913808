import { defineStore } from 'pinia';
import { User } from '@/api/types';
import { getOAuthToken, getUserinfosMe } from '@/api';
import { setCookie, getCookie, eraseCookie } from '@/lib/cookies';
import {
  ROLES,
  RESTRICTED_ROLES,
} from '@/mixins/modules/constants/base-constants';
import { WebshopLink } from '@/typings/enums';
import { ENV } from '@/typings/enums';
import { trackUserLogginLogout } from '@/lib/analytics';

interface State {
  token: string;
  user: User;
  environment: string;
  refreshToken: string;
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    token: getCookie('dlite.csrf.token'),
    user: { firstName: '', lastName: '', fullAccess: false },
    environment: process.env.VUE_APP_ENVIRONMENT,
    refreshToken: localStorage.getItem('refreshToken') || '',
  }),

  getters: {
    userName(state): string {
      return state.user.firstName
        ? `${state.user.firstName} ${state.user.lastName}`
        : '';
    },

    hasNoBundleAccess(state): boolean {
      if (state.user.roles) {
        return (
          state.user.roles.length === 1 &&
          state.user.roles.some((r) => RESTRICTED_ROLES.includes(r))
        );
      }
      return true;
    },

    isLoggedIn(state): boolean {
      return state.user.firstName !== '' && state.token !== '';
    },

    isEmptyUser(state): boolean {
      return !state.user.firstName;
    },

    isEmCustomer(state): boolean {
      return state.user.customerType === 'EM_CUSTOMER';
    },

    isLocal(state): boolean {
      return state.environment === ENV.LOCAL;
    },

    isDev(state): boolean {
      return state.environment === ENV.DEV;
    },

    isQA(state): boolean {
      return state.environment === ENV.QA;
    },

    isProd(state): boolean {
      return state.environment === ENV.PROD;
    },

    hasWebshop(state): boolean {
      return (
        (state.user &&
          state.user.webshopWelcomePage &&
          state.user.webshopWelcomePage.length > 0) ||
        false
      );
    },

    hasCarestore(): boolean {
      return (
        this.hasWebshop &&
        (this.user.webshopWelcomePage as string).includes(WebshopLink.CARESTORE)
      );
    },

    hasBasfStore(): boolean {
      return (
        this.hasWebshop &&
        (this.user.webshopWelcomePage as string).includes(WebshopLink.BASF)
      );
    },

    hasBrenntag(): boolean {
      const brenntagRoles = [ROLES.DL_STANDARD_3RD, ROLES.DL_FREEMIUM];
      const hasRequiredRole =
        this.user &&
        this.user.roles?.some((role) => brenntagRoles.includes(role));

      return (
        (hasRequiredRole &&
          this.user.webshopWelcomePage &&
          this.user.webshopWelcomePage.includes(WebshopLink.BRENNTAG)) ||
        false
      );
    },

    isFreemiumUser() {
      return (
        !this.isEmptyUser &&
        !this.user.roles.includes('DL_STANDARD_3RD') &&
        !this.user.roles.includes('DL_STANDARD_BASF') &&
        !this.user.fullAccess
      );
    },

    isInternalUser() {
      return (
        !this.isEmptyUser &&
        (this.user.roles.includes('DL_STANDARD_BASF') || this.user.fullAccess)
      );
    },

    userAccesiblesIds(state) {
      return state.user.accessibles
        ? state.user.accessibles.map((e) => e.featureIdentifier)
        : [];
    },
  },

  actions: {
    async fetchUserinfosMe() {
      const apiResponse = await getUserinfosMe({ lang: 'en' });
      this.setUser(apiResponse.data);
    },

    async getAuthToken(code: string) {
      const apiResponse = await getOAuthToken({ code });
      if (apiResponse && apiResponse.data) {
        this.setToken(apiResponse.data.access_token);
        this.setUser(apiResponse.data.user);
        this.setRefreshToken(apiResponse.data.refresh_token);
        trackUserLogginLogout();
      } else {
        this.setToken('');
        this.setRefreshToken('');
        this.resetUser();
      }
    },

    setUser(user: User): void {
      this.user = user;
    },

    resetUser(): void {
      this.user = { firstName: '', lastName: '', fullAccess: false };
    },

    cleanUser() {
      this.resetToken();
      this.resetUser();
    },

    async getToken(token: string) {
      if (token && token.length > 0) {
        this.setToken(token);
        await this.fetchUserinfosMe();
      } else {
        this.setToken('');
        this.resetUser();
      }
    },

    setToken(token: string) {
      setCookie('dlite.csrf.token', token, 8);
      this.token = token;
    },

    resetToken(): void {
      eraseCookie('dlite.csrf.token');
      this.token = '';
    },

    setRefreshToken(refreshToken: string): void {
      this.refreshToken = refreshToken;
      localStorage.setItem('refreshToken', refreshToken);
    },
  },
});
