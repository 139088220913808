/* eslint-disable  @typescript-eslint/no-explicit-any */
import api from '@/plugins/axiosApi';
import {
  IngredientsAdvancedSearchReq,
  BasfIngredientDetail,
} from '@/api/types';
import { arrayToObject } from '@/lib/utils';

export const fetchIngredientDetail = async (
  ingredientId: string,
  params: any
): Promise<BasfIngredientDetail> => {
  const resObj = await api({
    url: `/api/ingredients/v1/${ingredientId}`,
    method: 'get',
    params,
  });
  return resObj.data;
};

export const fetchAllFormulationIngredients = async (params: any) => {
  const resObj: any = await api({
    url: '/api/formulation-ingredient-selection',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchAllBasfIngredients = async (params: any) => {
  const resObj: any = await api({
    url: '/api/basf-ingredient-selection',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchIngredientsAdvanced = async (
  params: IngredientsAdvancedSearchReq
) => {
  const options = params.params;
  const reqParams = {
    ...arrayToObject(options),
    categoryIds: params.categoryIds,
    subCategoryIds: params.subCategoryIds,
    functions: params.functionIds,
    regions: params.regionIds,
    q: params.q || '*',
  };

  const resObj = await api.post('/api/ingredients/v1', reqParams);
  return resObj.data;
};
