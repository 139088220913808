/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
import api from '@/plugins/axiosApi';

export const fetchPreferences = async (key: string, params: any) => {
  const resObj: any = await api({
    url: '/api/users/profiles/' + key,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const deliverPreferences = async (
  key: string,
  lang: string,
  data: any
) => {
  const payload = {
    keyName: key,
    value: JSON.stringify(data),
  };
  const resObj: any = await api.post('api/users/profiles/' + key, payload);
  if (resObj) {
    return resObj.data;
  }
};
