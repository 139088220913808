/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */

import api from '@/plugins/axiosApi';

export const getApiToken = async (data: any) =>
  api.post('/oauth/token', data, {
    auth: {
      username: 'client',
      password: 'password',
    },
  });

export const getOAuthToken = async (params: any) => {
  const resObj: any = await api({
    url: '/oauth/retrievetoken',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const getUserinfosMe = async (params: any) => {
  const resObj: any = await api({
    url: '/api/userinfos/me',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const checkUserEmail = async (email: string) => {
  const resObj: any = await api({
    url: `/public/email/${email}`,
    method: 'get',
  });
  if (resObj) {
    return resObj;
  }
};

export const revokeOAuthToken = async (params: any) => {
  try {
    await api.post('/oauth/revokeToken', params);
  } catch (error) {
    throw new Error(error);
  }
};
