/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
import api from '@/plugins/axiosApi';
import {
  EmollientMaestroSearchReq,
  EmollientMaestroMixtureSpiderChartReq,
  EmollientCalculation,
  EmollientCandidateUpdate,
} from '@/api/types';

export const fetchEmollientMaestroBenchmarks = async (params: any) => {
  const resObj: any = await api({
    url: '/api/maestro/benchmark',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchEmollientMaestroMixtures = async (
  params: EmollientMaestroSearchReq
) => {
  const options: any = params.params;

  const jsonData = {
    lang: options.filter(function (item: any) {
      return item.name === 'lang';
    })[0].value,
    _limit: options.filter(function (item: any) {
      return item.name === '_limit';
    })[0].value,
    _page: options.filter(function (item: any) {
      return item.name === '_page';
    })[0].value,

    oilTypeBuid: params.oilTypeBuid,
    benchmarkBuid: params.benchmarkBuid,
    benchmarkCandidateBuid: params.benchmarkCandidateBuid,
    naturalness: params.naturalness,
    matching: params.matching,
    mixLength: params.mixLength,
    ...(params.labelBuids && { labelBuids: params.labelBuids }),
    q: params.q || '*',
    _sort: params.sort,
    _order: params.order,
  };

  const resObj: any = await api.post(`/api/maestro`, jsonData);
  if (resObj) {
    return resObj.data;
  }
};

export const fetchEmollientMaestroSpiderChart = async (
  params: EmollientMaestroMixtureSpiderChartReq
) => {
  const resObj: any = await api.post('/api/maestro/spiderchart', params);
  if (resObj) {
    return resObj.data;
  }
};

export const fetchBenchmarkCandidates = async () => {
  const resObj: any = await api.post('/api/maestro/benchmarkcandidate', {});
  if (resObj) {
    return resObj.data.content;
  }
};

export const calculateBenchmarkCandidate = async (
  params: EmollientCalculation
) => {
  const resObj: any = await api.post('/api/maestro/calculate', params);
  if (resObj) {
    return resObj.data;
  }
};

export const updateCandidateName = async (params: EmollientCandidateUpdate) => {
  const resObj: any = await api.post('/api/maestro/update', params);
  if (resObj) {
    return resObj.data;
  }
};

export const fetchEmollientMaestroDetail = async (id: any, params: any) => {
  const resObj: any = await api({
    url: `/api/maestro/${id}`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchEmollientMaestroOils = async (params: any) => {
  const resObj: any = await api({
    url: `/api/maestro/oil`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};
