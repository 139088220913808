import { defineStore } from 'pinia';
import { Product, ConsumerProductDetailReq, ParamsOptions } from '@/api/types';
import {
  fetchProductDetail,
  fetchConsumerProductSourceConversationVolume,
} from '@/api';

interface State {
  consumerProductDetail: Product | null;
  consumerProductSourceConversationVolume: string;
}

export const useProductDetailStore = defineStore('productDetail', {
  state: (): State => ({
    consumerProductDetail: null,
    consumerProductSourceConversationVolume: '',
  }),

  actions: {
    async getProductDetail(reqObj: ConsumerProductDetailReq) {
      const reqParams: ParamsOptions = {};
      reqObj.params.map((arr) => {
        reqParams[arr.name] = arr.value;
      });

      const responseData = await fetchProductDetail(
        reqObj.productId,
        reqParams
      );
      if (responseData) {
        this.consumerProductDetail = responseData;
      }
    },

    async getConsumerProductSourceConversationVolume(
      reqObj: ConsumerProductDetailReq
    ) {
      const reqParams: ParamsOptions = {};
      reqObj.params.map((arr) => {
        reqParams[arr.name] = arr.value;
      });
      const responseData = await fetchConsumerProductSourceConversationVolume(
        reqObj.productId,
        reqParams
      );
      if (responseData && responseData.values) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dataValues = responseData.values as any[];
        const arrValues: { name: string; value: number }[] = [];
        const arrSourceValues: string[] = [];
        let count = 1;
        dataValues.forEach((val) => {
          count += val.value;
        });
        dataValues.forEach((val) => {
          const valPercent = (val.value / count) * 100;
          arrValues.push({
            name: val.dim2Name.charAt(0).toUpperCase() + val.dim2Name.slice(1),
            value: valPercent,
          });
        });

        const arrValuesSorted = arrValues
          .slice()
          .sort((a, b) => b.value - a.value);
        arrValuesSorted.forEach((val) => {
          const valueToString = val.value.toLocaleString(
            reqObj.lang || process.env.VUE_APP_I18N_FALLBACK_LOCALE,
            {
              maximumFractionDigits: 1,
            }
          );
          const valString = `${val.name}=${valueToString}%`;

          arrSourceValues.push(valString);
        });
        this.consumerProductSourceConversationVolume =
          arrSourceValues.join(', ');
      }
    },

    resetConsumerProductDetail() {
      this.consumerProductDetail = null;
    },

    resetConsumerProductSourceConversationVolume() {
      this.consumerProductSourceConversationVolume = '';
    },
  },
});
