/* eslint-disable no-console */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  const baseURL = process.env.BASE_URL || '/ui/';
  register(`${baseURL}service-worker.js`, {
    ready() {
      // console.log('Service worker is ready.');
    },
    registered() {
      // console.log('Service worker has been registered.');
    },
    cached() {
      // console.log('Content has been cached for offline use.');
    },
    updatefound() {
      // console.log('New content is downloading.');
    },
    updated() {
      // console.log('New content is available; please refresh.');
      self?.parent.postMessage({ msg: 'SW_UPDATE' });
    },
    offline() {
      // console.log(
      //   'No internet connection found. App is running in offline mode.'
      // );
    },
    error(_error) {
      // console.error('Error during service worker registration:', error);
    },
  });
}
