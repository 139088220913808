import { refDebounced, useWindowSize } from '@vueuse/core';
import { computed, ref } from 'vue';

const { width, height } = useWindowSize();

const screenWidth = refDebounced(width, 100);
const screenHeight = refDebounced(height, 100);

const defaultEaseOut = 'easeOutQuad';
const defaultEaseIn = 'easeInQuad';

const veryShortDuration = 150;
const shortDuration = 250;
const mediumDuration = 400;
const longDuration = 600;
const veryLongDuration = 1000;

enum Breakpoints {
  XL = 1920,
  LG = 1472,
  MD = 1280,
  SM = 768,
  XS = 500,
  XS2 = 320,
  DRAWER_FULLSCREEN = 450,
}

export const useResponsive = () => {
  const veryShortDurationMS = computed(() => `${veryShortDuration}ms`);
  const shortDurationMS = computed(() => `${shortDuration}ms`);
  const mediumDurationMS = computed(() => `${mediumDuration}ms`);
  const longDurationMS = computed(() => `${longDuration}ms`);
  const veryLongDurationMS = computed(() => `${veryLongDuration}ms`);

  const XL_PX = computed(() => `${Breakpoints.XL}px`);
  const LG_PX = computed(() => `${Breakpoints.LG}px`);
  const MD_PX = computed(() => `${Breakpoints.MD}px`);
  const SM_PX = computed(() => `${Breakpoints.SM}px`);
  const XS_PX = computed(() => `${Breakpoints.XS}px`);
  const XS2_PX = computed(() => `${Breakpoints.XS}px`);

  const isSM = computed(() => screenWidth.value <= Breakpoints.SM);
  const isLG = computed(() => screenWidth.value <= Breakpoints.LG);
  const isLowerThanXS = computed(() => screenWidth.value < Breakpoints.XS);
  const isLowerThanSM = computed(() => screenWidth.value < Breakpoints.SM);
  const isLowerThanMD = computed(() => screenWidth.value < Breakpoints.MD);
  const isDrawerFullScreen = computed(
    () => screenWidth.value < Breakpoints.DRAWER_FULLSCREEN
  );

  // TODO: Find more documentation about detecting touch devices
  const isMobile = ref(false);
  isMobile.value =
    'ontouchstart' in document.documentElement ||
    window.matchMedia('(pointer:coarse)').matches;
  // window.matchMedia('(any-pointer:coarse)').matches;

  const isSmallMobile = computed(() => isMobile.value && isLowerThanSM.value);

  return {
    isMobile,
    isSmallMobile,
    // Screen resolution
    Breakpoints,
    screenWidth,
    screenHeight,
    // Animations
    defaultEaseOut,
    defaultEaseIn,
    veryShortDuration,
    shortDuration,
    mediumDuration,
    longDuration,
    veryLongDuration,
    // CSS
    veryShortDurationMS,
    shortDurationMS,
    mediumDurationMS,
    longDurationMS,
    veryLongDurationMS,
    XL_PX,
    LG_PX,
    MD_PX,
    SM_PX,
    XS_PX,
    XS2_PX,
    isSM,
    isLG,
    isLowerThanXS,
    isLowerThanSM,
    isLowerThanMD,
    isDrawerFullScreen,
  };
};
