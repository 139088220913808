<template>
  <v-app id="base">
    <DliteHeaderBackground v-if="routeName === 'home' && isLoggedIn" is-home />
    <transition name="fade" mode="out-in">
      <DliteHeader v-if="isLoggedIn" :isLoggedIn="isLoggedIn" />
      <DliteHeaderInitial v-else :isLoggedIn="isLoggedIn" />
    </transition>
    <div
      :class="{
        'body-container-wrapper': isLoggedIn,
        'login-container-wrapper': isLoginPage,
      }"
    >
      <div><!-- space --></div>
      <div :class="['body-container', { 'body-container--in': isLoggedIn }]">
        <DliteHeaderNav :route-name="routeName" />
        <slot></slot>
      </div>
      <div><!-- space --></div>
    </div>
    <transition name="fade" mode="out-in">
      <BaseFooter v-if="isLoggedIn" :isLoggedIn="isLoggedIn" />
      <BaseFooter v-else :isLoggedIn="isLoggedIn" />
    </transition>
    <dialog ref="dialogModal" class="app-dialog">
      <transition name="fade">
        <DialogSectionMobile />
      </transition>
    </dialog>
  </v-app>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useAppStore } from '@/stores/app';
import DliteHeader from '@/components/common/DliteHeader.vue';
import DliteHeaderInitial from '@/components/common/DliteHeaderInitial.vue';
import BaseFooter from '@/components/common/BaseFooter.vue';
import { useHeaderAndFooterStore } from '@/stores/headerAndFooter';
import DliteHeaderNav from '@/components/common/DliteHeaderNav.vue';
import { useRouter } from '@/composables/useRouter';
import DliteHeaderBackground from '@/components/common/DliteHeaderBackground.vue';
import DialogSectionMobile from '@/components/common/DialogSectionMobile.vue';
import { SectionsIds } from '@/typings/enums';

const dialogModal = ref<HTMLDialogElement | null>(null);

const { route } = useRouter();
const routeName = computed(() => route.value.name);
const isLoginPage = computed(() => routeName.value === SectionsIds.LOGIN);

const headerAndFooter = useHeaderAndFooterStore();
const fixedHeaderHeight = computed(() => {
  if (isLoggedIn.value) {
    return `${headerAndFooter.fixedHeaderHeight}px`;
  }
  if (isLoginPage.value) {
    return `${headerAndFooter.loginHeaderHeight}px`;
  }
  return '0';
});
const footerHeight = computed(() => {
  if (routeName.value === SectionsIds.LANDING) {
    return '12px';
  }
  if (isLoginPage.value || isLoggedIn.value) {
    return `${headerAndFooter.footerHeight}px`;
  }
  return '0';
});

const bodyPadding = computed(() => {
  if (routeName.value === SectionsIds.HOME) {
    return '1.5rem';
  }

  return '0';
});

const appStore = useAppStore();

const authStore = useAuthStore();

const isLoggedIn = computed(() => {
  return authStore.isLoggedIn;
});

onMounted(() => {
  if (appStore && dialogModal.value) {
    dialogModal.value.addEventListener(
      'close',
      appStore.resetDialogSectionMobile
    );
    appStore.appDialog = dialogModal.value;
  }
});

onUnmounted(() => {
  if (appStore && dialogModal.value) {
    dialogModal.value.removeEventListener(
      'close',
      appStore.resetDialogSectionMobile
    );
  }
});
</script>

<style scoped lang="scss">
.app-dialog {
  border: none;
  z-index: 999;
}

.body-container {
  margin-top: v-bind(fixedHeaderHeight);
  margin-bottom: v-bind(footerHeight);
  padding-top: v-bind(bodyPadding);
}

.body-container--in {
  max-width: calc(100vw - 80px - 1rem);
}

.body-container-wrapper {
  display: grid;
  grid-template-columns: minmax(80px, 1fr) minmax(auto, 1472px) minmax(
      1rem,
      1fr
    );
  overflow: hidden;
}

.login-container-wrapper {
  background-color: white;
  height: 100%;
  .body-container {
    height: 100%;
    margin: 0;
    padding-top: v-bind(fixedHeaderHeight);
    padding-bottom: calc(v-bind(footerHeight) - 1rem);
  }
}

@media only screen and (max-width: 1279.5px) {
  .body-container-wrapper {
    grid-template-columns: 0.5rem 1fr 0.5rem;
  }
  .body-container--in {
    max-width: calc(100vw - 1rem);
  }
}

@media only screen and (max-width: 400px) {
  .body-container-wrapper {
    grid-template-columns: 0 1fr 0;
  }
  .body-container--in {
    max-width: 100vw;
  }
  .login-container-wrapper {
    display: grid;
    place-content: center;
  }
}
</style>
