import { SectionsIds } from '@/typings/enums';

interface ThemeProperties {
  theme: string;
  chartColors: Array<string>;
  hexColor: string;
}
interface Theme {
  [key: string]: ThemeProperties;
}

const themeColor = {
  green: '#00793a',
  greenLight1: '#65ac1e',
  greenLight2: '#8bc156',
  redLight1: '#d34059',
  orange: '#f39500',
  orangeLight1: '#f7b040',
  blue: '#004a96',
  blueLight1: '#5177b5',
  blueLight2: '#21a0d2',
  blueLight3: '#75bddf',
  gray: '#333333',
};

const theme: Theme = {
  [SectionsIds.LOGIN]: {
    theme: 'red',
    chartColors: [],
    hexColor: themeColor.redLight1,
  },
  [SectionsIds.BRAND_ANALYTICS]: {
    theme: 'red',
    chartColors: [
      '#D34059',
      '#ADADAD',
      '#F39500',
      '#4F000E',
      '#FDEFD9',
      '#373737',
      '#F6D9DE',
      '#F7B040',
      '#D8D8D8',
      '#C50022',
      '#F0F0F0',
      '#613C00',
    ],
    hexColor: themeColor.redLight1,
  },
  [SectionsIds.CONSUMER_PRODUCTS]: {
    theme: 'blue-light-1',
    chartColors: [
      '#5177B5',
      '#ADADAD',
      '#21A0D2',
      '#001E3C',
      '#DEF1F8',
      '#373737',
      '#D9E4EF',
      '#59B8DE',
      '#D8D8D8',
      '#004A96',
      '#F0F0F0',
      '#0D4054',
    ],
    hexColor: themeColor.blueLight1,
  },
  [SectionsIds.CONSUMER_INSIGHTS]: {
    theme: 'blue-light-2',
    chartColors: ['#004A96'],
    hexColor: themeColor.blueLight3,
  },
  [SectionsIds.MARKET_INSIGHTS]: {
    theme: 'blue',
    chartColors: [
      '#21A0D2',
      '#ADADAD',
      '#004A96',
      '#0D4054',
      '#D9E4EF',
      '#373737',
      '#DEF1F8',
      '#4078B1',
      '#D8D8D8',
      '#59B8DE',
      '#F0F0F0',
      '#001E3C',
    ],
    hexColor: themeColor.blue,
  },
  [SectionsIds.CONCEPT_COLLECTION]: {
    theme: 'orange-light-1',
    chartColors: [],
    hexColor: themeColor.orangeLight1,
  },
  [SectionsIds.FORMULATION_DESIGN]: {
    theme: 'orange',
    chartColors: [],
    hexColor: themeColor.orange,
  },
  [SectionsIds.INGREDIENT_SELECTION]: {
    theme: 'green-light-1',
    chartColors: [],
    hexColor: themeColor.greenLight2,
  },
  [SectionsIds.BASF_PRODUCTS]: {
    theme: 'green-light-1',
    chartColors: [],
    hexColor: themeColor.greenLight2,
  },
  [SectionsIds.MY_DLITE]: {
    theme: 'red',
    chartColors: [],
    hexColor: themeColor.gray,
  },
  [SectionsIds.EMOLLIENT_JOCKEY]: {
    theme: 'green',
    chartColors: [],
    hexColor: themeColor.green,
  },
  [SectionsIds.EMOLLIENT_MAESTRO]: {
    theme: 'green',
    chartColors: [],
    hexColor: themeColor.green,
  },
  [SectionsIds.COMPOSITIONS]: {
    theme: 'green',
    chartColors: [],
    hexColor: themeColor.green,
  },
  [SectionsIds.CARE_STORE]: {
    theme: 'green',
    chartColors: [],
    hexColor: themeColor.greenLight1,
  },
  [SectionsIds.LEARN_MORE]: {
    theme: 'blue',
    chartColors: [],
    hexColor: themeColor.blue,
  },
  [SectionsIds.CARE_360]: {
    theme: 'blue',
    chartColors: [],
    hexColor: themeColor.blue,
  },
  [SectionsIds.SFA]: {
    theme: 'blue-light-2',
    chartColors: [],
    hexColor: themeColor.blueLight2,
  },
  [SectionsIds.SURFACTANT_NAVIGATOR]: {
    theme: 'blue-light-2',
    chartColors: [],
    hexColor: themeColor.blueLight2,
  },
  [SectionsIds.CARE_CONNECT]: {
    theme: 'red',
    chartColors: [],
    hexColor: themeColor.redLight1,
  },
  [SectionsIds.BRENNTAG]: {
    theme: 'green',
    chartColors: [],
    hexColor: themeColor.greenLight1,
  },
  [SectionsIds.BASF_STORE]: {
    theme: 'green',
    chartColors: [],
    hexColor: themeColor.greenLight1,
  },
  [SectionsIds.TREND]: {
    theme: 'green',
    chartColors: [],
    hexColor: themeColor.green,
  },
};

/**
 * Return section's color, default to brandAnalytics
 * @param {string} section // Section's key
 * @returns {string} // Section's color
 */
export const getThemeColor = (section: string) => {
  if (!(section in theme)) section = SectionsIds.BRAND_ANALYTICS;

  return theme[section].hexColor;
};

export default theme;
