<template>
  <span>
    <button
      :class="{ 'chart-dialog-button': inChart }"
      @click="showPrintDialog = true"
    >
      <span class="px-1">
        <v-icon small :class="['iconPadding', { 'themed-color': !inChart }]">
          mdi mdi-image-move
        </v-icon>
      </span>
      <span>
        <slot></slot>
      </span>
    </button>
    <!-- Delete -->
    <v-dialog v-model="showPrintDialog" max-width="300" @keydown.esc="cancel">
      <v-card>
        <v-toolbar dense flat class="themed-bg">
          <v-toolbar-title class="px-3 white--text">
            {{ $t('app.page.my-projects.dlg-title-print') }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4">
          {{ $t('app.page.my-projects.dlg-message-print') }}
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showPrintDialog = false">
            {{ $t('app.page.my-projects.dlg-no') }}
          </v-btn>
          <v-btn color="primary" @click="doPrint">
            {{ $t('app.page.my-projects.dlg-yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script setup lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, ref } from 'vue';
import { useMyProjectStore } from '@/stores/myProject';
import { useProductDetailStore } from '@/stores/productDetail';
import { useBrandDetailStore } from '@/stores/brandDetail';
import { MyProjectDetail } from '@/api/types';
import { useI18n } from '@/composables/useI18n';
import { formatDateYYYMMDD } from '@/lib/dates';
import { generateUuid } from '@/lib/utils';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { ANALYTIC_OPTIONS } from '@/mixins';
import { trackEvent } from '@/lib/analytics';

interface Props {
  inChart?: boolean;
  canvasRef?: string;
  imgType?: string;
  imgFactor?: number;
  imgData?: any;
  imgConfig?: string;
}

const props = withDefaults(defineProps<Props>(), {
  inChart: false,
  canvasRef: '',
  imgType: 'default',
  imgFactor: 4,
  imgConfig: '',
});

const { $t } = useI18n();

const myProjectStore = useMyProjectStore();
const brandDetailStore = useBrandDetailStore();
const productDetailStore = useProductDetailStore();

const showPrintDialog = ref(false);

const textKey = computed(() => props.imgData[0].textKey);

const doPrint = async () => {
  const el: any = document.querySelector(`#${props.canvasRef}`);
  const y = window.pageYOffset;
  const x = window.pageXOffset;

  const options: any = {
    logging: false,
    backgroundColor: '#ffffff',
    allowTaint: true,
    useCORS: true,
    scrollX: x * -1,
    scrollY: y * -1,
    scale: 3,
  };

  const data = props.imgData;

  // Update value with textKey if exists
  data[0] = {
    ...data[0],
    value: textKey.value ? $t(textKey.value) : data[0].value,
  };

  if (props.imgConfig) {
    if (props.imgConfig === 'ba') {
      data.push({ name: 'route', value: '/ba' });
    } else if (props.imgConfig === 'ba/detail') {
      if (brandDetailStore.brandDetail) {
        data.push({
          name: 'route',
          value: brandDetailStore.brandDetail.businessId
            ? '/ba/details/' + brandDetailStore.brandDetail.businessId
            : '#',
        });
        data.push({
          name: $t('app.common.name'),
          value: brandDetailStore.brandDetail.name
            ? brandDetailStore.brandDetail.name
            : '',
        });
      }
    } else if (props.imgConfig === 'cp/detail') {
      if (productDetailStore.consumerProductDetail) {
        data.push({
          name: 'route',
          value: productDetailStore.consumerProductDetail.businessId
            ? '/cp/details/' +
              productDetailStore.consumerProductDetail.businessId
            : '#',
        });
        data.push({
          name: $t('app.common.name'),
          value: productDetailStore.consumerProductDetail.name
            ? productDetailStore.consumerProductDetail.name
            : '',
        });
        data.push({
          name: '',
          value: productDetailStore.consumerProductDetail.market
            ? JSON.parse(
                JSON.stringify(productDetailStore.consumerProductDetail.market)
              ).name
            : '',
        });
        data.push({
          name: '',
          value: productDetailStore.consumerProductDetail.category
            ? JSON.parse(
                JSON.stringify(
                  productDetailStore.consumerProductDetail.category
                )
              ).name
            : '',
        });
        data.push({
          name: '',
          value: productDetailStore.consumerProductDetail.subCategory
            ? JSON.parse(
                JSON.stringify(
                  productDetailStore.consumerProductDetail.subCategory
                )
              ).name
            : '',
        });
        data.push({
          name: $t('app.page.consumer-products.brand'),
          value: productDetailStore.consumerProductDetail.brand
            ? productDetailStore.consumerProductDetail.brand
            : '',
        });
        data.push({
          name: '',
          value: productDetailStore.consumerProductDetail.launchDate
            ? formatDateYYYMMDD(
                productDetailStore.consumerProductDetail.launchDate
              )
            : '',
        });
      }
    } else if (props.imgConfig.startsWith('/em')) {
      // Maestro
      data.push({ name: 'route', value: props.imgConfig });
    } else {
      data.push({ name: 'route', value: '/' + props.imgConfig });
    }
  }
  data.push({
    name: 'created',
    value: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
  });

  let pageType;
  if (props.imgConfig.startsWith('ba')) {
    pageType = ANALYTIC_OPTIONS.SECTIONS.BRAND_ANALYTICS;
  } else if (props.imgConfig.startsWith('cp')) {
    pageType = ANALYTIC_OPTIONS.SECTIONS.CONSUMER_PRODUCTS;
  } else if (props.imgConfig.startsWith('ej')) {
    pageType = ANALYTIC_OPTIONS.SECTIONS.EMOLLIENT_JOCKEY;
  } else if (props.imgConfig.startsWith('em')) {
    pageType = ANALYTIC_OPTIONS.SECTIONS.EMOLLIENT_MAESTRO;
  } else if (props.imgConfig.startsWith('sn')) {
    pageType = ANALYTIC_OPTIONS.SECTIONS.SURFACTANT_NAVIGATOR;
  }

  trackEvent({
    event_type: 'action',
    event_category: 'filter',
    event_action: 'snapshot',
    event_value: $t(textKey.value, null, 'en'),
    page_type: pageType,
  });

  html2canvas(el, options).then((canvas: any) => {
    const img = canvas.toDataURL();
    const detail: MyProjectDetail = {
      id: generateUuid(),
      type: props.imgType,
      img,
      size: props.imgFactor,
      data: props.imgData,
    };
    myProjectStore.addImage(detail);
    showPrintDialog.value = false;
  });
};
</script>
