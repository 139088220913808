<template>
  <svg class="icon-wrapper__icon" :width="getWidth()" :height="getHeight()">
    <use
      :color="color"
      :width="getWidth()"
      :height="getHeight()"
      :href="`${require('@/assets/icons/' + icon + '.svg')}#${icon}`"
      :xlink:href="`${require('@/assets/icons/' + icon + '.svg')}#${icon}`"
    />
  </svg>
</template>

<script setup lang="ts">
// TODO: Use Literal Template Types to restrict icons to enum values
// import { ICONS } from '@/typings/enums';

// type IconsType = `${ICONS}`;
type IconsType = string;

interface Props {
  icon: IconsType;
  color?: string;
  size?: number;
  width?: number;
  height?: number;
  scale?: number;
}

const props = withDefaults(defineProps<Props>(), {
  color: 'currentcolor',
  size: undefined,
  width: undefined,
  height: undefined,
  scale: 1,
});

const getWidth = () => {
  if (props.width !== undefined) {
    return props.width * props.scale;
  }
  if (props.size !== undefined) {
    return props.size * props.scale;
  }
  if (props.height !== undefined) {
    return props.height * props.scale;
  }
  return '100%';
};

const getHeight = () => {
  if (props.height !== undefined) {
    return props.height * props.scale;
  }
  if (props.size !== undefined) {
    return props.size * props.scale;
  }
  if (props.width !== undefined) {
    return props.width * props.scale;
  }
  return '100%';
};
</script>
