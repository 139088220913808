import moment from 'moment';
import { useI18n } from '@/composables/useI18n';

const { $d } = useI18n();

/**
 * Return date from provided string
 * @param {string} date // String to format
 * @returns {Date} // String as Date
 */
const stringToDate = (date: string) => {
  return date.length > 5
    ? moment(date, 'YYYY MM').toDate()
    : moment(date, 'YYYY M').toDate();
};

/**
 * Return formatted date from provided string
 * @param {string} date // String to format
 * @returns {string} // Formatted date to YYYY-MM-DD
 */
const formatDateYYYMMDD = (date: string) => {
  return $d(moment(date, 'YYYY-MM-DD').toDate(), 'short');
};

/**
 * Return formatted date from provided string
 * @param {string} date // String to format
 * @returns {string} // Formatted date to YYYY-MM-DD HH:mm:ss
 */
const formatDateYYYYMMDDHHmmss = (date: string) => {
  return $d(moment(date, 'YYYY-MM-DD HH:mm:ss').toDate(), 'short');
};

export { stringToDate, formatDateYYYMMDD, formatDateYYYYMMDDHHmmss };
