export const SunCareCategoryId =
  'SnCr:WrldCtg:9D1AB2A5D81886EA0C5B24D2D68A50B6';

export const SunCareSubCategoriesIds = [
  'SnFtrSn:WrldCtg:1033A441E8ADC407888C497F010A2E30',
  'SnSlfTn:WrldCtg:86775EBC00BBB2E296DDA72B8B701379',
  'SnSnSnb:WrldCtg:464626516B0B63997D5A95501751DC81',
  'SnCrChdPrd:WrldCtg:9D1AB2A5D81886EA0C5B24D2D68A6889',
];

export const UVFunctionsIds = [
  'UV :8D170C3CC93A4FC872D9D80D8CF5ADB0',
  'UV :35E6C35A5B4D0C055E156A87CAD2D2EF',
];

export const UVProductGroupsIds = [
  'VVbFltrs:BsfPrdPrdctGrp:4E03E1C3F3B45723329527444E020C69',
  'VFltrs:BsfPrdPrdctGrp:852CD5F03AEC97A080860DB8A0AA9DA9',
  'VbFltrs:BsfPrdPrdctGrp:63BE20CCB2CB10270411DB77A2638982',
];

export const CategoryEmpty = {
  id: '',
  name: 'All Categories',
  asset: 'allCategories.png',
  assetId: 'allCategories',
  cssVars:
    '--bottom-rainbow: 20px; --left-rainbow: 50px; --left-rainbow-labels: -25px;',
};
export const Categories = [
  {
    id: 'RlHgn:WrldCtg:B2ED30C68B47C8D9EF3C876CA23CF57D',
    name: 'Oral Hygiene',
    asset: 'oralHygiene.png',
    assetId: 'oralHygiene',
    cssVars:
      '--bottom-rainbow: 20px; --left-rainbow: 70px; --left-rainbow-labels: -25px;',
  },
  {
    id: 'ClrCsmt:WrldCtg:D1705A9E3C64B77A4790139BFAC0E79C',
    name: 'Colour Cosmetics',
    asset: 'colourCosmetics.png',
    assetId: 'colourCosmetics',
    cssVars: '--left-rainbow-labels: 15px;',
  },
  {
    id: 'Skncr:WrldCtg:72E3774090F0FAC48E7BC07789AD75D1',
    name: 'Skin Care',
    asset: 'skincare.png',
    assetId: 'skincare',
    cssVars: '--bottom-rainbow: 120px;',
  },
  {
    id: 'HrPrdct:WrldCtg:A2F344C2EED802F67E7B18A9D3C4E9E1',
    name: 'Hair Products',
    asset: 'hairProducts.png',
    assetId: 'hairProducts',
    cssVars: '--bottom-rainbow: 70px; --left-rainbow: 40px;',
  },
  {
    id: 'Ddrnts:WrldCtg:43F6628EA8ED1ABBFB4D2C8F01AFBEEA',
    name: 'Deodorants',
    asset: 'deodorants.png',
    assetId: 'deodorants',
    cssVars: '',
  },
  {
    id: SunCareCategoryId,
    name: 'Sun Care',
    asset: 'suncare.png',
    assetId: 'suncare',
    cssVars: '',
  },
  {
    id: 'SpBthPr:WrldCtg:B1E480BD8072F2374571CA7A12AEA5CC',
    name: 'Soap & Bath Products',
    asset: 'soap.png',
    assetId: 'soap',
    cssVars: '',
  },
];

export const CategoriesWithEmpty = [CategoryEmpty, ...Categories];

//TODO: Check if the id is the same in QA and prod to retrieve based on environment
export const BiodegradabilityOptionsId =
  'BdgrdbltVltnShrt:SstnbltBdgrdblt:6002AE2AE30288A273BEEF08A6BFA8A6';
export const CosmosNaturalityLabelId =
  'Csms:FrmltnLbl:579C48843159E78B257D9DF4588A9A36';
export const CosmosOrganicNaturalityLabelId =
  'CsmsRgnc:FrmltnLbl:4F8F6A0681194329F08EB8D1110FD8F3';
export const NatrueNaturalityLabelId =
  'Ntr:FrmltnLbl:7D0ABDBC8AC8A271AFE0F9E7B70054EB';
export const EcoSunPassLabelId =
  'CSnPss:FrmltnLbl:23C1D03E3A59C6E853102AFDCC8FADDD';
export const CosmosLabelId = 'Sstnblt:Csms:E06090BB1D96EEC8AF856A185C4A29E9';

export const ReadilyBiodegradableId =
  'RdlBdgr:Bdgrdbl:Sstnblt:2A6764A647F40BC70373E5E89B77F8C8';

export const euEcoLabelLeaveOnSectionId =
  'ClblLvN:SstnbltClbls:AF2A06973180608B48862640EACC362F';
export const euEcoLabelRinseOffSectionId =
  'ClblRnsFf:SstnbltClbls:D63784A661108568CFE5C6D99EE549AF';
export const nordicSwanLeaveOnSectionId =
  'NrdcSwnClblLvN:SstnbltClbls:D608573FB39EF9A50DE87581411850FA';
export const nordicSwanRinseOffSectionId =
  'NrdcSwnClblRnsFf:SstnbltClbls:31AC46C335EE2C062F01C98D40D0B36A';
export const sustainableSourcingStandardsSectionId =
  'SstnblSrcngStndrds:SstnbltThrs:770FB64830024AA12EE08295618CCDA6';
export const sustainabilityBiodegradabilitySectionId =
  'BdgrdbltVltnShrt:SstnbltBdgrdblt:6002AE2AE30288A273BEEF08A6BFA8A6';
export const excludedNotApprovedLabels = [
  'NtPprvd:ClblLvN:Sstnblt:2286CDE6C046ADBAD9652EDADA4D30A6',
  'NtPprvd:ClblRns:Sstnblt:2B587B71EB753F0E38D0E826A927EF85',
  'NtPprvd:NrdcSwn:Sstnblt:72F8DBCB38A7549220487CCD48A23651',
  'NtPprvd:NrdcSwn:Sstnblt:50F0E03CC48752CA435DE1653730D7A0',
];
