import { getEnvironment } from '@/lib/utils';

export function setCookie(
  cname: string,
  cvalue: string,
  exhours: number
): void {
  const d = new Date();
  d.setTime(d.getTime() + exhours * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function getCookie(cname: string): string {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function eraseCookie(cname: string): void {
  document.cookie = cname + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
}

export function isPerformanceConsent(): boolean {
  const { isLocal } = getEnvironment();
  let result = isLocal;
  const cookie = getCookie('OptanonConsent');
  if (cookie && cookie.length > 0) {
    const val = decodeURIComponent(cookie);
    if (val && val.length > 0) {
      result = val.indexOf('C0002:1') > -1;
    }
  }
  return result;
}
