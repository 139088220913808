/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
import api from '@/plugins/axiosApi';

export const fetchAllClaims = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/world_claim',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};

export const fetchAllFormulationConsumerBenefits = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/formulation_consumer_benefit',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};
