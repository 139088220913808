import { toRef } from 'vue';
import mainRouter from '@/router';

export const useRouter = () => {
  const route = toRef(mainRouter.app, '$route');
  const router = mainRouter.app.$router;

  return {
    route,
    router,
  };
};
