import { getBase64FromArrayBuffer } from '@/lib/utils';
import api from '@/plugins/axiosApi';
import {
  ConceptsResponse,
  ConceptsAdvancedSearchReq,
  ConceptData,
} from '@/typings/concepts';

export const fetchConceptCollections = async (
  params: ConceptsAdvancedSearchReq
): Promise<ConceptsResponse> => {
  const { data } = await api.post('/api/v1/conceptcollection', params);
  return data;
};

export const fetchConcept = async (conceptId: string): Promise<ConceptData> => {
  const { data } = await api({
    url: `/api/v1/conceptcollection/${conceptId}`,
    method: 'get',
  });
  return data as ConceptData;
};

export const fetchConceptRelatedBasfProducts = async (
  conceptId: string,
  params
) => {
  const resObj = await api({
    url: `/api/v1/conceptcollection/${conceptId}/relatedBasfProducts`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data.relatedBasfProducts;
  }
};

export const fetchConceptRelatedFormulations = async (
  conceptId: string,
  params
) => {
  const resObj = await api({
    url: `/api/v1/conceptcollection/${conceptId}/relatedFormulations`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data.relatedFormulations;
  }
};

export const fetchConceptRelatedConcepts = async (
  conceptId: string,
  params
) => {
  const resObj = await api({
    url: `/api/v1/conceptcollection/${conceptId}/relatedConcepts`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data.relatedConcepts;
  }
};

export const fetchConceptDocument = async (
  conceptId: string,
  userGroup: string,
  documentType: string,
  lang: string,
  blobId: string
) => {
  const { data } = await api({
    url: `/api/v1/conceptcollection/${conceptId}/${userGroup}/${documentType}/${lang}/download/${blobId}`,
    method: 'get',
    responseType: 'arraybuffer',
  });

  return data;
};

export const fetchConceptVideo = async (
  conceptId: string,
  fileId: string,
  lang: string
) => {
  const { data } = await api({
    url: `/api/v1/conceptcollection/${conceptId}/${fileId}/${lang}/videolink`,
    method: 'get',
    responseType: 'arraybuffer',
  });

  return data;
};

export const fetchConceptPicture = async (conceptId: string) => {
  const { data } = await api({
    url: `/api/v1/conceptcollection/${conceptId}/picture/download`,
    method: 'get',
    responseType: 'arraybuffer',
  });

  if (data) return getBase64FromArrayBuffer(data);

  return null;
};

export const fetchAllFilesConcept = async (
  conceptId: string,
  userGroup: string,
  lang: string
) => {
  const { data } = await api({
    url: `/api/v1/conceptcollection/${conceptId}/${userGroup}/${lang}/download`,
    method: 'get',
    responseType: 'arraybuffer',
  });

  return data;
};
