/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  ConsumerProductAdvancedSearchReq,
  TopClaimsProducts,
  TopIngredientsProducts,
} from '@/api/types';
import api from '@/plugins/axiosApi';
import { ProductDiscussedTopics, ProductScore } from '@/typings/products';

export const fetchProductDetailsSearch = async (
  productId: string,
  params: any
) => {
  const resObj: any = await api({
    url: `/api/products/v2/${productId}`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchBasfProductsSearch = async (params: any) => {
  const resObj: any = await api({
    url: '/api/basfproducts',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchBasfProducts = async (params: any) => {
  const resObj: any = await api({
    url: '/api/basfproducts',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchProductsAdvanced = async (
  params: ConsumerProductAdvancedSearchReq
) => {
  const options: any = params.params;

  const jsonData = {
    lang: options.find((item: any) => item.name === 'lang')?.value || 'en',
    _limit: options.find((item: any) => item.name === '_limit')?.value || 10,
    _page: options.find((item: any) => item.name === '_page')?.value || 1,
    _sort:
      options.find((item: any) => item.name === '_sort')?.value ||
      'search.score()',
    _order:
      options.find((item: any) => item.name === '_order')?.value || 'desc',
    mainBrandIds: params.mainBrandIds,
    brandIds: params.brandIds,
    categoryIds: params.categoryIds,
    claimIds: params.claimIds,
    formatIds: params.formatIds,
    marketIds: params.marketIds,
    maxPriceRange: params.maxPriceRange,
    minPriceRange: params.minPriceRange,
    fromDateRange: params.fromDateRange,
    toDateRange: params.toDateRange,
    paIds: params.paIds,
    spfIds: params.spfIds,
    subCategoryIds: params.subCategoryIds,
    q: params.q ? params.q : '*',
    ingredientIncludeAnyIds: params.includedIngredientIds,
    ingredientExcludeIds: params.excludedIngredientIds,
  };

  const resObj: any = await api.post(`/api/products/v2`, jsonData);
  if (resObj) {
    return resObj.data;
  }
};

export const fetchTopClaimsProducts = async (
  params: ConsumerProductAdvancedSearchReq
): Promise<TopClaimsProducts> => {
  const options: any = params.params;

  const jsonData = {
    lang: options.find((item: any) => item.name === 'lang')?.value || 'en',
    mainBrandIds: params.mainBrandIds,
    brandIds: params.brandIds,
    categoryIds: params.categoryIds,
    formatIds: params.formatIds,
    marketIds: params.marketIds,
    maxPriceRange: params.maxPriceRange,
    minPriceRange: params.minPriceRange,
    paIds: params.paIds,
    spfIds: params.spfIds,
    subCategoryIds: params.subCategoryIds,
    ingredientIncludeAnyIds: params.includedIngredientIds,
    ingredientExcludeIds: params.excludedIngredientIds,
  };

  const { data } = await api.post(`/api/products/top-claims/v2`, jsonData);
  return data;
};

export const fetchTopIngredientsProducts = async (
  params: ConsumerProductAdvancedSearchReq
): Promise<TopIngredientsProducts> => {
  const options: any = params.params;

  const jsonData = {
    lang: options.find((item: any) => item.name === 'lang')?.value || 'en',
    mainBrandIds: params.mainBrandIds,
    brandIds: params.brandIds,
    categoryIds: params.categoryIds,
    formatIds: params.formatIds,
    marketIds: params.marketIds,
    maxPriceRange: params.maxPriceRange,
    minPriceRange: params.minPriceRange,
    fromDateRange: params.fromDateRange,
    toDateRange: params.toDateRange,
    paIds: params.paIds,
    spfIds: params.spfIds,
    subCategoryIds: params.subCategoryIds,
    claimIds: params.claimIds,
    ingredientFunctions: params.functionSelection,
    ingredientIncludeAnyIds: params.includedIngredientIds,
    ingredientExcludeIds: params.excludedIngredientIds,
  };

  const { data } = await api.post(`/api/products/top-ingredients/v2`, jsonData);
  return data;
};

export const fetchProductDetail = async (productId: string, params: any) => {
  const resObj: any = await api({
    url: `/api/products/v2/${productId}`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchProductFeedbackAnalysis = async (
  productId: string,
  params: any
) => {
  const resObj: any = await api({
    url: `/api/products/${productId}/feedback-analysis`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchConsumerInsight = async (productId: string, params: any) => {
  const endpointSuffix = params.isBevol ? '/v1' : '';
  const resObj: any = await api({
    url: `/api/products/${productId}/consumer-insight${endpointSuffix}`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchProductCountryConversationvolume = async (
  productId: string,
  params: any
) => {
  const resObj: any = await api({
    url: `/api/products/${productId}/country-conversationvolume`,
    method: 'get',
    params,
  });
  return (
    resObj.data || {
      datasets: [],
    }
  );
};

export const fetchChinaProvincesInfo = async (
  productId: string,
  params: any
) => {
  const resObj: any = await api({
    url: `/api/products/${productId}/province/v1`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchTopClaims = async (productId: string, params: any) => {
  const resObj: any = await api({
    url: `/api/products/${productId}/top-discussed-topics`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchProductReach = async (productId: string, params: any) => {
  const endpointSuffix = params.isBevol ? '/v1' : '';
  const resObj: any = await api({
    url: `/api/products/${productId}/conversationvolume-reach-time${endpointSuffix}`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchTopTrendingIngredients = async (
  brandId: string,
  params: any
) => {
  const resObj: any = await api({
    url: `/api/products/${brandId}/top-ingredients/v2`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchConsumerProductSourceConversationVolume = async (
  productId: string,
  params: any
) => {
  const resObj = await api({
    url: `/api/products/${productId}/source-conversationvolume`,
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchBasfProductsMultipleSearchTerms = async (
  id: string,
  params: any
) => {
  const resObj = await api({
    url: `/api/formulations/${id}/related-basf-products`,
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchProductScore = async (
  productId: string,
  params: any
): Promise<ProductScore> => {
  const resObj = await api({
    url: `/api/products/${productId}/score/v1`,
    method: 'get',
    params,
  });
  return resObj.data;
};

export const fetchTopDiscussedTopics = async (
  productId: string,
  params: any
): Promise<ProductDiscussedTopics> => {
  const resObj = await api({
    url: `/api/products/${productId}/top-discussed-topics/v1`,
    method: 'get',
    params,
  });
  return resObj.data;
};
