/* eslint-disable  @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia';
import {
  ConceptRelatedSelectionReq,
  RelatedConcept,
  RelatedBasfProduct,
  RelatedFormulation,
} from '@/api/types';
import {
  fetchConcept,
  fetchConceptCollections,
  fetchConceptRelatedBasfProducts,
  fetchConceptRelatedConcepts,
  fetchConceptRelatedFormulations,
} from '@/api/conceptCollection';
import { arrayToObject, getItemFromStorage, setItemStorage } from '@/lib/utils';
import {
  ConceptData,
  ConceptsAdvancedSearchPref,
  ConceptsAdvancedSearchReq,
} from '@/typings/concepts';

const localStorageKey = 'ConceptsAdvancedPref';
const emtpySearchParams: ConceptsAdvancedSearchPref = {
  languageSelection: [],
  regionSelection: [],
  categorySelection: [],
  yearSelection: [],
};

const savedSearchParams = getItemFromStorage(localStorageKey);

interface State {
  concepts: ConceptData[];
  noOfConcepts: number;
  concept: ConceptData | null;
  quickSearchParam: string | null;
  sortParam: string;
  pageParam: number;
  relatedBasfProducts: RelatedBasfProduct[];
  relatedConcepts: RelatedConcept[];
  relatedFormulations: RelatedFormulation[];
  conceptsAdvancedPref: ConceptsAdvancedSearchPref;
  backupConceptsAdvancedPref: ConceptsAdvancedSearchPref;
  openedFilters: string[];
  fetchedFiltersData: boolean;
}

export const useConceptCollectionStore = defineStore('concetpCollection', {
  state: (): State => ({
    concepts: [],
    concept: null,
    noOfConcepts: 0,
    quickSearchParam: '',
    sortParam: '',
    pageParam: 1,
    relatedBasfProducts: [],
    relatedConcepts: [],
    relatedFormulations: [],
    conceptsAdvancedPref: {
      ...emtpySearchParams,
      ...(savedSearchParams || {}),
    },
    backupConceptsAdvancedPref: {
      ...emtpySearchParams,
      ...(savedSearchParams || {}),
    },
    openedFilters: [],
    fetchedFiltersData: false,
  }),

  getters: {
    appliedFilters: (state) => () => {
      return Object.entries(state.backupConceptsAdvancedPref)
        .filter(([key, _val]) => key !== 'q' && key !== 'params')
        .reduce((acc, [_key, val]) => {
          acc.push(val);

          return acc;
        }, [])
        .flat()
        .sort();
    },

    newFilters: (state) => () => {
      return Object.entries(state.conceptsAdvancedPref)
        .filter(([key, _val]) => key !== 'q' && key !== 'params')
        .reduce((acc, [_key, val]) => {
          acc.push(val);

          return acc;
        }, [])
        .flat()
        .sort();
    },
  },

  actions: {
    setQuickSearchParam(quickSearch: string) {
      this.quickSearchParam = quickSearch;
    },

    setSortParam(sort: string) {
      this.sortParam = sort;
    },

    setPageParam(page: number) {
      this.pageParam = page;
    },

    setOpenedFilters(filters: string[]) {
      this.openedFilters = filters;
    },

    setFetchedDataFilters(value: boolean) {
      this.fetchedFiltersData = value;
    },

    restoreFilters() {
      this.conceptsAdvancedPref = JSON.parse(
        JSON.stringify(this.backupConceptsAdvancedPref)
      );
    },

    async getFormulationRelatedConcepts(params: any) {
      const responseData = await fetchConceptCollections(params);
      return responseData.content;
    },

    async getConcepts(reqObj: ConceptsAdvancedSearchReq) {
      const responseData = await fetchConceptCollections(reqObj);
      this.concepts = responseData.content || [];
      this.noOfConcepts = responseData.metaData.totalElements;
      return responseData;
    },

    async getConcept(reqId: string) {
      const responseData = await fetchConcept(reqId);
      this.concept = responseData;
    },

    async getRelatedBasfProducts(reqObj: ConceptRelatedSelectionReq) {
      const reqParams = arrayToObject(reqObj.params);
      const responseData = await fetchConceptRelatedBasfProducts(
        reqObj.conceptId,
        reqParams
      );
      this.relatedBasfProducts = responseData || [];
    },

    async getRelatedConcepts(reqObj: ConceptRelatedSelectionReq) {
      const reqParams = arrayToObject(reqObj.params);
      const responseData = await fetchConceptRelatedConcepts(
        reqObj.conceptId,
        reqParams
      );
      this.relatedConcepts = responseData || [];
    },

    async getRelatedFormulations(reqObj: ConceptRelatedSelectionReq) {
      const reqParams = arrayToObject(reqObj.params);
      const responseData = await fetchConceptRelatedFormulations(
        reqObj.conceptId,
        reqParams
      );
      this.relatedFormulations = responseData || [];
    },

    setFormulationsAdvancedPref(
      conceptsAdvancedPref: ConceptsAdvancedSearchPref,
      updateBackup = false
    ) {
      setItemStorage(localStorageKey, conceptsAdvancedPref);
      this.conceptsAdvancedPref = conceptsAdvancedPref;

      if (updateBackup) {
        this.backupConceptsAdvancedPref = conceptsAdvancedPref;
      }
    },
  },
});
