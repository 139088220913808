import './registerServiceWorker';
import Vue from 'vue';
import App from '@/App.vue';
import pinia from '@/plugins/pinia';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '@/scss/main.scss';
import '@/scss/menu_icons.scss';
import 'video.js/dist/video-js.css';
import '@/lib/fontAwesome';
import './class-component-hooks';
import VueMeta from 'vue-meta';
import VueRouter from 'vue-router';
import router from '@/router';
import {
  QueryClient,
  VueQueryPlugin,
  VueQueryPluginOptions,
} from '@tanstack/vue-query';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60 * 8, // 8 hours
      cacheTime: 1000 * 60 * 60 * 8, // 8 hours,
      refetchOnWindowFocus: false,
    },
  },
});

const vueQueryOptions: VueQueryPluginOptions = {
  queryClient: client,
};

Vue.use(VueMeta);
Vue.use(VueQueryPlugin, vueQueryOptions);

Vue.config.productionTip = false;

Vue.use(VueRouter);

new Vue({
  pinia,
  router,
  i18n,
  vuetify,
  render: (h) => h(App),
  mounted() {
    AOS.init();
  },
  metaInfo: () => ({
    title: 'D’lite - Market Understanding meets Science',
    meta: [
      {
        name: 'description',
        content:
          'In today’s highly fragmented and fast-moving cosmetic markets, keeping pace with consumer trends and wishes is challenging. Traditional market analysis and product development processes are no longer fast and flexible enough.',
        noscript: [
          {
            innerHTML:
              'In today’s highly fragmented and fast-moving cosmetic markets, keeping pace with consumer trends and wishes is challenging. Traditional market analysis and product development processes are no longer fast and flexible enough.',
          },
        ],
      },
      { name: 'robots', content: 'index,follow' },
      {
        property: 'og:title',
        content: 'D’lite - Market Understanding meets Science',
      },
      { property: 'og:site_name', content: 'D’lite' },
      { property: 'og:type', content: 'website' },
    ],
  }),
}).$mount('#app');
