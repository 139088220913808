var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'dlite-main-drawer',
    {
      'dlite-main-drawer__collapsed': _setup.isLowerThanMD && !_setup.isDrawerExpanded,
    },
  ],style:(_setup.mainDrawerStyle),attrs:{"expanded":_setup.isDrawerExpanded}},[(_setup.isDrawerFullScreen)?_c('header',{staticClass:"dlite-main-drawer__fullscreen-header"},[_c('button',{class:[
        'dlite-main-drawer__fullscreen-header__close-btn',
        {
          'dlite-main-drawer__fullscreen-header__close-btn--hidden':
            !_setup.isDrawerExpanded,
        },
      ],attrs:{"aria-label":_setup.$tc('app.accessibility.close-navigation')},on:{"click":_setup.hideDrawer}},[_c(_setup.BaseIcon,{attrs:{"icon":_setup.ICONS.CLOSE}})],1)]):_vm._e(),_c('nav',{ref:"mainDrawer",attrs:{"id":_setup.mainDrawerId,"aria-expanded":_setup.isDrawerExpanded}},[_c('ul',_vm._l((_vm.apps),function(eco){return _c('li',{key:eco.id,class:[
          _setup.listItemClass(eco),
          'drawer-item',
          _setup.getItemClass(eco),
          { 'drawer-item--selected': _setup.isSelected(eco) },
        ]},[(eco.newPage)?_c('a',{staticClass:"menu-router-link",attrs:{"target":"_blank","href":eco.href},on:{"click":function($event){return _setup.trackRedirection(eco)}}},[_c('v-tooltip',{attrs:{"content-class":"menu-tooltip","right":"","nudge-right":"6","transition":"slide-x-transition","disabled":!_setup.drawerMini || _setup.isMobile},scopedSlots:_vm._u([{key:`activator`,fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({class:'float-left drawer-icon ' + eco.icon},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_setup.$t(eco.text)))])]),_c('span',{staticClass:"normalTextColor ml-5 pt-1"},[_vm._v(" "+_vm._s(_setup.$t(eco.text))+" ")])],1):(eco.isVisible)?_c('router-link',{staticClass:"menu-router-link",class:!eco.isAllowed ? 'disabled' : '',attrs:{"to":eco.id !== _setup.route.name ? eco.href : {}},nativeOn:{"click":function($event){return _setup.closeDrawer.apply(null, arguments)}}},[_c('v-tooltip',{attrs:{"content-class":"menu-tooltip","right":"","nudge-right":"6","transition":"slide-x-transition","disabled":!_setup.drawerMini || _setup.isMobile},scopedSlots:_vm._u([{key:`activator`,fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({class:[
                  'float-left',
                  'drawer-icon',
                  { 'drawer-icon--selected': _setup.isSelected(eco) },
                  eco.icon,
                ]},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',{style:({
                color: _setup.isSelected(eco) ? eco.color : '',
              })},[_vm._v(" "+_vm._s(_setup.$t(eco.text))+" ")])]),_c('span',{staticClass:"title-item ml-5",style:({
              color: _setup.isSelected(eco) ? 'white' : '',
            })},[_vm._v(_vm._s(_setup.$t(eco.text))+" ")])],1):_vm._e()],1)}),0),(!_setup.isLowerThanMD)?_c('div',{staticClass:"mt-4 drawer-chevron",attrs:{"aria-hidden":"true"}},[_c('v-btn',{staticClass:"drawer-chevron-btn",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _setup.toggleMiniDrawer.apply(null, arguments)}}},[(_setup.drawerMini)?_c('v-icon',{staticClass:"drawer-chevron",attrs:{"large":""}},[_vm._v("mdi-chevron-right")]):_c('v-icon',{staticClass:"drawer-chevron",attrs:{"large":""}},[_vm._v("mdi-chevron-left")])],1)],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }