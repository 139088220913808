/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
import api from '@/plugins/axiosApi';

export const fetchAllFormats = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/world_format',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};
