var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{class:[
    'base-button',
    {
      'base-button--regular': _vm.regular,
      'base-button--rounded': _vm.rounded,
      'base-button--outlined': _vm.outlined,
      'base-button--link': _vm.link,
      'base-button--uppercase': _vm.uppercase,
      'base-button--disabled': _vm.disabled,
    },
  ],attrs:{"disabled":_vm.disabled},on:{"click":($event) => _setup.onClick($event)}},[(_vm.iconLeft)?_c(_setup.BaseIcon,{staticClass:"base-button__icon",attrs:{"icon":_vm.iconLeft}}):_vm._e(),_vm._t("default",function(){return [_vm._v(_vm._s(_vm.label))]}),(_vm.iconRight)?_c(_setup.BaseIcon,{staticClass:"base-button__icon",attrs:{"icon":_vm.iconRight}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }