var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'search-input-wrapper',
    { 'search-active': !_vm.isOnlyButton && _setup.isExpanded },
  ]},[_c('label',{ref:"searchInputLabel",class:[
      'search-input-label',
      {
        'rectangle-border': _vm.showRectangleBorder,
        colorized: _vm.colorized,
      },
    ]},[(_vm.searchIcon)?_c('button',{staticClass:"search-icon-button search-input-mag-button",attrs:{"type":_setup.isExpanded ? 'submit' : 'button',"aria-label":_setup.isExpanded ? 'submit' : 'expand'},on:{"click":_setup.searchButtonHandler}},[_c('img',{attrs:{"src":`${require('@/assets/icons/search_icon.svg')}`,"alt":"search"}})]):_vm._e(),(!_vm.isOnlyButton)?_c('span',{staticClass:"search-input"},[_c('input',{ref:"searchBoxInput",class:{ colorized: _vm.colorized },attrs:{"type":"text","placeholder":_setup.searchPlaceholder,"disabled":_setup.isDisabled || !_setup.isExpanded},domProps:{"value":_setup.searchInputField},on:_vm._d({"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _setup.doSearch.apply(null, arguments)}},[_setup.searchEvent,_setup.doSearch])})]):_vm._e(),(
        !_vm.isOnlyButton && (_vm.closeIcon || (_vm.closeIconIfText && _setup.searchInputField))
      )?_c('button',{staticClass:"search-icon-button search-input-close-button",attrs:{"type":"button","disabled":!_setup.isExpanded,"aria-label":"clear search"},on:{"pointerdown":_setup.closeButtonHandler,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _setup.closeButtonHandler.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _setup.closeButtonHandler.apply(null, arguments)}]}},[_c('img',{attrs:{"src":`${require('@/assets/icons/close_icon.svg')}`,"alt":"close search"}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }