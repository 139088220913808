<template>
  <div>
    <div v-if="showButton" @click="openDialog">
      <!-- BUTTON -->
      <slot name="button"></slot>
    </div>
    <template v-else>
      <slot></slot>
    </template>

    <v-dialog
      :value="modelValue"
      :max-width="maxWidth"
      :fullscreen="isLowerThanSM"
      :content-class="contentClass"
      :persistent="shouldPersist"
      @click:outside="closeDialog"
      @keydown.esc="escapeDown"
    >
      <v-card>
        <v-card-title
          :class="[
            'card-title',
            {
              'themed-bg': themedTitle,
              'white--text': themedTitle,
            },
          ]"
        >
          <!-- TITLE -->
          <slot name="title">
            <span>{{ title }}</span>
          </slot>
          <!-- CLOSE -->
          <slot name="close">
            <v-btn text plain :ripple="false" @click="closeDialog">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </slot>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          :class="['card-text', { 'formatted-text': hasFormattedText }]"
        >
          <!-- DEFAULT -->
          <slot></slot>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions
          :class="[
            'card-actions',
            { 'card-actions--vertical': verticalActions },
          ]"
        >
          <!-- ACTIONS -->
          <slot name="actions"></slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { useResponsive } from '@/composables/useResponsive';
import { computed, watch, onMounted } from 'vue';

interface Props {
  modelValue: boolean;
  preloadDialog?: boolean;
  allResolutions?: boolean;
  closeDialogOnEsc?: boolean;
  maxWidth?: string | number;
  title?: string;
  contentClass?: string;
  verticalActions?: boolean;
  shouldPersist?: boolean;
  themedTitle?: boolean;
  hasFormattedText?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  preloadDialog: false,
  allResolutions: false,
  closeDialogOnEsc: true,
  maxWidth: '75%',
  title: '',
  contentClass: '',
  verticalActions: false,
  shouldPersist: false,
  themedTitle: false,
  hasFormattedText: false,
});

const emit = defineEmits(['update:modelValue', 'close', 'open', 'apply']);

const { isSM, isLowerThanSM } = useResponsive();

const showButton = computed(() => props.allResolutions || isSM.value);

const openDialog = () => {
  emit('update:modelValue', true);
};

const closeDialog = () => {
  emit('update:modelValue', false);
};

const escapeDown = () => {
  if (props.closeDialogOnEsc) {
    closeDialog();
  }
};

watch(
  () => props.modelValue,
  (value) => {
    if (value) {
      emit('open');
    } else {
      emit('close');
    }
  }
);

onMounted(() => {
  if (props.preloadDialog && showButton.value) {
    // Apply initial filters if dialog is closed
    emit('apply');
  }
});
</script>

<style lang="scss" scoped>
.card-title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  padding-right: 0 !important;
  word-break: unset;
}
.card-text {
  max-height: 70vh;
  overflow-y: auto;
  padding: 24px !important;
}
.card-actions {
  justify-content: center;

  &--vertical {
    flex-direction: column;
    gap: 0.5rem;

    :deep() {
      .base-button {
        width: 100%;
        text-transform: none;
        margin: 0 !important;
      }
    }
  }

  &:empty {
    display: none;
  }
}
</style>
