import { defineStore } from 'pinia';

interface State {
  searchQuery: string;
  showBAItems: boolean;
  showCPItems: boolean;
  showBPItems: boolean;
  showFDItems: boolean;
  showISItems: boolean;
  showLMItems: boolean;
  showCCItems: boolean;
}

export const useSearchStore = defineStore('search', {
  state: (): State => ({
    searchQuery: '',
    showBAItems: true,
    showCPItems: true,
    showBPItems: true,
    showFDItems: true,
    showISItems: true,
    showLMItems: true,
    showCCItems: true,
  }),

  actions: {
    setSearchQuery(searchQuery: string) {
      this.searchQuery = searchQuery;
    },
    setShowBAItems(showBAItems: boolean) {
      this.showBAItems = showBAItems;
    },
    setShowCPItems(showCPItems: boolean) {
      this.showCPItems = showCPItems;
    },
    setShowBPItems(showBPItems: boolean) {
      this.showBPItems = showBPItems;
    },
    setShowFDItems(showFDItems: boolean) {
      this.showFDItems = showFDItems;
    },
    setShowISItems(showISItems: boolean) {
      this.showISItems = showISItems;
    },
    setShowLMItems(showLMItems: boolean) {
      this.showLMItems = showLMItems;
    },
    setShowCCItems(showCCItems: boolean) {
      this.showCCItems = showCCItems;
    },
  },
});
