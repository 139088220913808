import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faLock,
  faChevronRight,
  faLockOpen,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faSpinner,
  faExpandAlt,
  faPlay,
  faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(
  faLock,
  faChevronRight,
  faLockOpen,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faSpinner,
  faExpandAlt,
  faPlay,
  faThumbsUp
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
