import i18n from '@/plugins/i18n';
import VueI18n from 'vue-i18n';
import { useAppStore } from '@/stores/app';

import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/zh-cn';
import 'moment/locale/th';

const languagesMapMoment: Record<string, string> = {
  jp: 'ja',
  kr: 'ko',
  zh: 'zh-cn',
};

export const useI18n = () => {
  // TODO: remove when upgrade to vue-i18n composition api
  const $t = (
    key: string,
    values?: VueI18n.Values | undefined,
    locale = i18n.locale
  ) => i18n.t(key, locale, values) as string;

  // TODO: remove when upgrade to vue-i18n composition api
  const $tc = (
    key: VueI18n.Path,
    choice?: VueI18n.Choice,
    values?: VueI18n.Values
  ) => i18n.tc(key, choice, values) as string;

  const $te = (key: VueI18n.Path, locale?: VueI18n.Locale) =>
    i18n.te(key, locale);

  const $d = (
    value: number | Date,
    key?: VueI18n.Path,
    locale?: VueI18n.Locale
  ) => i18n.d(value, key, locale);

  const updateLocale = (locale = 'en') => {
    const appStore = useAppStore();

    i18n.locale = locale;
    appStore.updateLocale(locale);
    moment.locale(languagesMapMoment[locale] || locale);
    localStorage.setItem('i18n', locale);
  };

  // const tte = (key: string | undefined, params: never | undefined = undefined) => {
  //     const { t, te } = useI18n();
  //     if (key && te(key)) {
  //       return params ? t(key, params) : t(key);
  //     }
  //     return key;
  //   };

  return {
    $t,
    $tc,
    $te,
    $d,
    locale: i18n.locale,
    updateLocale,
  };
};
