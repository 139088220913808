import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
import { ClickOutside } from 'vuetify/lib/directives';

Vuetify.config.silent = true;

Vue.use(Vuetify, {
  directives: {
    ClickOutside,
  },
});

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});

const getVideoBreakPointColCount = () => {
  switch (vuetify.framework.breakpoint.name) {
    case 'xs':
      return 1;
    case 'sm':
      return 2;
    case 'md':
      return 3;
    case 'lg':
      return 4;
    case 'xl':
      return 4;
    default:
      return 1;
  }
};

export { vuetify as default, getVideoBreakPointColCount };
