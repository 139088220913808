/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
import api from '@/plugins/axiosApi';
import {
  EmollientJockeyBenchmarkResponse,
  EmollientJockeyProductResponse,
} from '@/api/types';

export const fetchEmollientJockeyProducts = async (params: any) => {
  const resObj: any = await api({
    url: '/api/emollient/jockey/products',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data as EmollientJockeyProductResponse;
  }
};

export const fetchEmollientJockeyBenchmarks = async (params: any) => {
  const resObj: any = await api({
    url: '/api/emollient/jockey/benchmarks',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data as EmollientJockeyBenchmarkResponse;
  }
};
