/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  CompositionPhase,
  FormulationAdvancedSearchReq,
  FormulationDetail,
} from '@/api/types';
import api from '@/plugins/axiosApi';

export const fetchFormulationDetail = async (
  id: string,
  params = {}
): Promise<FormulationDetail> => {
  const resObjTmp: any = await api({
    url: `/api/formulations/${id}/compositionphases`,
    method: 'get',
    params,
  });
  const resObj: any = await api({
    url: `/api/formulations/${id}`,
    method: 'get',
    params,
  });
  if (resObj) {
    const data = resObj.data;
    if (resObjTmp && resObjTmp.data) {
      data['ingredientsCompositions'] = resObjTmp.data
        .slice()
        .sort((a: CompositionPhase, b: CompositionPhase) =>
          a.phase && b.phase && a.phase.name > b.phase.name ? 1 : -1
        );
    } else {
      data['ingredientsCompositions'] = [];
    }

    return data as FormulationDetail;
  }
};

export const fetchFormulations = async (params: any) => {
  const resObj: any = await api({
    url: '/api/formulations',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchFormulationsSearch = async (params: any) => {
  const resObj: any = await api({
    url: '/api/formulations',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj.data;
  }
};

export const fetchFormulationsAdvanced = async (
  params: FormulationAdvancedSearchReq
) => {
  const options: any = params.params;

  const jsonData = {
    _limit: options.filter(function (item: any) {
      return item.name === '_limit';
    })[0].value,
    _page: options.filter(function (item: any) {
      return item.name === '_page';
    })[0].value,
    _sort: options.filter(function (item: any) {
      return item.name === '_sort';
    })[0].value,

    categoryIds: params.categoryIds,
    subCategoryIds: params.subCategoryIds,
    claimIds: params.claimIds,
    consumerBenefitIds: params.consumerBenefitIds,
    labelIds: params.labelIds,
    excludeListComparabilityIds: params.excludeListComparabilityIds,
    includedSustainabilityIds: params.includedSustainabilityIds,
    formatIds: params.formatIds,
    spfIds: params.spfIds,
    releasedForIds: params.releasedForIds,
    includedTradenameIds: params.includedTradenameIds,
    excludedTradenameIds: params.excludedTradenameIds,
    includedIngredientIds: params.includedIngredientIds,
    excludedIngredientIds: params.excludedIngredientIds,
    ...(params.rcc && {
      rcc: {
        minValue: params.rcc[0],
        maxValue: params.rcc[1],
      },
    }),
    ...(params.biodegradability && {
      biodegradability: {
        minValue: params.biodegradability[0],
        maxValue: params.biodegradability[1],
      },
    }),
    accessTypes: params.accessTypes || [],
    q: params.q || '*',
  };

  const resObj = await api.post(`/api/formulations`, jsonData);
  if (resObj) {
    return resObj.data;
  }
};
