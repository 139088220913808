/* eslint-disable  @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia';
import { fetchTrend, fetchTrends } from '@/api/trends';
import { getItemFromStorage, setItemStorage } from '@/lib/utils';
import {
  TrendData,
  TrendsAdvancedSearchPref,
  TrendsAdvancedSearchReq,
} from '@/typings/trends';

const localStorageKey = 'TrendsAdvancedPref';
const emtpySearchParams: TrendsAdvancedSearchPref = {
  languageSelection: [],
  regionSelection: [],
  categorySelection: [],
  yearSelection: [],
  lifestyleSelection: [],
};

const savedSearchParams = getItemFromStorage(localStorageKey);

interface State {
  trends: TrendData[];
  trend: TrendData | null;
  noOfTrends: number;
  quickSearchParam: string | null;
  sortParam: string;
  pageParam: number;
  trendsAdvancedPref: TrendsAdvancedSearchPref;
  backupTrendsAdvancedPref: TrendsAdvancedSearchPref;
  openedFilters: string[];
  fetchedFiltersData: boolean;
}

export const useTrendsStore = defineStore('trends', {
  state: (): State => ({
    trends: [],
    trend: null,
    noOfTrends: 0,
    quickSearchParam: '',
    sortParam: '',
    pageParam: 1,
    trendsAdvancedPref: {
      ...emtpySearchParams,
      ...(savedSearchParams || {}),
    },
    backupTrendsAdvancedPref: {
      ...emtpySearchParams,
      ...(savedSearchParams || {}),
    },
    openedFilters: [],
    fetchedFiltersData: false,
  }),

  getters: {
    appliedFilters: (state) => () => {
      return Object.entries(state.backupTrendsAdvancedPref)
        .filter(([key, _val]) => key !== 'q' && key !== 'params')
        .reduce((acc, [_key, val]) => {
          acc.push(val);

          return acc;
        }, [])
        .flat()
        .sort();
    },

    newFilters: (state) => () => {
      return Object.entries(state.trendsAdvancedPref)
        .filter(([key, _val]) => key !== 'q' && key !== 'params')
        .reduce((acc, [_key, val]) => {
          acc.push(val);

          return acc;
        }, [])
        .flat()
        .sort();
    },
  },

  actions: {
    setQuickSearchParam(quickSearch: string) {
      this.quickSearchParam = quickSearch;
    },

    setSortParam(sort: string) {
      this.sortParam = sort;
    },

    setPageParam(page: number) {
      this.pageParam = page;
    },

    setOpenedFilters(filters: string[]) {
      this.openedFilters = filters;
    },

    setFetchedDataFilters(value: boolean) {
      this.fetchedFiltersData = value;
    },

    restoreFilters() {
      this.trendsAdvancedPref = JSON.parse(
        JSON.stringify(this.backupTrendsAdvancedPref)
      );
    },

    // async getFormulationRelatedConcepts(params: any) {
    //   const responseData = await fetchTrends(params);
    //   return responseData.content;
    // },

    async getTrends(reqObj: TrendsAdvancedSearchReq) {
      const responseData = await fetchTrends(reqObj);
      this.trends = responseData.content || [];
      this.trends = this.trends.map((trend) => {
        return {
          ...trend,
          trendYear: new Date(trend.uploadDate).getFullYear(),
        };
      });
      this.noOfTrends = responseData.metaData.totalElements;
      return responseData;
    },

    async getTrend(reqId: string) {
      const responseData = await fetchTrend(reqId);
      this.trend = responseData;
    },

    setFormulationsAdvancedPref(
      trendsAdvancedPref: TrendsAdvancedSearchPref,
      updateBackup = false
    ) {
      setItemStorage(localStorageKey, trendsAdvancedPref);
      this.trendsAdvancedPref = trendsAdvancedPref;

      if (updateBackup) {
        this.backupTrendsAdvancedPref = trendsAdvancedPref;
      }
    },
  },
});
