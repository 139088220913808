<template>
  <BaseDialog
    allResolutions
    max-width="600px"
    contentClass="info-dialog"
    :model-value="dialog"
    @update:modelValue="(value) => (dialog = value)"
  >
    <template #button>
      <button :class="{ 'chart-dialog-button': inChart }">
        <span class="px-1">
          <v-icon small class="iconPadding"> mdi-source-branch </v-icon>
        </span>
        <span>
          <slot></slot>
        </span>
      </button>
    </template>

    <div class="dialog-content">
      <MDAsset
        :inlineContent="sourceContent"
        :isPublic="false"
        :fileApi="fileName"
        :folderApi="folderApi"
      />
    </div>
  </BaseDialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRouter } from '@/composables/useRouter';
import BaseDialog from '@/components/common/base/BaseDialog.vue';
import MDAsset from '@/components/content/MDAsset.vue';
import { mdFilePath } from '@/lib/utils';

interface Props {
  inChart?: boolean;
  file?: string;
  folder?: string;
  sourceContent?: string;
  mdPath?: string;
  isBevol?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  inChart: false,
  file: 'source',
  mdPath: 'md/app/use/',
  folder: '',
  sourceContent: '',
  isBevol: false,
});

const { route } = useRouter();

const dialog = ref(false);

const fileName = computed(() =>
  props.isBevol ? `${props.file}-bevol` : props.file
);

const folderApi = computed(() => {
  return mdFilePath(props.mdPath, props.folder, route.value.name as string);
});
</script>
