import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/i18n/en.json';
import pt from '@/i18n/pt.json';
import es from '@/i18n/es.json';
import zh from '@/i18n/zh.json';
import ja from '@/i18n/ja.json';
import ko from '@/i18n/ko.json';
import th from '@/i18n/th.json';

import dateTimeFormats from '@/i18n/date-time-formats';

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: localStorage.getItem('i18n') || process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: { en, pt, es, zh, ja, ko, th },
  dateTimeFormats,
});

export default i18n;
