<template>
  <div class="language-selector">
    <div
      class="language-selector__wrapper"
      ref="languageSelector"
      @click="showLanguageList = !showLanguageList"
    >
      <v-icon aria-label="language-selector"> mdi-earth </v-icon>

      <span class="language-selector__wrapper__language">
        {{ selectedLanguageName }}
      </span>
      <font-awesome-icon
        icon="chevron-down"
        size="xs"
        class="language-selector__wrapper__arrow"
      />
    </div>

    <transition name="fade" mode="in-out">
      <div class="language-selector__list" v-show="showLanguageList">
        <div
          v-for="language in availableLanguages"
          class="language-selector__list__item"
          :class="{
            'language-selector__list__item--selected': language.selected,
          }"
          :key="language.id"
          @click="selectLanguage(language)"
        >
          {{ language.name }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { AVAILABLE_LANGUAGES_LIST } from '@/constants';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from '@/composables/useI18n';
import { LanguageItem } from '@/typings/common';
import { onClickOutside } from '@vueuse/core';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/auth';

interface Props {
  hideSelector?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  hideSelector: false,
});

const { $t, updateLocale } = useI18n();

const appStore = useAppStore();
const { isDev, isLocal } = useAuthStore();

const selectedLanguage = ref('en');
const showLanguageList = ref(false);
const languageSelector = ref(null);

const selectedLanguageName = computed(() => {
  return languageNames.value[selectedLanguage.value];
});

const languageNames = computed(() => {
  return availableLanguages.value.reduce((acc, val) => {
    acc[val.id] = val.name;
    return acc;
  }, {});
});

const availableLanguages = computed((): LanguageItem[] => {
  const languagesList = AVAILABLE_LANGUAGES_LIST.map((lang) => {
    return {
      ...lang,
      name: $t(lang.name),
      selected: selectedLanguage.value === lang.id,
    };
  });

  // Fake option to show keys on dev/local
  if (isDev || isLocal) {
    languagesList.push({
      id: '',
      name: $t(`app.common.languages.show-keys`),
      selected: selectedLanguage.value === '',
    });
  }

  return languagesList;
});

const selectLanguage = (language: LanguageItem) => {
  selectedLanguage.value = language.id;
  updateLocale(language.id);

  showLanguageList.value = false;

  appStore.resetStoresFilters();
};

onClickOutside(languageSelector, () => (showLanguageList.value = false));

onMounted(() => {
  const language = localStorage.getItem('i18n') || 'en';
  const selectedLanguage = availableLanguages.value.find(
    (lang) => lang.id === language
  ) as LanguageItem;
  selectLanguage(selectedLanguage);
});

watch(
  () => props.hideSelector,
  () => {
    showLanguageList.value = false;
  }
);
</script>

<style lang="scss" scoped>
.language-selector {
  position: relative;

  &__wrapper {
    background-color: white;
    border-radius: 20px;
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    border: 1px solid var(--atoms-basf-gray-300);

    &__language {
      @media (max-width: $breakpoint-sm) {
        display: none;
      }
    }

    &__arrow {
      margin-right: 3px;
    }
  }

  .v-icon {
    color: black;
  }

  &__list {
    position: absolute;
    background-color: var(--atoms-basf-gray-825);
    right: 0px;
    top: 40px;
    box-shadow: 0 0 5px 0 var(--atoms-basf-gray-600);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 100;

    &__item {
      background-color: white;
      cursor: pointer;
      width: 150px;
      padding: 5px 10px;
      transition: all 0.2s;
      position: relative;

      &:not(:last-child) {
        &:before {
          content: '';
          position: absolute;
          height: 1px;
          background-color: $gray-light;
          width: 100%;
          bottom: 0;
          left: 0;
        }
      }

      &:hover {
        background-color: var(--atoms-basf-gray-175);
      }

      &--selected {
        background-color: var(--atoms-basf-gray-825) !important;
        color: white;
      }
    }
  }
}
</style>
