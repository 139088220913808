/* eslint-disable  @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia';
import {
  ParamsOptions,
  EmollientJockeyItem,
  EmollientJockeyFilter,
  EmollientJockeyField,
  ItemLabel,
  SelectItem,
} from '@/api/types';
import {
  fetchEmollientJockeyBenchmarks,
  fetchEmollientJockeyProducts,
} from '@/api';
import config from '@/components/emollientJockey/config.json';
import { useI18n } from '@/composables/useI18n';
import { PhysicoChemicalProps, SensorialProps } from '@/typings/enums';

const { emollientJockeyFields, emollientRadarSelectedOptions } = config;

interface ItemProps {
  text: string;
  order: number;
}

const physicoChemProps = {
  [PhysicoChemicalProps.INTERFACIAL_TENSION]: {
    text: 'ift',
    order: 0,
  },
  [PhysicoChemicalProps.REFRACTIVE_INDEX]: {
    text: 'ri',
    order: 1,
  },
  [PhysicoChemicalProps.DENSITY]: {
    text: 'density',
    order: 2,
  },
  [PhysicoChemicalProps.ACN]: {
    text: 'acn',
    order: 3,
  },
  [PhysicoChemicalProps.SPREADABILITY]: {
    text: 'spreadabilitybr',
    order: 4,
  },
  [PhysicoChemicalProps.VISCOSITY]: {
    text: 'viscosity',
    order: 5,
  },
  [PhysicoChemicalProps.SURFACE_TENSION]: {
    text: 'sft',
    order: 6,
  },
};

const sensorialProps = {
  [SensorialProps.WAXINESS]: {
    text: 'waxiness',
    order: 0,
  },
  [SensorialProps.STICKINESS]: {
    text: 'stickiness',
    order: 1,
  },
  [SensorialProps.GLIDING]: {
    text: 'gliding',
    order: 2,
  },
  [SensorialProps.DISTRIBUTION]: {
    text: 'distribution',
    order: 3,
  },
  [SensorialProps.CARED_FOR_FEEL]: {
    text: 'cared_for_feel_3min',
    order: 4,
  },
  [SensorialProps.LIGHT_SKIN_FEEL]: {
    text: 'light_feel_skin_3min',
    order: 5,
  },
  [SensorialProps.POWDERY_3MIN]: {
    text: 'powdery_3min',
    order: 6,
  },
  [SensorialProps.WAXINESS_3MIN]: {
    text: 'waxiness_3min',
    order: 7,
  },
  [SensorialProps.STICKINESS_3MIN]: {
    text: 'stickiness_3min',
    order: 8,
  },
  [SensorialProps.ABSORPTOP_3MIN]: {
    text: 'absorption_3min',
    order: 9,
  },
  [SensorialProps.ABSORPTION_1MIN]: {
    text: 'absorption_1min',
    order: 10,
  },
  [SensorialProps.ACCEPTANCE]: {
    text: 'acceptance',
    order: 11,
  },
};

const rangeFilters = [
  'averageMolecularWeight',
  PhysicoChemicalProps.ACN,
  PhysicoChemicalProps.INTERFACIAL_TENSION,
  PhysicoChemicalProps.REFRACTIVE_INDEX,
  PhysicoChemicalProps.SPREADABILITY,
  PhysicoChemicalProps.SURFACE_TENSION,
  PhysicoChemicalProps.VISCOSITY,
  PhysicoChemicalProps.DENSITY,
];
const wheelFilters = [
  SensorialProps.DISTRIBUTION,
  SensorialProps.GLIDING,
  SensorialProps.STICKINESS,
  SensorialProps.WAXINESS,
  SensorialProps.ACCEPTANCE,
  SensorialProps.ABSORPTION_1MIN,
  SensorialProps.ABSORPTOP_3MIN,
  SensorialProps.STICKINESS_3MIN,
  SensorialProps.WAXINESS_3MIN,
  SensorialProps.POWDERY_3MIN,
  SensorialProps.LIGHT_SKIN_FEEL,
  SensorialProps.CARED_FOR_FEEL,
];

interface State {
  sortOrder: string;
  sortBy: string;
  products: EmollientJockeyItem[];
  filteredProducts: EmollientJockeyItem[];
  benchmarks: EmollientJockeyItem[];
  fields: EmollientJockeyField[];
  filters: EmollientJockeyFilter[];
  selectedFilter: EmollientJockeyFilter[];
  selectedMatrixXAxis: ItemLabel | null;
  selectedMatrixYAxis: ItemLabel | null;
  selectedRadarOptions: SelectItem[];
  showFilter: boolean;
  showFilteredProducts: boolean;
  physicoChemProps: Record<string, ItemProps>;
  sensorialProps: Record<string, ItemProps>;
  jockeyPref: any | null;
}

const { $tc } = useI18n();

export const useEmollientJockeyStore = defineStore('emollientJockey', {
  state: (): State => ({
    sortOrder: 'asc',
    sortBy: 'title',
    products: [],
    filteredProducts: [],
    benchmarks: [],
    fields: [],
    filters: [],
    selectedFilter: [],
    selectedMatrixXAxis: null,
    selectedMatrixYAxis: null,
    selectedRadarOptions: [],
    showFilter: false,
    showFilteredProducts: false,
    physicoChemProps,
    sensorialProps,
    jockeyPref: localStorage.getItem('JockeyPref')
      ? JSON.parse(localStorage.getItem('JockeyPref') as string)
      : null,
  }),

  getters: {
    benchmarksIds(state) {
      return state.benchmarks.map((benchmark) => benchmark.businessId);
    },

    phycoChemPropsKeys(state) {
      return Object.keys(state.physicoChemProps);
    },

    sensorialPropskeys(state) {
      return Object.keys(state.sensorialProps);
    },

    visibleOptionskeys(state) {
      return state.selectedRadarOptions
        .filter((prop) => prop.selected)
        .map((prop) => prop.id);
    },

    visibleSensorialkeys(): string[] {
      return this.sensorialPropskeys.filter((prop: string) =>
        this.visibleOptionskeys.includes(prop)
      );
    },

    visiblePhysicoChemKeys(): string[] {
      return this.phycoChemPropsKeys.filter((prop: string) =>
        this.visibleOptionskeys.includes(prop)
      );
    },

    visiblePhysicoChemLabels(state): string[] {
      return this.visiblePhysicoChemKeys.map(
        (prop: string) => state.physicoChemProps[prop].text
      );
    },

    visibleSensorialLabels(state): string[] {
      return this.visibleSensorialkeys.map(
        (prop: string) => state.sensorialProps[prop].text
      );
    },
  },

  actions: {
    setFilters(filters: EmollientJockeyFilter[]) {
      this.filters = filters || null;
    },

    setBenchmarks(benchmarks: EmollientJockeyItem[]) {
      this.benchmarks = benchmarks || [];
    },

    setProducts(products: EmollientJockeyItem[]) {
      this.products = products || [];
    },

    setSelectedRadarOptions(selectedRadarOptions: SelectItem[]) {
      this.selectedRadarOptions = selectedRadarOptions;
    },

    setSelectedMatrixXAxis(xAxis: ItemLabel) {
      this.selectedMatrixXAxis = xAxis;
    },

    setFilteredProducts(filteredProducts: EmollientJockeyItem[]) {
      this.filteredProducts = filteredProducts || [];
    },

    setFields(fields: EmollientJockeyField[]) {
      this.fields = fields || [];
    },

    setSelectedMatrixYAxis(yAxis: ItemLabel) {
      this.selectedMatrixYAxis = yAxis;
    },

    setShowFilteredProducts(showFilteredProducts: boolean) {
      this.showFilteredProducts = showFilteredProducts;
    },

    setShowFilter(showFilter: boolean) {
      this.showFilter = showFilter;
    },

    toggleShowFilter() {
      this.showFilter = !this.showFilter;
    },

    saveFilters(filters: EmollientJockeyFilter[]) {
      this.setPreferences({ filters });
      this.setFilters(filters);
    },

    setPreferences(params: any) {
      const savedPref = localStorage.getItem('JockeyPref');

      const savedPrefParsed = savedPref ? JSON.parse(savedPref) : {};
      const newValues = {
        ...savedPrefParsed,
        ...params,
      };

      localStorage.setItem('JockeyPref', JSON.stringify(newValues));
      this.jockeyPref = newValues;
    },

    async loadEmollientData() {
      await this.getEmollientJockeyProducts();
    },

    async getEmollientJockeyBenchmarks() {
      const reqParams: ParamsOptions = {};
      const response = await fetchEmollientJockeyBenchmarks(reqParams);
      const items =
        (response &&
          response.benchmarks &&
          response.benchmarks
            .slice()
            .sort((a, b) => (a.title > b.title ? 1 : -1))) ||
        [];
      this.setBenchmarks(items);
    },

    async getEmollientJockeyProducts() {
      const reqParams: ParamsOptions = {};
      const response = await fetchEmollientJockeyProducts(reqParams);
      const items =
        (response &&
          response.products &&
          response.products
            .slice()
            .sort((a, b) => (a.title > b.title ? 1 : -1))) ||
        [];

      const checkActiveFormLocalStorage = this.jockeyPref?.listProducts;
      items.forEach((item: EmollientJockeyItem) => {
        let active = false;
        if (checkActiveFormLocalStorage) {
          const savedProduct = this.jockeyPref?.listProducts.find(
            (product: EmollientJockeyItem) =>
              product.businessId === item.businessId
          );
          active = savedProduct ? savedProduct.active : false;
        }
        item.active = active;
      });
      this.setProducts(items);

      const myFields: EmollientJockeyField[] = emollientJockeyFields;
      myFields.forEach((item: EmollientJockeyField) => {
        item.label = $tc(item.i18n);
      });
      this.setFields(myFields);

      const spreadability = myFields.find(
        (f: any) => f.value === 'spreadability'
      );
      if (spreadability) {
        this.setSelectedMatrixXAxis({
          value: spreadability.value,
          label: spreadability.label,
        });
      }

      const interfacialTension = myFields.find(
        (f: any) => f.value === 'interfacialTension'
      );
      if (interfacialTension) {
        this.setSelectedMatrixYAxis({
          value: interfacialTension.value,
          label: interfacialTension.label,
        });
      }

      const options: SelectItem[] = emollientRadarSelectedOptions;
      options.forEach((o) => {
        const tField = myFields.find((f: any) => f.value === o.id);
        if (tField) {
          o.name = tField.label;
        }
      });
      this.setSelectedRadarOptions(options);

      const myFilters: EmollientJockeyFilter[] = [];
      this.fields.forEach((item: EmollientJockeyField) => {
        if (item.fieldType !== 'COMMON') {
          myFilters.push({
            value: item.value,
            label: item.label,
            fieldType: item.fieldType,
            unit: item.unit,
            step: item.step,
            width: item.width,
            minValue: 0,
            maxValue: 1,
            range: [0, 1],
            rangeMinMax: [0, 1],
            active: true,
          });
        }
      });

      myFilters.forEach((filter) => {
        let savedFilterRange = null;
        let savedFilterActive = true;
        if (this.jockeyPref?.filters) {
          const savedFilter = this.jockeyPref?.filters.find(
            (savedFilter: EmollientJockeyFilter) =>
              savedFilter.value === filter.value
          );
          savedFilterRange = savedFilter ? savedFilter.range : null;
          savedFilterActive = savedFilter ? savedFilter.active : true;
        }

        if (rangeFilters.includes(filter.value)) {
          const filterRange: number[] = this.products
            .filter((val: any) => !!val[filter.value].raw)
            .map((val: any) => Number(val[filter.value].raw));

          filter.minValue = Math.min.apply(null, filterRange);
          filter.maxValue = Math.max.apply(null, filterRange);
          filter.range = savedFilterRange || [filter.minValue, filter.maxValue];
          filter.rangeMinMax = [filter.minValue, filter.maxValue];
          filter.active = savedFilterActive;
        } else {
          filter.minValue = 0;
          filter.maxValue = 4;
          filter.range = savedFilterRange || [filter.minValue, filter.maxValue];
          filter.rangeMinMax = [filter.minValue, filter.maxValue];
          filter.active = savedFilterActive;
        }
      });

      this.doFilterProducts(myFilters);
    },

    doFilterProducts(filters: EmollientJockeyFilter[]) {
      const filteredProducts: EmollientJockeyItem[] = this.products.filter(
        (product: EmollientJockeyItem) => {
          let matched = true;
          const mapSensoric: Record<number, string> = {
            1: 'LOW',
            2: 'MEDIUM',
            3: 'HIGH',
            4: 'VERY HIGH',
          };

          filters.forEach((filter: EmollientJockeyFilter) => {
            if (filter.active) {
              if (
                rangeFilters.includes(filter.value) &&
                product[filter.value].raw
              ) {
                product[filter.value].raw < filter.range[0] ||
                product[filter.value].raw > filter.range[1]
                  ? (matched = false)
                  : void 0;
              } else if (
                wheelFilters.includes(filter.value as SensorialProps) &&
                filter.range[0] !== 0
              ) {
                !product[filter.value].classified ||
                product[filter.value].classified !==
                  mapSensoric[filter.range[0]]
                  ? (matched = false)
                  : null;
              }
            }
          });
          return matched;
        }
      );

      this.setFilteredProducts(filteredProducts);
      this.saveFilters(filters);
    },
  },
});
