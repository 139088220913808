import api from '@/plugins/axiosApi';
import {
  CompositionsFormulationsResponse,
  CompositionsFormulationsAdvancedSearchReq,
  CustomFormulationDetail,
  CustomFormulationPayload,
  CompositionsIngredientsAdvancedSearchReq,
  CompositionsIngredientsResponse,
  CustomIngredientPayload,
  CustomIngredientResponse,
  IngredientPayload,
  IngredientFormulationDetail,
  BiodegradabilityInformationResponse,
  IngredientPayloadMultiple,
  GenericIngredientSustainability,
} from '@/typings/compositions';

export const fetchCustomFormulationsAdvanceSearch = async (
  params: CompositionsFormulationsAdvancedSearchReq
): Promise<CompositionsFormulationsResponse> => {
  const options = params.params;
  const _order = options.find((item) => item.name === '_order');

  const jsonData = {
    lang: options.filter(function (item) {
      return item.name === 'lang';
    })[0].value,
    _limit: options.filter(function (item) {
      return item.name === '_limit';
    })[0].value,
    _page: options.filter(function (item) {
      return item.name === '_page';
    })[0].value,
    _sort: options.filter(function (item) {
      return item.name === '_sort';
    })[0].value,
    _order: _order ? _order.value : 'asc',

    categories: params.categoryIds,
    subCategories: params.subCategoryIds,
    formats: params.formatIds,
    q: params.q || '*',
  };

  const { data } = await api.post(
    '/api/cleanbeauty/customFormulation/search',
    jsonData
  );
  return data as CompositionsFormulationsResponse;
};

export const createCustomFormulation = async (
  params: CustomFormulationPayload
) => {
  const { data } = await api.post('/api/cleanbeauty/customFormulation', params);

  return data;
};

export const fetchCustomFormulation = async (
  id: string
): Promise<CustomFormulationDetail> => {
  const config = { params: { 'no-cache': true } };
  const { data } = await api.get(
    `/api/cleanbeauty/customFormulation/${id}`,
    config
  );

  return data as CustomFormulationDetail;
};

export const updateCustomFormulation = async (
  id: string,
  params: Partial<CustomFormulationPayload>
): Promise<CustomFormulationDetail> => {
  const { data } = await api.put(
    `/api/cleanbeauty/customFormulation/${id}`,
    params
  );

  return data;
};

export const deleteCustomFormulation = async (id: string): Promise<void> => {
  return await api.delete(`/api/cleanbeauty/customFormulation/${id}`);
};

/* Ingredients */
export const createCustomIngredient = async (
  params: CustomIngredientPayload
) => {
  const { data } = await api.post('/api/cleanbeauty/customIngredient', params);

  return data;
};

export const updateCustomIngredient = async (
  id: string,
  params: CustomIngredientPayload
): Promise<CustomIngredientResponse> => {
  const { data } = await api.put(
    `/api/cleanbeauty/customIngredient/${id}`,
    params
  );

  return data as CustomIngredientResponse;
};

export const fetchCustomIngredient = async (
  id: string
): Promise<CustomIngredientResponse> => {
  const config = { params: { 'no-cache': true } };
  const { data } = await api.get(
    `/api/cleanbeauty/customIngredient/${id}`,
    config
  );
  return data as CustomIngredientResponse;
};

export const deleteCustomIngredient = async (id: string): Promise<void> => {
  return await api.delete(`/api/cleanbeauty/customIngredient/${id}`);
};

export const fetchCustomIngredientsAdvanceSearch = async (
  params: CompositionsIngredientsAdvancedSearchReq
): Promise<CompositionsIngredientsResponse> => {
  const options = params.params;
  const _order = options.find((item) => item.name === '_order');

  const jsonData = {
    lang: options.filter(function (item) {
      return item.name === 'lang';
    })[0].value,
    _limit: options.filter(function (item) {
      return item.name === '_limit';
    })[0].value,
    _page: options.filter(function (item) {
      return item.name === '_page';
    })[0].value,
    _sort: options.filter(function (item) {
      return item.name === '_sort';
    })[0].value,
    _order: _order ? _order.value : 'asc',

    productGroups: params.productGroupsIds,
    functions: params.functionsIds,

    q: params.q || '*',
  };

  const { data } = await api.post(
    '/api/cleanbeauty/customIngredient/search',
    jsonData
  );
  return data as CompositionsIngredientsResponse;
};

export const addIngredientToFormulation = async (
  formulationId: string,
  params: IngredientPayload
) => {
  const { data } = await api.post(
    `/api/cleanbeauty/customFormulation/${formulationId}/ingredient`,
    params
  );

  return data;
};

export const fetchIngredientsFormulation = async (
  formulationId: string
): Promise<IngredientFormulationDetail[]> => {
  const config = { params: { 'no-cache': true } };
  const { data } = await api.get(
    `/api/cleanbeauty/customFormulation/${formulationId}/ingredient`,
    config
  );

  return data as IngredientFormulationDetail[];
};

export const deleteIngredientFromFormulation = async (
  formulationId: string,
  ingredientId: string
): Promise<void> => {
  return await api.delete(
    `/api/cleanbeauty/customFormulation/${formulationId}/ingredient/${ingredientId}`
  );
};

export const updateIngredientConcentration = async (
  formulationId: string,
  params: IngredientPayloadMultiple
): Promise<void> => {
  return await api.put(
    `/api/cleanbeauty/customFormulation/${formulationId}/ingredient`,
    params
  );
};

export const fetchCompositionSustainabilityPercentage = async (
  formulationId: string
): Promise<BiodegradabilityInformationResponse> => {
  const { data } = await api.get(
    `/api/cleanbeauty/composition/sustainability/${formulationId}`
  );

  return data as BiodegradabilityInformationResponse;
};

export const fetchFormulationSustainabilityPercentage = async (
  formulationId: string
): Promise<BiodegradabilityInformationResponse> => {
  const config = { params: { 'no-cache': true } };
  const { data } = await api.get(
    `/api/cleanbeauty/customFormulation/sustainability/${formulationId}`,
    config
  );

  return data as BiodegradabilityInformationResponse;
};

export const fetchFormulationRCCPercentage = async (
  formulationId: string
): Promise<Record<string, number>> => {
  const config = { params: { 'no-cache': true } };
  const { data } = await api.get(
    `/api/cleanbeauty/rcc/custom-formulation?id=${encodeURIComponent(
      formulationId
    )}`,
    config
  );

  // TODO: check type
  return data as Record<string, number>;
};

export const fetchTradeNameSustainabilityInformation = async (
  sustainabilityIdsListString: string
) => {
  const ids = sustainabilityIdsListString
    .split(':')
    .map((id) => `id=${encodeURIComponent(id)}`)
    .join('&');
  const {
    data: { content },
  } = await api({
    url: `/api/cleanbeauty/genericIngredient/trade-name?${ids}`,
    method: 'get',
  });

  return content as GenericIngredientSustainability[];
};
