import { defineStore } from 'pinia';
import { ParamsOptions, BrandDetailReq, BrandDetail } from '@/api/types';
import { fetchBrandDetail } from '@/api';

interface State {
  brandDetail: BrandDetail | null;
}

export const useBrandDetailStore = defineStore('brandDetail', {
  state: (): State => ({
    brandDetail: null,
  }),

  actions: {
    async getBrandDetail(reqObj: BrandDetailReq) {
      const reqParams: ParamsOptions = {};
      reqObj.params.map((arr) => {
        reqParams[arr.name] = arr.value;
      });

      const responseData = await fetchBrandDetail(reqObj.brandId, reqParams);
      if (responseData) {
        this.brandDetail = responseData;
      }
    },

    resetBrandDetail() {
      this.brandDetail = null;
    },
  },
});
