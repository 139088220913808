var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_setup.showButton)?_c('div',{on:{"click":_setup.openDialog}},[_vm._t("button")],2):[_vm._t("default")],_c('v-dialog',{attrs:{"value":_vm.modelValue,"max-width":_vm.maxWidth,"fullscreen":_setup.isLowerThanSM,"content-class":_vm.contentClass,"persistent":_vm.shouldPersist},on:{"click:outside":_setup.closeDialog,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _setup.escapeDown.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',{class:[
          'card-title',
          {
            'themed-bg': _vm.themedTitle,
            'white--text': _vm.themedTitle,
          },
        ]},[_vm._t("title",function(){return [_c('span',[_vm._v(_vm._s(_vm.title))])]}),_vm._t("close",function(){return [_c('v-btn',{attrs:{"text":"","plain":"","ripple":false},on:{"click":_setup.closeDialog}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)]})],2),_c('v-divider'),_c('v-card-text',{class:['card-text', { 'formatted-text': _vm.hasFormattedText }]},[_vm._t("default")],2),_c('v-divider'),_c('v-card-actions',{class:[
          'card-actions',
          { 'card-actions--vertical': _vm.verticalActions },
        ]},[_vm._t("actions")],2)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }