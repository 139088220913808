import { SelectItem } from '@/api/types';

export interface AnnualLaunches {
  data: AnnualLaunchesDatum[];
}

export interface AnnualLaunchesDatum {
  dataX: SelectItem | number;
  dataY: number;
}

export const enum BrandAnalyticsTabs {
  BRAND_LIST = 'brandList',
  CONSUMER_TYPE = 'consumerType',
  LIFESTYLE = 'lifestyle',
}
