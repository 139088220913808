/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia';
import {
  Product,
  ConsumerProductDetailReq,
  ParamsOptions,
  ConsumerProductAdvancedSearchReq,
  ConsumerProductAdvancedSearchPref,
  TopClaimsProductsDatum,
  TopIngredientsProductsDatum,
} from '@/api/types';
import {
  fetchProductsAdvanced,
  fetchProductDetailsSearch,
  fetchTopClaimsProducts,
  fetchTopIngredientsProducts,
} from '@/api';
import { getItemFromStorage, setItemStorage } from '@/lib/utils';
import { ProductSection } from '@/typings/enums';

const FiltersExcludedSection = {
  [ProductSection.LIST]: ['functionSelection'],
  [ProductSection.TOP_CLAIMS]: [
    'functionSelection',
    'dateSelection',
    'claimsSelection',
  ],
  [ProductSection.TOP_INGREDIENTS]: [],
};

const emtpySearchParams = {
  params: [],
  categorySelection: [],
  subCategorySelection: [],
  formatsSelection: [],
  marketSelection: [],
  dateSelection: [],
  paSelection: [],
  spfSelection: [],
  claimsSelection: [],
  mainBrandsSelection: [],
  brandsSelection: [],
  functionSelection: [],
  minPriceRange: '',
  maxPriceRange: '',
  ingredientsIncludeSelection: [],
  ingredientsExcludeSelection: [],
};

const savedSearchParams = getItemFromStorage('ConsumerProductsAdvancedPref');

interface State {
  consumerProducts: Product[];
  topClaims: TopClaimsProductsDatum[];
  topIngredients: TopIngredientsProductsDatum[];
  consumerProductsSearch: string | null;
  consumerProductsSort: string | null;
  consumerProductsPage: number | null;
  showAdvancedFilter: boolean;
  showAdvancedActiveFilter: boolean;
  consumerProductsAdvancedPref: ConsumerProductAdvancedSearchPref;
  backupConsumerProductsAdvancedPref: ConsumerProductAdvancedSearchPref;
  noOfResults: number | null;
  openedFilters: string[];
  fetchedFiltersData: boolean;
}

export const useProductListStore = defineStore('productList', {
  state: (): State => ({
    consumerProducts: [],
    topClaims: [],
    topIngredients: [],
    consumerProductsSearch: '',
    consumerProductsSort: '',
    consumerProductsPage: 0,
    showAdvancedFilter: false,
    showAdvancedActiveFilter: false,
    consumerProductsAdvancedPref: {
      ...emtpySearchParams,
      ...(savedSearchParams || {}),
    },
    backupConsumerProductsAdvancedPref: {
      ...emtpySearchParams,
      ...(savedSearchParams || {}),
    },
    noOfResults: null,
    openedFilters: [],
    fetchedFiltersData: false,
  }),

  getters: {
    appliedFilters: (state) => (section: any) => {
      return Object.entries(state.backupConsumerProductsAdvancedPref)
        .filter(([key, val]) => key !== 'q' && key !== 'params' && val !== '')
        .reduce((acc, [key, val]) => {
          if (FiltersExcludedSection[section].includes(key)) {
            return acc;
          }

          if (key === 'maxPriceRange') {
            const minPrice = acc.pop();
            acc.push(`${minPrice}-${val}`);
          } else {
            acc.push(val);
          }
          return acc;
        }, [])
        .flat()
        .sort();
    },

    newFilters: (state) => (section: any) => {
      return Object.entries(state.consumerProductsAdvancedPref)
        .filter(([key, val]) => key !== 'q' && key !== 'params' && val !== '')
        .reduce((acc, [key, val]) => {
          if (FiltersExcludedSection[section].includes(key)) {
            return acc;
          }

          if (key === 'maxPriceRange') {
            const minPrice = acc.pop();
            acc.push(`${minPrice}-${val}`);
          } else {
            acc.push(val);
          }
          return acc;
        }, [])
        .flat()
        .sort();
    },
  },

  actions: {
    setFetchedDataFilters(value: boolean) {
      this.fetchedFiltersData = value;
    },

    setOpenedFilters(filters: string[]) {
      this.openedFilters = filters;
    },

    searchConsumerProductDetails(reqObj: ConsumerProductDetailReq) {
      const reqParams: ParamsOptions = {};
      reqObj.params.map((arr) => {
        reqParams[arr.name] = arr.value;
      });
      return fetchProductDetailsSearch(reqObj.productId, reqParams);
    },

    async getConsumerProductsAdvanced(
      reqObj: ConsumerProductAdvancedSearchReq,
      updateStore = true
    ) {
      this.noOfResults = null;
      const responseData = await fetchProductsAdvanced(reqObj);
      if (responseData && updateStore) {
        this.updateProductsAndResults(
          responseData.consumerProducts,
          responseData.noOfResults
        );
      }

      return responseData;
    },

    async getTopClaims(reqObj: ConsumerProductAdvancedSearchReq) {
      const response = await fetchTopClaimsProducts(reqObj);
      this.topClaims = response.data;
      return response;
    },

    async getTopIngredients(reqObj: ConsumerProductAdvancedSearchReq) {
      const response = await fetchTopIngredientsProducts(reqObj);
      this.topIngredients = response.data;
      return response;
    },

    updateProductsAndResults(consumerProducts: Product[], noOfResults: number) {
      this.consumerProducts = consumerProducts;
      this.noOfResults = noOfResults;
    },

    setConsumerProductsSort(consumerProductsSort: string) {
      this.consumerProductsSort = consumerProductsSort;
    },

    setConsumerProductsPage(consumerProductsPage: number) {
      this.consumerProductsPage = consumerProductsPage;
    },

    setConsumerProductSearch(consumerProductsSearch: string) {
      this.consumerProductsSearch = consumerProductsSearch;
    },

    restoreFilters() {
      this.consumerProductsAdvancedPref = JSON.parse(
        JSON.stringify(this.backupConsumerProductsAdvancedPref)
      );
    },

    setConsumerProductsAdvancedPref(
      consumerProductsAdvancedPref: any,
      updateBackup = false
    ) {
      setItemStorage(
        'ConsumerProductsAdvancedPref',
        consumerProductsAdvancedPref
      );
      this.consumerProductsAdvancedPref = consumerProductsAdvancedPref;
      if (updateBackup) {
        this.backupConsumerProductsAdvancedPref = consumerProductsAdvancedPref;
      }
    },

    updateConsumerProductsAdvancedPref(filters: any) {
      this.consumerProductsAdvancedPref = {
        ...this.consumerProductsAdvancedPref,
        ...filters,
      };
      setItemStorage(
        'ConsumerProductsAdvancedPref',
        this.consumerProductsAdvancedPref
      );
    },

    // TODO: Future improvement
    /*
      One thing we could do is to store the app version in the LocalStorage
      and check it with the current app version. If there are differences,
      we could check *all the items* in the LocalStorage to verify that they
      still exist in the different lists (categories, subcategories, etc.)
    */
    verifyConsumerProductsAdvancedPrefCategories(categoryIds: string[]) {
      const verifiedCategories =
        this.consumerProductsAdvancedPref.categorySelection.filter((x) =>
          categoryIds.includes(x)
        );
      if (
        this.consumerProductsAdvancedPref.categorySelection.length !==
        verifiedCategories.length
      ) {
        this.consumerProductsAdvancedPref.categorySelection =
          verifiedCategories;
        // Remove subcategories
        this.consumerProductsAdvancedPref.subCategorySelection = [];
      }

      this.setConsumerProductsAdvancedPref(
        this.consumerProductsAdvancedPref,
        true
      );
    },
  },
});
