import api from '@/plugins/axiosApi';
import {
  Surfactant,
  SurfactantBechmark,
  SurfactantCompositionList,
  SurfactantExtended,
} from '@/typings/surfactants';

export const fetchSurfactants = async () => {
  const resObj = await api.post(
    '/api/surfactantnavigator/finder/compositions',
    { q: '*', _limit: 9999, _page: 1 }
  );
  return resObj.data.content as Surfactant[];
};

export const fetchBenchmarksExtended = async () => {
  const resObj = await api({
    url: '/api/surfactantnavigator/extended/benchmarks?_page=1&_limit=50',
  });
  return resObj.data.content as SurfactantBechmark[];
};

export const fetchSolutionsExtended = async (benchmarkBuid: string) => {
  const resObj = await api({
    url: `/api/surfactantnavigator/extended/solutions/${benchmarkBuid}?_page=1&_limit=50`,
  });
  return resObj.data.content as SurfactantBechmark[];
};

export const fetchCompositionsExtended = async (
  benchmarkBuid: string,
  solutionBuid: string
) => {
  const resObj = await api.post(
    '/api/surfactantnavigator/extended/spiderchart',
    {
      benchmarkBuid,
      solutionBuid,
      _page: 1,
      _limit: 50,
    }
  );
  return resObj.data as SurfactantExtended;
};

export const fetchBenchmarksOptimizer = async () => {
  const resObj = await api({
    url: '/api/surfactantnavigator/optimizer/benchmarks?_page=1&_limit=50',
  });
  return resObj.data.content as SurfactantBechmark[];
};

export const fetchSolutionsOptimizer = async (benchmarkBuid: string) => {
  const resObj = await api({
    url: `/api/surfactantnavigator/optimizer/compositions/${benchmarkBuid}?_page=1&_limit=50`,
  });
  return resObj.data.content as SurfactantBechmark[];
};

export const fetchCompositions = async (surfactantCompBusinessId: string) => {
  const resObj = await api.post(
    '/api/surfactantnavigator/optimizer/spiderchart',
    {
      surfactantCompBusinessId,
      _page: 1,
      _limit: 50,
    }
  );
  return resObj.data.content as SurfactantCompositionList[];
};
