/* eslint-disable  @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { useLoaderStore } from '@/stores/loader';
import { useAuthStore } from '@/stores/auth';
import router from '@/router';
import { useAppStore } from '@/stores/app';
import { SectionsIds } from '@/typings/enums';
import { useI18n } from '@/composables/useI18n';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore();
    const loaderStore = useLoaderStore();

    loaderStore.startLoading();
    const token = authStore.token;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    // Add lang to all requests if not present
    if (!config.params) {
      config.params = {};
    }
    if (!config.params.lang) {
      const { locale } = useI18n();
      config.params.lang = locale || 'en';
    }
    return config;
  },
  (error) => {
    const loaderStore = useLoaderStore();

    loaderStore.startLoading();
    Promise.reject(error);
  }
);

// Response interceptors
api.interceptors.response.use(
  (response) => {
    const loaderStore = useLoaderStore();

    loaderStore.finishLoading();
    if (response.status !== 200 && response.status !== 204) {
      return Promise.reject(
        new Error(response.statusText + '(' + response.status + ')' || 'Error')
      );
    } else {
      if ((router as any).history) {
        loaderStore.updatePath((router as any).history.current.fullPath);
      }
      return response;
    }
  },
  async (error) => {
    const loaderStore = useLoaderStore();

    if (error.response?.status === 503) {
      const appStore = useAppStore();
      appStore.setMaintenance(true);

      router.push({ name: SectionsIds.LANDING });
    } else if (
      error.response?.status === 401 ||
      error.response?.status === 403
    ) {
      router.push({ name: SectionsIds.ERROR_PAGE });
    }
    loaderStore.finishLoading();
    return Promise.reject(error);
  }
);

export default api;
