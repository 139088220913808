/* eslint-disable  @typescript-eslint/no-explicit-any */
import api from '@/plugins/axiosApi';

export const fetchAllSentiments = async (params: any) => {
  const resObj: any = await api({
    url: '/api/masterdata/references/sentiment',
    method: 'get',
    params,
  });
  if (resObj) {
    return resObj;
  }
};
