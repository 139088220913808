/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia';
import {
  BasfProduct,
  ParamsOptions,
  BasfProductAdvancedSearchPref,
  BasfProductAdvancedSearchReq,
  BasfProductsReq,
  Category,
  SelectItem,
} from '@/api/types';
import {
  fetchBasfProductsAdvanceSearch,
  fetchBasfProductMintelCategories,
  fetchBasfProductsSearch,
  fetchAllProductGroups,
  fetchAllApplications,
  fetchAllCertificateTypes,
  fetchAllActiveSubstanceTechnologies,
  fetchAllRelatedBasfProductLabels,
  fetchAllConsumerBenefits,
  fetchMasterdataHierarchicalreferences,
  fetchAllFunctionsBasfProducts,
} from '@/api';
import {
  ReadilyBiodegradableId,
  euEcoLabelLeaveOnSectionId,
  euEcoLabelRinseOffSectionId,
  excludedNotApprovedLabels,
  nordicSwanLeaveOnSectionId,
  nordicSwanRinseOffSectionId,
  sustainabilityBiodegradabilitySectionId,
  sustainableSourcingStandardsSectionId,
} from '@/constants/compositions';

interface State {
  basfProducts: BasfProduct[];
  basfProductsSearch: string | null;
  basfProductsSort: string | null;
  basfProductsPage: number | null;
  showAdvancedFilter: boolean;
  showAdvancedActiveFilter: boolean;
  basfProductCategories: Category[];
  productGroups: SelectItem[];
  applications: SelectItem[];
  ethicsLabels: SelectItem[];
  activeSubstanceTechnologies: SelectItem[];
  relatedBasfProductLabels: SelectItem[];
  euEcoLabelLeaveOnLabels: SelectItem[];
  euEcoLabelRinseOffLabels: SelectItem[];
  nordicSwanLeaveOnLabels: SelectItem[];
  nordicSwanRinseOffLabels: SelectItem[];
  naturalityLabels: SelectItem[];
  sustainableSourcingStandardsLabels: SelectItem[];
  sustainabilityBiodegradabilityLabels: SelectItem[];
  functions: SelectItem[];
  consumerBenefits: SelectItem[];
  noOfResults: number | null;
  openedFilters: string[];
  fetchedFiltersData: boolean;
  basfProductsAdvancedPref: BasfProductAdvancedSearchPref;
  backupBasfProductsAdvancedPref: BasfProductAdvancedSearchPref;
}

export const useBasfProductList = defineStore('basfProductList', {
  state: (): State => ({
    basfProducts: [],
    basfProductsSearch: null,
    basfProductsSort: null,
    basfProductsPage: null,
    showAdvancedFilter: false,
    showAdvancedActiveFilter: false,
    basfProductCategories: [],
    productGroups: [],
    applications: [],
    ethicsLabels: [],
    activeSubstanceTechnologies: [],
    relatedBasfProductLabels: [],
    euEcoLabelLeaveOnLabels: [],
    euEcoLabelRinseOffLabels: [],
    naturalityLabels: [],
    sustainableSourcingStandardsLabels: [],
    sustainabilityBiodegradabilityLabels: [],
    nordicSwanLeaveOnLabels: [],
    nordicSwanRinseOffLabels: [],
    functions: [],
    consumerBenefits: [],
    noOfResults: null,
    openedFilters: [],
    fetchedFiltersData: false,
    basfProductsAdvancedPref: localStorage.getItem('BasfProductsAdvancedPref')
      ? JSON.parse(localStorage.getItem('BasfProductsAdvancedPref')!)
      : {
          params: [],
          categorySelection: [],
          applicationsSelection: [],
          productGroupsSelection: [],
          activeSubstanceTechnologiesSelection: [],
          functionSelection: [],
          ethicsLabelsSelection: [],
          retailLabelsSelection: [],
          ecoLabelsSelection: [],
          naturalityLabelsSelection: [],
          sustainableSourcingStandardsLabelsSelection: [],
          sustainabilityBiodegradabilityLabelsSelection: [],
          relatedBasfProductLabelsSelection: [],
          comparabilityLabelSelection: [],
          consumerBenefitSelection: [],
          ingredientsIncludeSelection: [],
          ingredientsExcludeSelection: [],
          rcc: [0, 100],
        },
    backupBasfProductsAdvancedPref: localStorage.getItem(
      'BasfProductsAdvancedPref'
    )
      ? JSON.parse(localStorage.getItem('BasfProductsAdvancedPref')!)
      : {
          params: [],
          categorySelection: [],
          applicationsSelection: [],
          productGroupsSelection: [],
          activeSubstanceTechnologiesSelection: [],
          functionSelection: [],
          ethicsLabels: [],
          retailLabels: [],
          ecoLabelsSelection: [],
          naturalityLabelsSelection: [],
          sustainableSourcingStandardsLabelsSelection: [],
          sustainabilityBiodegradabilityLabelsSelection: [],
          relatedBasfProductLabelsSelection: [],
          comparabilityLabelSelection: [],
          consumerBenefitSelection: [],
          ingredientsIncludeSelection: [],
          ingredientsExcludeSelection: [],
          rcc: [0, 100],
        },
  }),

  getters: {
    appliedFilters: (state) => () => {
      return Object.entries(state.backupBasfProductsAdvancedPref)
        .filter(([key]) => key !== 'q' && key !== 'params')
        .reduce((acc, [key, val]) => {
          // Ignore default values
          if (
            (key === 'rcc' && val[0] === 0 && val[1] === 100) ||
            (key === 'biodegradabilitySelection' && !val)
          ) {
            return acc;
          } else if (key === 'rcc') {
            acc.push(`${val[0]}-${val[1]}`);
          } else {
            acc.push(val);
          }

          return acc;
        }, [])
        .flat()
        .sort();
    },

    newFilters: (state) => () => {
      return Object.entries(state.basfProductsAdvancedPref)
        .filter(([key]) => key !== 'q' && key !== 'params')
        .reduce((acc, [key, val]) => {
          // Ignore default values
          if (
            (key === 'rcc' && val[0] === 0 && val[1] === 100) ||
            (key === 'biodegradabilitySelection' && !val)
          ) {
            return acc;
          } else if (key === 'rcc') {
            acc.push(`${val[0]}-${val[1]}`);
          } else {
            acc.push(val);
          }

          return acc;
        }, [])
        .flat()
        .sort();
    },
  },

  actions: {
    resetNoOfResults() {
      this.noOfResults = null;
    },

    setFetchedDataFilters(value: boolean) {
      this.fetchedFiltersData = value;
    },

    setOpenedFilters(filters: string[]) {
      this.openedFilters = filters;
    },

    setBasfProductsPage(basfProductsPage: number) {
      this.basfProductsPage = basfProductsPage;
    },

    setBasfProductsSort(basfProductsSort: string) {
      this.basfProductsSort = basfProductsSort;
    },

    setBasfProductSearch(basfProductsSearch: string) {
      this.basfProductsSearch = basfProductsSearch;
    },

    restoreFilters() {
      this.basfProductsAdvancedPref = JSON.parse(
        JSON.stringify(this.backupBasfProductsAdvancedPref)
      );
    },

    setBasfProductsAdvancedPref(
      basfProductsAdvancedPref: any,
      updateBackup = false
    ) {
      localStorage.setItem(
        'BasfProductsAdvancedPref',
        JSON.stringify(basfProductsAdvancedPref)
      );
      this.basfProductsAdvancedPref = basfProductsAdvancedPref;

      if (updateBackup) {
        this.backupBasfProductsAdvancedPref = basfProductsAdvancedPref;
      }
    },

    async searchBasfProducts(reqObj: BasfProductsReq) {
      const reqParams: ParamsOptions = {};
      reqObj.params.map((arr) => {
        reqParams[arr.name] = arr.value;
      });
      return fetchBasfProductsSearch(reqParams);
    },

    async getBasfProductsAdvanced(reqObj: BasfProductAdvancedSearchReq) {
      this.resetNoOfResults();
      const responseData = await fetchBasfProductsAdvanceSearch(reqObj);
      if (responseData) {
        this.basfProducts = responseData.basfProducts;
        this.noOfResults = responseData.noOfResults;

        return responseData.basfProducts;
      }
    },

    async getBasfProductMintelCategories(params: any) {
      const reqParams: ParamsOptions = {};
      params.forEach((arr: any) => {
        reqParams[arr.name] = arr.value;
      });
      const resObj: any = await fetchBasfProductMintelCategories(reqParams);
      if (resObj) {
        const filteredObj: any = [];
        for (const val in resObj.data) {
          filteredObj.push(resObj.data[val]);
        }
        this.basfProductCategories = (filteredObj as Category[]) || [];
      }
    },

    async fetchAllProductGroups(reqObj: any) {
      const reqParams: ParamsOptions = {};
      reqObj.map((arr: any) => {
        reqParams[arr.name] = arr.value;
      });
      const resObj: any = await fetchAllProductGroups(reqParams);
      if (resObj) {
        this.productGroups = (resObj.data as SelectItem[]) || [];
      }
    },

    async fetchAllApplications(reqObj: any) {
      const reqParams: ParamsOptions = {};
      reqObj.map((arr: any) => {
        reqParams[arr.name] = arr.value;
      });
      const resObj: any = await fetchAllApplications(reqParams);
      if (resObj) {
        this.applications = (resObj.data as SelectItem[]) || [];
      }
    },

    async fetchAllCertificateTypes(reqObj: any) {
      const reqParams: ParamsOptions = {};
      reqObj.map((arr: any) => {
        reqParams[arr.name] = arr.value;
      });
      const resObj: any = await fetchAllCertificateTypes(reqParams);
      if (resObj) {
        this.ethicsLabels = (resObj.data as SelectItem[]) || [];
      }
    },

    async fetchAllActiveSubstanceTechnologies(reqObj: any) {
      const reqParams: ParamsOptions = {};
      reqObj.map((arr: any) => {
        reqParams[arr.name] = arr.value;
      });
      const resObj: any = await fetchAllActiveSubstanceTechnologies(reqParams);
      if (resObj) {
        this.activeSubstanceTechnologies = (resObj.data as SelectItem[]) || [];
      }
    },

    async fetchAllRelatedBasfProductLabels(reqObj: any) {
      const reqParams: ParamsOptions = {};
      reqObj.map((arr: any) => {
        reqParams[arr.name] = arr.value;
      });
      const resObj: any = await fetchAllRelatedBasfProductLabels(reqParams);
      if (resObj) {
        this.relatedBasfProductLabels = (resObj.data as SelectItem[]) || [];
      }
    },

    async fetchAllFunctions(reqObj: any) {
      const reqParams: ParamsOptions = {};
      reqObj.map((arr: any) => {
        reqParams[arr.name] = arr.value;
      });
      const resObj: any = await fetchAllFunctionsBasfProducts(reqParams);
      if (resObj) {
        this.functions = (resObj.data as SelectItem[]) || [];
      }
    },

    async getAllConsumerBenefits(reqObj: any) {
      const reqParams: ParamsOptions = {};
      reqObj.map((arr: any) => {
        reqParams[arr.name] = arr.value;
      });
      const resObj: any = await fetchAllConsumerBenefits(reqParams);
      if (resObj) {
        this.consumerBenefits = (resObj.data as SelectItem[]) || [];
      }
    },

    async fetchAllSustainabilityEcoLabels(reqObj: any) {
      const reqParams: ParamsOptions = {};
      reqObj.map((arr: any) => {
        reqParams[arr.name] = arr.value;
      });
      const resObj: any = await fetchMasterdataHierarchicalreferences(
        'SUSTAINABILITY_ECOLABELS',
        reqParams
      );
      if (resObj) {
        this.euEcoLabelLeaveOnLabels = resObj
          .filter((item) => item.id == euEcoLabelLeaveOnSectionId)[0]
          ?.children.filter(
            (label) => !excludedNotApprovedLabels.includes(label.id)
          ) as SelectItem[];

        this.euEcoLabelRinseOffLabels = resObj
          .filter((item) => item.id == euEcoLabelRinseOffSectionId)[0]
          ?.children.filter(
            (label) => !excludedNotApprovedLabels.includes(label.id)
          ) as SelectItem[];

        this.nordicSwanLeaveOnLabels = resObj
          .filter((item) => item.id == nordicSwanLeaveOnSectionId)[0]
          ?.children.filter(
            (label) => !excludedNotApprovedLabels.includes(label.id)
          ) as SelectItem[];

        this.nordicSwanRinseOffLabels = resObj
          .filter((item) => item.id == nordicSwanRinseOffSectionId)[0]
          ?.children.filter(
            (label) => !excludedNotApprovedLabels.includes(label.id)
          ) as SelectItem[];
      }
    },
    async fetchAllNaturalityLabels(reqObj: any) {
      const reqParams: ParamsOptions = {};
      reqObj.map((arr: any) => {
        reqParams[arr.name] = arr.value;
      });
      const resObj: any = await fetchMasterdataHierarchicalreferences(
        'SUSTAINABILITY_NATURALITY_LABELS',
        reqParams
      );
      if (resObj) {
        this.naturalityLabels = resObj.flatMap((parent) => {
          return parent.children.map((child) => {
            return {
              ...child,
              name: parent.name + ' ' + child.name,
            };
          });
        });
      }
    },
    async fetchAllSustainableSourcingStandardsLabels(reqObj: any) {
      const reqParams: ParamsOptions = {};
      reqObj.map((arr: any) => {
        reqParams[arr.name] = arr.value;
      });
      const resObj: any = await fetchMasterdataHierarchicalreferences(
        'SUSTAINABILITY_OTHERS',
        reqParams
      );
      if (resObj) {
        this.sustainableSourcingStandardsLabels = resObj
          .filter((item) => item.id == sustainableSourcingStandardsSectionId)
          .flatMap((parent) => parent.children);
      }
    },
    async fetchAllSustainabilityBiodegradabilityLabels(reqObj: any) {
      const reqParams: ParamsOptions = {};
      reqObj.map((arr: any) => {
        reqParams[arr.name] = arr.value;
      });
      const resObj: any = await fetchMasterdataHierarchicalreferences(
        'SUSTAINABILITY_BIODEGRADABILITY',
        reqParams
      );
      if (resObj) {
        this.sustainabilityBiodegradabilityLabels = resObj
          .filter((item) => item.id == sustainabilityBiodegradabilitySectionId)
          .flatMap((parent) => parent.children)
          .filter((label) => label.id === ReadilyBiodegradableId);
      }
    },
  },
});
