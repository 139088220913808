/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia';

interface State {
  loading: number;
  fullPath: string;
  fullPathHistory: string[];
}

export const useLoaderStore = defineStore('loader', {
  state: (): State => ({
    loading: 0,
    fullPath: '',
    fullPathHistory: [],
  }),

  actions: {
    updatePath(fullPath: string): void {
      if (fullPath !== this.fullPath && fullPath !== '/public/error') {
        const myFullPathHistory = this.fullPathHistory;
        myFullPathHistory.unshift(fullPath);
        this.fullPath = fullPath;
        this.fullPathHistory = myFullPathHistory.slice(0, 3);
      }
    },

    startLoading(): void {
      this.loading++;
    },

    finishLoading(): void {
      this.loading--;
    },

    resetLoading(): void {
      this.loading = 0;
    },
  },
});
