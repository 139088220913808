import { ref } from 'vue';

const mainDrawerId = 'dlite-main-drawer';
const mainDrawerMobileButtonTriggerId = 'dlite-main-drawer-navigation-links';

const isDrawerExpanded = ref(false);
const drawerButtonTrigger = ref(null);

export const useMainDrawer = () => {
  const toggleDrawer = () => {
    isDrawerExpanded.value = !isDrawerExpanded.value;
  };

  const hideDrawer = () => {
    isDrawerExpanded.value = false;
  };

  return {
    drawerButtonTrigger,
    mainDrawerId,
    mainDrawerMobileButtonTriggerId,
    isDrawerExpanded,
    toggleDrawer,
    hideDrawer,
  };
};
