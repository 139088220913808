import { SurfactantFilters } from '@/typings/enums';

export const BASFProductPCFEnv: Record<string, Record<string, string>> = {
  test: {
    RSPO: '8a80816e7c9ecd69017cc2304499285d',
    BIOGENIC: '8a80816e7c9ecd69017cc230a048285f',
    SCOTT: '8a8082027d2403ec017d386932202456',
  },
  development: {
    RSPO: '8a80816e7c9ecd69017cc2304499285d',
    BIOGENIC: '8a80816e7c9ecd69017cc230a048285f',
    SCOTT: '8a8082027d2403ec017d386932202456',
  },
  dev: {
    RSPO: '8a80816e7c9ecd69017cc2304499285d',
    BIOGENIC: '8a80816e7c9ecd69017cc230a048285f',
    SCOTT: '8a8082027d2403ec017d386932202456',
  },
  qual: {
    RSPO: '8a80816e7c9ecd69017cc2304499285d',
    BIOGENIC: '8a80816e7c9ecd69017cc230a048285f',
    SCOTT: '8a8082027d2403ec017d386932202456',
  },
  prod: {
    RSPO: '8a8082c97eaf959c017ee44b9eeb6c8d',
    BIOGENIC: '8a8082c97eaf959c017ee44affc64b9f',
    SCOTT: '8a8082c97eaf959c017ee44a30c44397',
  },
};

export const ConsumerType: Record<number, string> = {
  0: 'authentic',
  1: 'creative',
  2: 'energetic',
  3: 'perfect',
  4: 'professional',
  5: 'protected',
};

export const CHINA_PROVINCES: Record<string, string> = {
  安徽: 'Anhui',
  北京: 'Beijing',
  福建: 'Fujian',
  甘肃: 'Gansu',
  广东: 'Guangdong',
  广西: 'Guangxi',
  贵州: 'Guizhou',
  海南: 'Hainan',
  河北: 'Hebei',
  河南: 'Henan',
  黑龙江: 'Heilongjiang',
  湖北: 'Hubei',
  湖南: 'Hunan',
  吉林: 'Jilin',
  江苏: 'Jiangsu',
  江西: 'Jiangxi',
  辽宁: 'Liaoning',
  内蒙古: 'Inner Mongolia',
  宁夏: 'Ningxia',
  青海: 'Qinghai',
  山东: 'Shandong',
  山西: 'Shanxi',
  陕西: 'Shaanxi',
  上海: 'Shanghai',
  四川: 'Sichuan',
  天津: 'Tianjin',
  西藏: 'Tibet',
  新疆: 'Xinjiang',
  云南: 'Yunnan',
  浙江: 'Zhejiang',
  重庆: 'Chongqing',
  澳门: 'Macao',
  香港: 'Hong Kong',
  台湾: 'Taiwan',
  南海诸岛: 'South China Sea Islands',
};

export const CATEGORIES_IDS_WHITELIST_PRODUCTS = [
  'Skncr:WrldCtg:72E3774090F0FAC48E7BC07789AD75D1',
  'SnCr:WrldCtg:9D1AB2A5D81886EA0C5B24D2D68A50B6',
  'HrPrdct:WrldCtg:A2F344C2EED802F67E7B18A9D3C4E9E1',
  'SpBthPr:WrldCtg:B1E480BD8072F2374571CA7A12AEA5CC',
  'ClrCsmt:WrldCtg:D1705A9E3C64B77A4790139BFAC0E79C',
  'Ddrnts:WrldCtg:43F6628EA8ED1ABBFB4D2C8F01AFBEEA',
  'Frgrncs:WrldCtg:0F0A4EC3CAB2907CB6D5397E59A78879',
  'RlHgn:WrldCtg:B2ED30C68B47C8D9EF3C876CA23CF57D',
  'ShvngDp:WrldCtg:D8793A3824D0FB8BF06BFFE401208C43',
  'PtPrdct:WrldCtg:860EE4488B6DB7053D8D6E35E41C5681',
  'DprsFmn:WrldCtg:59BD56415D8678BFE7C4677191B4C7AB',
];

export const AVAILABLE_LANGUAGES = {
  ENGLISH: 'en',
  SPANISH: 'es',
  PORTUGUESE: 'pt',
  CHINESE: 'zh',
  JAPANESE: 'ja',
  KOREAN: 'ko',
  THAI: 'th',
  RUSSIAN: 'ru',
  POLISH: 'pl',
};

export const AVAILABLE_LANGUAGES_LIST = [
  {
    id: AVAILABLE_LANGUAGES.ENGLISH,
    name: `app.common.languages.${AVAILABLE_LANGUAGES.ENGLISH}`,
  },
  {
    id: AVAILABLE_LANGUAGES.SPANISH,
    name: `app.common.languages.${AVAILABLE_LANGUAGES.SPANISH}`,
  },
  {
    id: AVAILABLE_LANGUAGES.PORTUGUESE,
    name: `app.common.languages.${AVAILABLE_LANGUAGES.PORTUGUESE}`,
  },
  {
    id: AVAILABLE_LANGUAGES.CHINESE,
    name: `app.common.languages.${AVAILABLE_LANGUAGES.CHINESE}`,
  },
  {
    id: AVAILABLE_LANGUAGES.JAPANESE,
    name: `app.common.languages.${AVAILABLE_LANGUAGES.JAPANESE}`,
  },
  {
    id: AVAILABLE_LANGUAGES.KOREAN,
    name: `app.common.languages.${AVAILABLE_LANGUAGES.KOREAN}`,
  },
  {
    id: AVAILABLE_LANGUAGES.THAI,
    name: `app.common.languages.${AVAILABLE_LANGUAGES.THAI}`,
  },
];

// Hardcoded Ids to be able to map Dimethicone to Dimethicone100Cst
export const DimethiconeIds = {
  Dimethicone: 'INCI:Dmthcn:CBB588DA2F11C2EA39D0CE4ED370235A',
  Dimethicone100Maestro: '741503D4AD5298A1BA78BA8C6AF06D36',
  Dimethicone100Jockey: 'BA421C169B1A006601A31E29D83461F1',
  DimethiconeAlternativeMaestro:
    'SlcnLLt:MstrLTp:8CD22179357F4F60725F985547703582',
};

export const SurfactantColums = {
  [SurfactantFilters.NATURALNESS]: {
    unit: '%',
    decimals: 0,
  },
  [SurfactantFilters.RATE_FOAMING]: {
    unit: '',
    decimals: 2,
  },
  [SurfactantFilters.FOAM_HEIGHT_STABILITY]: {
    unit: '',
    decimals: 3,
  },
  [SurfactantFilters.FOAM_HEIGHT_30]: {
    unit: '',
    decimals: 2,
  },
  [SurfactantFilters.FOAM_HEIGHT_100]: {
    unit: '',
    decimals: 2,
  },
  [SurfactantFilters.FOAM_HEIGHT_160]: {
    unit: '',
    decimals: 2,
  },
  [SurfactantFilters.FOAM_HEIGHT_220]: {
    unit: '',
    decimals: 2,
  },
  [SurfactantFilters.FOAM_HEIGHT_280]: {
    unit: '',
    decimals: 2,
  },
  [SurfactantFilters.SFT_RATE_DECREASE]: {
    unit: '',
    decimals: 2,
  },
  [SurfactantFilters.SFT_EQUILIBRIUM]: {
    unit: '',
    decimals: 2,
  },
  [SurfactantFilters.IFT_RATE_DECREASE]: {
    unit: '',
    decimals: 2,
  },
  [SurfactantFilters.IFT_EQUILIBRIUM]: {
    unit: '',
    decimals: 2,
  },
  [SurfactantFilters.ELASTIC_MODULUS]: {
    unit: '',
    decimals: 2,
  },
  [SurfactantFilters.MEAN_BUBBLE_SIZE]: {
    unit: '',
    decimals: 2,
  },
  [SurfactantFilters.DECAY_BUBBLE_SIZE]: {
    unit: '',
    decimals: 2,
  },
};

export const DliteInfo = {
  title: "D'lite",
  subject: 'Market Understanding meets Science',
  author: 'BASF SE',
};
