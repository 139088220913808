<template>
  <span>
    <span id="markDownViewer" v-html="parseMarkdown(mdText)"></span>
    <span v-if="isDev" style="color: #c50022">{{ mdFileName }}</span>
  </span>
</template>

<script setup lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref, computed, watchEffect } from 'vue';
import { ParamsOptions } from '@/api/types';
import { fetchMarkdownContentPublic, fetchMarkdownContentApi } from '@/api';
import { useI18n } from '@/composables/useI18n';
import { generateUuid } from '@/lib/utils';
import { useAuthStore } from '@/stores/auth';

interface Props {
  isPublic?: boolean;
  filePublic?: string;
  fileApi?: string;
  fileLanguage?: string;
  containerApi?: string;
  folderApi?: string;
  inlineContent?: string;
}

const props = withDefaults(defineProps<Props>(), {
  isPublic: false,
  filePublic: 'content',
  fileApi: 'content',
  fileLanguage: '',
  containerApi: '',
  folderApi: '',
  inlineContent: '',
});

const { locale } = useI18n();
const authStore = useAuthStore();

const mdText = ref('');
const mdLanguage = ref('');
const mdFileName = ref('');

const isDev = computed(() => authStore.isDev);

const parseMarkdown = (markdownText: any) => {
  const htmlText = markdownText
    .replace(/^##### (.*$)/gim, '<h4>$1</h4>')
    .replace(/^#### (.*$)/gim, '<h4>$1</h4>')
    .replace(/^### (.*$)/gim, '<h3>$1</h3>')
    .replace(/^## (.*$)/gim, '<h2>$1</h2>')
    .replace(/^# (.*$)/gim, '<h1>$1</h1>')
    .replace(/\*\*(.*)\*\*/gim, '<b>$1</b>')
    .replace(/\*(.*)\*/gim, '<i>$1</i>')
    .replace(/!\[(.*?)\]\((.*?)\)/gim, "<img alt='$1' src='$2' />")
    .replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2'>$1</a>")
    .replace(/\{\{(.*?)\}\}/gim, props.inlineContent)
    .replace(/\n$/gim, '<br />');

  return htmlText.trim();
};

const getContentResponsePublic = async (reqObj: any) => {
  let data = '';
  try {
    const reqParams: ParamsOptions = {};
    reqObj.params.map((arr: any) => {
      reqParams[arr.name] = arr.value;
    });
    data = await fetchMarkdownContentPublic(reqObj.id, reqParams);
  } catch (e) {
    data = '';
  }
  return data;
};

const getContentResponseApi = async (reqObj: any) => {
  let data = '';
  try {
    const reqParams: ParamsOptions = {};
    reqObj.params.map((arr: any) => {
      reqParams[arr.name] = arr.value;
    });
    data = await fetchMarkdownContentApi(reqParams);
  } catch (e) {
    data = '';
  }
  return data;
};

const getContentPublic = async () => {
  let content = '';
  if (props.filePublic && props.filePublic.length > 0) {
    const mdLocale = `${props.filePublic}-${mdLanguage.value}.md`;
    const reqObjLocale = {
      params: [{ name: 'uuid', value: generateUuid() }],
      id: mdLocale,
    };
    const respDataLocale = await getContentResponsePublic(reqObjLocale);
    if (respDataLocale) {
      content = respDataLocale;
    } else {
      if (mdLanguage.value !== 'en') {
        const mdFallback = `${props.filePublic}-en.md`;
        const reqObjFallback = {
          params: [{ name: 'uuid', value: generateUuid() }],
          id: mdFallback,
        };
        const respDataFallback = await getContentResponsePublic(reqObjFallback);
        if (respDataFallback) {
          content = respDataFallback;
        }
      }
    }
  }
  mdText.value = content;
};

const getContentApi = async () => {
  let content = '';
  if (props.fileApi && props.fileApi.length > 0) {
    const reqObj = {
      params: [{ name: 'uuid', value: generateUuid() }],
    };
    if (props.folderApi && props.folderApi.length > 0) {
      reqObj.params.push({ name: 'folder', value: props.folderApi });
    }
    if (props.containerApi && props.containerApi.length > 0) {
      reqObj.params.push({ name: 'container', value: props.containerApi });
    }
    const reqObjLocale = reqObj;
    const mdLocale = `${props.fileApi}-${mdLanguage.value}.md`;

    mdFileName.value = `${props.folderApi}/${mdLocale}`;

    reqObjLocale.params.push({ name: 'filename', value: mdLocale });
    const respDataLocale = await getContentResponseApi(reqObjLocale);
    if (respDataLocale) {
      content = respDataLocale;
    } else {
      if (mdLanguage.value != 'en') {
        const reqObjFallback = reqObj;
        const mdFallback = `${props.fileApi}-en.md`;
        reqObjFallback.params.push({ name: 'filename', value: mdFallback });
        const respDataFallback = await getContentResponseApi(reqObjFallback);
        if (respDataFallback) {
          content = respDataFallback;
        }
      }
    }
  }
  mdText.value = content;
};

watchEffect(() => {
  mdLanguage.value = props.fileLanguage || locale;

  if (props.isPublic) {
    getContentPublic();
  } else {
    getContentApi();
  }
});
</script>

<style scoped lang="css">
#markDownViewer :deep(pre) {
  padding: 1rem 0;
}

#markDownViewer :deep(code) {
  padding: 1rem 0.4rem;
}

#markDownViewer :deep(a),
#markDownViewer :deep(a:active),
#markDownViewer :deep(a:hover) {
  color: #c50022;
}

#markDownViewer :deep(h1) {
  margin: 0.5em 0;
}

#markDownViewer :deep(h2) {
  margin: 0.4em 0;
}

#markDownViewer :deep(h3) {
  margin: 0.3em 0;
}

#markDownViewer :deep(h4) {
  margin: 0.2em 0;
}

#markDownViewer :deep(h5) {
  margin: 0.1em 0;
}
</style>
