<template>
  <section v-if="appStore.dialogSectionMobileItem" class="dialog-section">
    <header class="dialog-section__header" :style="headerStyle">
      <button
        class="dialog-section__close-btn"
        :aria-label="$tc('app.accessibility.close-modal')"
        @click="closeDialog"
      >
        <BaseIcon :icon="ICONS.CLOSE" :color="'#ffffff'" />
      </button>
      <p class="dialog-section__header__title">
        {{ $t(`app.breadcrumbs.${appStore.dialogSectionMobileItem.title}`) }}
      </p>
      <div class="icon-wrapper">
        <div
          class="icon-wrapper--inner"
          :style="`background-color: ${appStore.dialogSectionMobileItem.color}`"
        >
          <BaseIcon
            :icon="appStore.dialogSectionMobileItem.icon"
            color="white"
          />
        </div>
      </div>
    </header>
    <article class="dialog-section__description">
      <p
        v-html="
          $t(
            `app.page.home.sections.${appStore.dialogSectionMobileItem.slides[0].description}`
          )
        "
      ></p>
    </article>
    <footer class="dialog-section__cta">
      <BaseButton
        class="dialog-section__cta__btn"
        iconRight="arrow_forward_icon"
        @click="ctaClick"
      >
        {{ appStore.dialogSectionMobileItem.goToText }}
      </BaseButton>
    </footer>
  </section>
</template>

<script setup lang="ts">
import BaseIcon from '@/components/common/base/BaseIcon.vue';
import { useI18n } from '@/composables/useI18n';
import { computed } from 'vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
import { useAppStore } from '@/stores/app';
import { ICONS } from '@/typings/enums';

const emit = defineEmits(['close', 'cta']);

const appStore = useAppStore();
const { $t, $tc } = useI18n();

const itemImage = computed(() => {
  if (appStore.dialogSectionMobileItem?.asset) {
    return appStore.dialogSectionMobileItem.asset;
  }
  if (appStore.dialogSectionMobileItem?.slides[0]?.asset) {
    return require(`../../assets/home/${appStore.dialogSectionMobileItem.slides[0].asset}`);
  }
  return '';
});

const headerStyle = computed(() => {
  const gradient =
    'background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))';
  if (itemImage.value) {
    return `${gradient}, url(${itemImage.value})`;
  }
  return `${gradient}`;
});

const ctaBgColor = computed(() => {
  if (appStore.dialogSectionMobileItem?.color) {
    return appStore.dialogSectionMobileItem.color;
  }
  return 'var(--base)';
});

const closeDialog = () => {
  emit('close');
  appStore.hideDialogSectionMobile();
};

const ctaClick = () => {
  emit('cta');
  appStore.ctaClickDialogSectionMobile();
};
</script>

<style scoped lang="scss">
.dialog-section {
  display: grid;
  grid-template-rows: 1fr 1.25fr minmax(5rem, auto);
  position: fixed;
  inset: 0;
  height: 100lvh;
  background-color: white;

  &__header {
    display: grid;
    position: relative;
    background-position: center;
    background-size: cover;

    &__title {
      margin-top: auto;
      margin-bottom: 60px;
      margin-inline: auto;
      text-align: center;
      padding-inline: 1rem;
      color: white;
      font-size: x-large;
      font-weight: 700;
    }

    .icon-wrapper {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 50%);
      border-radius: 100%;
      border: 2px solid white;
      padding: 4px;

      &--inner {
        width: 70px;
        height: 70px;
        padding: 1rem;
        border-radius: 100%;
        border: 2px solid white;
      }
    }
  }

  &__close-btn {
    margin-left: auto;
    width: 3.25rem;
    height: 3.25rem;
    padding: 1rem;
  }

  &__description,
  &__cta {
    padding-inline: 1.5rem;
  }

  &__description {
    overflow-y: auto;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  &__cta {
    --primary-color: v-bind(ctaBgColor);
    &__btn {
      font-size: 18px;
      width: 100%;
    }
  }
}
</style>
