<template>
  <transition name="fade">
    <div
      class="base-header-background"
      :style="`${calculatedHeight}; -webkit-mask-image: url(${require('@/assets/waves.webp')}); mask-image: url(${require('@/assets/waves.webp')})`"
    ></div>
  </transition>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  isHome: boolean;
  headerHeight?: number;
}

const props = defineProps<Props>();

const calculatedHeight = computed(() =>
  props.headerHeight ? `height: ${props.headerHeight}px` : 'height: 335px'
);

const maskPosition = computed(() => (props.headerHeight ? '0 50%' : '0 100%'));

const zIndex = computed(() => (props.isHome ? 'unset' : '-1'));
</script>

<style scoped>
.base-header-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 56px;
  background-color: var(--base);
  mask-size: cover;
  mask-position: v-bind(maskPosition);
  z-index: v-bind(zIndex);
  overflow: hidden;
}
</style>
