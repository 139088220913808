import { defineStore } from 'pinia';
import { MyComparisonBase, MyComparisonItem } from '@/api/types';
import { deliverPreferences, fetchPreferences } from '@/api';
import { ComparisonType, SectionsIds } from '@/typings/enums';

const ComparisonIds = {
  [ComparisonType.BRAND_ANALYTICS]: 'my-comparison-ba',
  [ComparisonType.CONSUMER_PRODUCTS]: 'my-comparison-cp',
  [ComparisonType.FORMULATION_DESIGN]: 'my-comparison-fd',
};

const lang = 'en';
const params = { lang };

interface State {
  baItems: MyComparisonBase;
  baSortOder: string[];
  cpItems: MyComparisonBase;
  cpSortOder: string[];
  fdItems: MyComparisonBase;
  fdSortOder: string[];
  itemsLoaded: boolean;
  comparisonTab: string;
}

export const useComparisonStore = defineStore('comparison', {
  state: (): State => ({
    baItems: {
      id: ComparisonIds[ComparisonType.BRAND_ANALYTICS],
      name: '',
      items: [],
    },
    baSortOder: [],
    cpItems: {
      id: ComparisonIds[ComparisonType.CONSUMER_PRODUCTS],
      name: '',
      items: [],
    },
    cpSortOder: [],
    fdItems: {
      id: ComparisonIds[ComparisonType.FORMULATION_DESIGN],
      name: '',
      items: [],
    },
    fdSortOder: [],
    itemsLoaded: false,
    comparisonTab: SectionsIds.CONSUMER_PRODUCTS,
  }),

  getters: {
    baComparatorType() {
      return ComparisonType.BRAND_ANALYTICS;
    },

    cpComparatorType() {
      return ComparisonType.CONSUMER_PRODUCTS;
    },

    fdComparatorType() {
      return ComparisonType.FORMULATION_DESIGN;
    },
  },

  actions: {
    setBaItems(baItems: MyComparisonBase) {
      this.baItems = baItems;
    },

    setBaSortOder(baSortOder: string[]) {
      this.baSortOder = baSortOder;
    },

    resetBaItems() {
      this.baItems = {
        id: ComparisonIds[ComparisonType.BRAND_ANALYTICS],
        name: '',
        items: [],
      };
    },

    setCpItems(cpItems: MyComparisonBase) {
      this.cpItems = cpItems;
    },

    setCpSortOder(cpSortOder: string[]) {
      this.cpSortOder = cpSortOder;
    },

    resetCpItems() {
      this.cpItems = {
        id: ComparisonIds[ComparisonType.CONSUMER_PRODUCTS],
        name: '',
        items: [],
      };
    },

    setFdItems(fdItems: MyComparisonBase) {
      this.fdItems = fdItems;
    },

    setFdSortOder(fdSortOder: string[]) {
      this.fdSortOder = fdSortOder;
    },

    resetFdItems() {
      this.fdItems = {
        id: ComparisonIds[ComparisonType.FORMULATION_DESIGN],
        name: '',
        items: [],
      };
    },

    setCurrentTab(tabName: string) {
      this.comparisonTab = tabName;
    },

    async loadItems() {
      await Promise.all([
        this.getItems(ComparisonType.BRAND_ANALYTICS),
        this.getItems(ComparisonType.CONSUMER_PRODUCTS),
        this.getItems(ComparisonType.FORMULATION_DESIGN),
      ]);
      this.itemsLoaded = true;
    },

    async getItems(type: number) {
      const key = ComparisonIds[type];
      const responseData = await fetchPreferences(key, params);
      if (responseData) {
        switch (type) {
          case ComparisonType.BRAND_ANALYTICS:
            this.setBaItems(JSON.parse(responseData.value));
            break;
          case ComparisonType.CONSUMER_PRODUCTS:
            this.setCpItems(JSON.parse(responseData.value));
            break;
          case ComparisonType.FORMULATION_DESIGN:
            this.setFdItems(JSON.parse(responseData.value));
            break;
        }
      }
    },

    async saveItems(type: number) {
      const key = ComparisonIds[type];

      let items = null;
      switch (type) {
        case ComparisonType.BRAND_ANALYTICS:
          items = this.baItems;
          break;
        case ComparisonType.CONSUMER_PRODUCTS:
          items = this.cpItems;
          break;
        case ComparisonType.FORMULATION_DESIGN:
          items = this.fdItems;
          break;
      }

      const responseData = await deliverPreferences(key, lang, items);
      if (responseData) {
        switch (type) {
          case ComparisonType.BRAND_ANALYTICS:
            this.setBaItems(JSON.parse(responseData.value));
            break;
          case ComparisonType.CONSUMER_PRODUCTS:
            this.setCpItems(JSON.parse(responseData.value));
            break;
          case ComparisonType.FORMULATION_DESIGN:
            this.setFdItems(JSON.parse(responseData.value));
            break;
        }
      }
    },

    async deleteAllFromType(type: number) {
      const key = ComparisonIds[type];
      let items;
      switch (type) {
        case ComparisonType.BRAND_ANALYTICS:
          this.resetBaItems();
          items = this.baItems;
          break;
        case ComparisonType.CONSUMER_PRODUCTS:
          this.resetCpItems();
          items = this.cpItems;
          break;
        case ComparisonType.FORMULATION_DESIGN:
          this.resetFdItems();
          items = this.fdItems;
          break;
      }

      const responseData = await deliverPreferences(key, lang, items);
      if (responseData) {
        switch (type) {
          case ComparisonType.BRAND_ANALYTICS:
            this.setBaItems(JSON.parse(responseData.value));
            break;
          case ComparisonType.CONSUMER_PRODUCTS:
            this.setCpItems(JSON.parse(responseData.value));
            break;
          case ComparisonType.FORMULATION_DESIGN:
            this.setFdItems(JSON.parse(responseData.value));
            break;
        }
      }
    },

    async addItem(item: MyComparisonItem) {
      const type = item.type;
      const key = ComparisonIds[type];
      let storeItems: MyComparisonItem[] = [];
      switch (type) {
        case ComparisonType.BRAND_ANALYTICS:
          storeItems = this.baItems.items;
          break;
        case ComparisonType.CONSUMER_PRODUCTS:
          storeItems = this.cpItems.items;
          break;
        case ComparisonType.FORMULATION_DESIGN:
          storeItems = this.fdItems.items;
          break;
      }
      storeItems.unshift(item);

      const seen = new Set();
      const filteredItems = storeItems.filter((el) => {
        const duplicate = seen.has(el.id);
        seen.add(el.id);
        return !duplicate;
      });

      let items = null;
      switch (type) {
        case ComparisonType.BRAND_ANALYTICS:
          items = this.baItems;
          break;
        case ComparisonType.CONSUMER_PRODUCTS:
          items = this.cpItems;
          break;
        case ComparisonType.FORMULATION_DESIGN:
          items = this.fdItems;
          break;
      }

      if (filteredItems.length > 4) {
        items.items = filteredItems.slice(0, 4);
      } else {
        items.items = filteredItems;
      }

      const responseData = await deliverPreferences(key, lang, items);
      if (responseData) {
        switch (type) {
          case ComparisonType.BRAND_ANALYTICS:
            this.setBaItems(JSON.parse(responseData.value));
            break;
          case ComparisonType.CONSUMER_PRODUCTS:
            this.setCpItems(JSON.parse(responseData.value));
            break;
          case ComparisonType.FORMULATION_DESIGN:
            this.setFdItems(JSON.parse(responseData.value));
            break;
        }
      }
    },

    async deleteItem(item: MyComparisonItem) {
      const type = item.type;
      const key = ComparisonIds[type];
      let storeItems: MyComparisonItem[] = [];
      let isKnownType = false;

      switch (type) {
        case ComparisonType.BRAND_ANALYTICS:
          storeItems = this.baItems.items;
          isKnownType = true;
          break;
        case ComparisonType.CONSUMER_PRODUCTS:
          storeItems = this.cpItems.items;
          isKnownType = true;
          break;
        case ComparisonType.FORMULATION_DESIGN:
          storeItems = this.fdItems.items;
          isKnownType = true;
          break;
        default:
          this.resetBaItems();
          this.resetCpItems();
          this.resetFdItems();
          this.resetIsItems();
      }

      if (isKnownType) {
        const filteredItems: MyComparisonItem[] = storeItems.filter(
          (i: MyComparisonItem) => i.id !== item.id
        );
        let items = null;
        switch (type) {
          case ComparisonType.BRAND_ANALYTICS:
            items = this.baItems;
            break;
          case ComparisonType.CONSUMER_PRODUCTS:
            items = this.cpItems;
            break;
          case ComparisonType.FORMULATION_DESIGN:
            items = this.fdItems;
            break;
        }
        items.items = filteredItems;
        const responseData = await deliverPreferences(key, lang, items);
        if (responseData) {
          switch (type) {
            case ComparisonType.BRAND_ANALYTICS:
              this.setBaItems(JSON.parse(responseData.value));
              break;
            case ComparisonType.CONSUMER_PRODUCTS:
              this.setCpItems(JSON.parse(responseData.value));
              break;
            case ComparisonType.FORMULATION_DESIGN:
              this.setFdItems(JSON.parse(responseData.value));
              break;
          }
        }
      }
    },
  },
});
