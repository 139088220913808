import { ref } from 'vue';

type NotificationType = 'success' | 'error' | 'warning' | 'info';

const showNotification = ref(false);
const notificationText = ref('');
const notificationType = ref<NotificationType>('success');

export const useNotifications = () => {
  return {
    showNotification,
    notificationText,
    notificationType,
  };
};
