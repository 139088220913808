<template>
  <v-app-bar app :height="70" style="padding: 0">
    <v-container fluid>
      <v-row no-gutters style="background-color: #c50022">
        <v-col cols="6">
          <div
            id="baseHeaderLogos"
            style="height: 80px"
            class="d-flex align-center"
          >
            <router-link to="/" class="mx-3">
              <img
                alt="BASF"
                class="logo-basf"
                :src="require('@/assets/logo_basf.svg')"
              />
            </router-link>
          </div>
        </v-col>
        <v-col cols="6">
          <div
            id="baseHeaderUserdata"
            style="height: 80px; float: right"
            class="d-flex align-center"
          >
            <div class="float-right pr-2 d-flex align-center">
              <LanguageSelector />

              <div v-if="isMaintenanceMode" class="user-data--headline">
                <v-avatar class="pa-1" tile color="#c50022" width="240">
                  <span class="white--text headline">{{
                    $t('app.common.value.maintenance')
                  }}</span>
                </v-avatar>
              </div>
              <div v-else-if="env !== 'PROD'" class="user-data--headline">
                <v-avatar class="px-3" tile color="#c50022" width="auto">
                  <span class="white--text headline"> {{ env }} </span>
                </v-avatar>
              </div>
              <div v-else>&nbsp;</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script setup lang="ts">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref, computed, watch } from 'vue';
import LanguageSelector from '@/components/common/LanguageSelector.vue';
import { useLoaderStore } from '@/stores/loader';
import { getEnvironment } from '@/lib/utils';
import { useI18n } from '@/composables/useI18n';
import { usePublicPages } from '@/composables/usePublicPages';

const loaderStore = useLoaderStore();
const { isMaintenanceMode } = usePublicPages();

const { $t } = useI18n();

const loading = ref(false);

const getLoading = computed(() => loaderStore.loading);

const env = computed(() => {
  const { isLocal, isDev, isQA, isProd } = getEnvironment();
  if (isLocal) return 'LOCAL';
  if (isDev) return 'DEV';
  if (isQA) return 'QA';
  if (isProd) return 'PROD';

  return 'LOCAL';
});

watch(getLoading, (newVal, oldVal) => {
  if (newVal === 0) return (loading.value = false);
  if (newVal < 0) return (loading.value = false);
  if (oldVal === 0) return (loading.value = true);
});
</script>

<style scoped lang="scss">
.baseHeaderNavItemsWrapper {
  width: 100%;
  max-width: 1440px;
}

#baseHeaderLogos {
  padding: 15px 0 0 7px;
}

.v-application a,
.v-application a:visited,
.v-application #baseHeaderNavItems a:active,
.menuItems a:active {
  text-decoration: none !important;
  color: var(--base);
}
.v-application .menuItems,
.v-application .menuItems a {
  text-decoration: none !important;
  color: #555555 !important;
}
.v-application a:hover {
  text-decoration: none !important;
  color: #b5b5b5 !important;
}

.dlite-red--text {
  color: #c50022 !important;
  caret-color: #c50022 !important;
}
.centerInput {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.logo-basf {
  width: 110px;
  height: 41px;
}

.user-data--headline {
  border: solid white 1px;
  position: absolute;
  top: 12px;
  left: calc(50% + 30px);
  transform: translateX(-50%);
}
</style>
