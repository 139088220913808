import { getBase64FromArrayBuffer } from '@/lib/utils';
import api from '@/plugins/axiosApi';
import {
  TrendsResponse,
  TrendsAdvancedSearchReq,
  TrendData,
} from '@/typings/trends';

export const fetchTrends = async (
  params: TrendsAdvancedSearchReq
): Promise<TrendsResponse> => {
  const { data } = await api.post('/api/trend', params);
  return data;
};

export const fetchTrend = async (trendId: string): Promise<TrendData> => {
  const { data } = await api({
    url: `/api/trend/${trendId}`,
    method: 'get',
  });
  return data as TrendData;
};

export const fetchTrendDocument = async (
  trendId: string,
  userGroup: string,
  documentType: string,
  lang: string,
  blobId: string
) => {
  const { data } = await api({
    url: `/api/trend/${trendId}/${userGroup}/${documentType}/${lang}/download/${blobId}`,
    method: 'get',
    responseType: 'arraybuffer',
  });

  return data;
};

export const fetchAllFilesTrend = async (
  trendId: string,
  userGroup: string,
  lang: string
) => {
  const { data } = await api({
    url: `/api/trend/${trendId}/${userGroup}/${lang}/download`,
    method: 'get',
    responseType: 'arraybuffer',
  });

  return data;
};

export const fetchTrendPicture = async (trendId: string) => {
  const { data } = await api({
    url: `/api/trend/${trendId}/picture/download`,
    method: 'get',
    responseType: 'arraybuffer',
  });

  if (data) return getBase64FromArrayBuffer(data);

  return null;
};
