<template>
  <div class="configuration-tooltip-wrapper">
    <div
      ref="configurationTooltip"
      :class="`configuration-tooltip ${isSmallMobile ? 'right' : alignment}`"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import { onMounted, ref } from 'vue';
import animejs from 'animejs';
import { useResponsive } from '@/composables/useResponsive';

interface Props {
  alignment?: 'center' | 'left' | 'right';
}

withDefaults(defineProps<Props>(), {
  alignment: 'center',
});
const emit = defineEmits(['close']);

const { defaultEaseOut, defaultEaseIn, veryShortDuration, isSmallMobile } =
  useResponsive();

const configurationTooltip = ref<HTMLDivElement>();

const enterAnim = {
  easing: defaultEaseOut,
  duration: veryShortDuration,
  targets: '.configuration-tooltip-wrapper',
  opacity: 1,
  translateY: ['-10px', 0],
};

const exitAnim = {
  easing: defaultEaseIn,
  duration: veryShortDuration,
  targets: '.configuration-tooltip-wrapper',
  opacity: 0,
  translateY: [0, '-5px'],
};

onClickOutside(configurationTooltip, () => {
  animejs({
    ...exitAnim,
    complete: () => emit('close'),
  });
});

onMounted(() => {
  animejs(enterAnim);
});
</script>

<style scoped lang="scss">
.configuration-tooltip {
  --configuration-bg-color: white;
  --configuration-border-color: var(--base-lightest);
}
.configuration-tooltip.center {
  --tooltip-transform: translate(calc(-50% + 9px), 7px);
  --tooltip-before-left: calc(50% - 6px);
  --tooltip-after-left: calc(50% - 5px);
}
.configuration-tooltip.left {
  --tooltip-transform: translate(calc(-5% + 8px), 7px);
  --tooltip-before-left: calc(5% - 6px);
  --tooltip-after-left: calc(5% - 5px);
}
.configuration-tooltip.right {
  --tooltip-transform: translate(calc(-95% + 17px), 7px);
  --tooltip-before-left: calc(95% - 14px);
  --tooltip-after-left: calc(95% - 13px);
}
.configuration-tooltip-wrapper {
  position: absolute;
  z-index: 30;
  opacity: 0;
}
.configuration-tooltip {
  position: relative;
  background-color: var(--configuration-bg-color);
  border: 1px solid var(--configuration-border-color);
  transform: var(--tooltip-transform);
}
.configuration-tooltip:before,
.configuration-tooltip:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 100%;
  width: 0;
  height: 0;
}
.configuration-tooltip:before {
  left: var(--tooltip-before-left);
  border: 6px solid transparent;
  border-bottom-color: var(--configuration-border-color);
  border-bottom-width: 10px;
  border-top-width: 10px;
}
.configuration-tooltip:after {
  left: var(--tooltip-after-left);
  border: 5px solid transparent;
  border-bottom-color: var(--configuration-bg-color);
  border-bottom-width: 8px;
}
</style>
