/* eslint-disable  @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia';
import {
  BaseItem,
  EmollientMaestroBenchmark,
  EmollientMaestroMixture,
  EmollientMaestroSearchReq,
  MaestroOilType,
  ParamsOptions,
} from '@/api/types';
import { fetchAllMaestroOilTypes } from '@/api';
import {
  fetchEmollientMaestroBenchmarks,
  fetchEmollientMaestroMixtures,
  fetchEmollientMaestroOils,
} from '@/api/emollientMaestro';
import { arrayToObject } from '@/lib/utils';

interface State {
  sortOrder: string;
  sortBy: string;
  oilTypes: MaestroOilType[];
  benchmarks: Record<string, EmollientMaestroBenchmark[]>;
  mixtureCandidates: EmollientMaestroMixture[] | null;
  oils: BaseItem[];
  beckmarksAlternatives: Record<string, string>;
  resetValues: boolean;
  maestroPref: any | null;
}

export const useEmollientMaestroStore = defineStore('emollientMaestro', {
  state: (): State => ({
    sortOrder: 'asc',
    sortBy: 'title',
    oilTypes: [],
    benchmarks: {},
    oils: [],
    beckmarksAlternatives: {},
    mixtureCandidates: null,
    resetValues: false,
    maestroPref: localStorage.getItem('MaestroPref')
      ? JSON.parse(localStorage.getItem('MaestroPref') as string)
      : null,
  }),

  actions: {
    setResetValues(val: boolean) {
      this.resetValues = val;
    },

    setPreferences(params: any) {
      const savedPref = localStorage.getItem('MaestroPref');

      const savedPrefParsed = savedPref ? JSON.parse(savedPref) : {};
      const newValues = {
        ...savedPrefParsed,
        ...params,
      };

      localStorage.setItem('MaestroPref', JSON.stringify(newValues));
      this.maestroPref = newValues;
    },

    async getOilTypes() {
      const resObj = await fetchAllMaestroOilTypes();
      this.oilTypes = resObj?.data ? (resObj.data as MaestroOilType[]) : [];
    },

    async getBenchmarks(reqObj: any) {
      const reqParams = arrayToObject(reqObj);
      const resObj = await fetchEmollientMaestroBenchmarks(reqParams);
      if (resObj?.items) {
        this.benchmarks[reqObj[0].value] =
          (resObj.items as EmollientMaestroBenchmark[]) || [];
        return resObj.items;
      }
    },

    async getEmollientMaestroMixturesAdvanced(
      reqObj: EmollientMaestroSearchReq
    ) {
      const responseData = await fetchEmollientMaestroMixtures(reqObj);
      this.mixtureCandidates = responseData.content;
    },

    async getEmollientMaestroOils(reqObj: ParamsOptions) {
      const responseData = await fetchEmollientMaestroOils(reqObj);
      this.oils = responseData.items;
    },

    async fetchAlternativesBenchmarks() {
      await this.getOilTypes();
      const brenchmarksPromises = this.oilTypes.map((oil: MaestroOilType) => {
        return this.getBenchmarks([{ name: 'type', value: oil.id }]);
      });
      const benchmarksData: Array<EmollientMaestroBenchmark[]> =
        await Promise.all(brenchmarksPromises);
      const dictBenchmarksAlternatives = benchmarksData.reduce(
        (
          acc: Record<string, string>,
          ingredients: EmollientMaestroBenchmark[],
          idx: number
        ) => {
          const alternativeId = this.oilTypes[idx].id;
          ingredients.forEach((ingredient) => {
            const [, ingredientId] = ingredient.id.split(':');
            acc[ingredientId] = alternativeId;
          });
          return acc;
        },
        {}
      );

      this.beckmarksAlternatives = dictBenchmarksAlternatives;
    },
  },
});
