import { defineStore } from 'pinia';
import {
  Formulation,
  FormulationsResponse,
  FormulationAdvancedSearchReq,
  ParamsOptions,
  FormulationsReq,
  FormulationAdvancedSearchPref,
} from '@/api/types';
import {
  fetchFormulations,
  fetchFormulationsAdvanced,
  fetchFormulationsSearch,
} from '@/api';
import { getItemFromStorage, setItemStorage } from '@/lib/utils';
import { useSecurityStore } from '@/stores/security';
import { FormulationAccessibles } from '@/typings/enums';

interface State {
  formulations: Formulation[];
  formulationsSearch: string | null;
  formulationsSort: string | null;
  formulationsPage: number | null;
  formulationsAdvancedPref: FormulationAdvancedSearchPref;
  backupFormulationsAdvancedPref: FormulationAdvancedSearchPref;
  noOfResults: number | null;
  openedFilters: string[];
  fetchedFiltersData: boolean;
  rangeKeys: string[];
}

export const useFormulationListStore = defineStore('formulationList', {
  state: (): State => ({
    formulations: [],
    formulationsSearch: '',
    formulationsSort: '',
    formulationsPage: 0,
    formulationsAdvancedPref: getItemFromStorage(
      'FormulationsAdvancedPref'
    ) || {
      params: [],
      categorySelection: [],
      subCategorySelection: [],
      consumerBenefitSelection: [],
      labelSelection: [],
      comparabilityLabelSelection: [],
      formatsSelection: [],
      spfSelection: [],
      regionSelection: [],
      tradeNamesIncludeSelection: [],
      tradeNamesExcludeSelection: [],
      ingredientsIncludeSelection: [],
      ingredientsExcludeSelection: [],
      rcc: [0, 100],
      biodegradability: [0, 100],
    },
    backupFormulationsAdvancedPref: getItemFromStorage(
      'FormulationsAdvancedPref'
    ) || {
      params: [],
      categorySelection: [],
      subCategorySelection: [],
      consumerBenefitSelection: [],
      labelSelection: [],
      comparabilityLabelSelection: [],
      formatsSelection: [],
      spfSelection: [],
      regionSelection: [],
      tradeNamesIncludeSelection: [],
      tradeNamesExcludeSelection: [],
      ingredientsIncludeSelection: [],
      ingredientsExcludeSelection: [],
      rcc: [0, 100],
      biodegradability: [0, 100],
    },
    noOfResults: null,
    openedFilters: [],
    fetchedFiltersData: false,
    rangeKeys: ['rcc', 'biodegradability'],
  }),

  getters: {
    canSeeDetails() {
      const securityStore = useSecurityStore();

      return securityStore.isFeatureAllowed(
        FormulationAccessibles.FORMULATION_DESIGN_DETAIL
      );
    },

    appliedFilters: (state) => () => {
      return Object.entries(state.backupFormulationsAdvancedPref)
        .filter(([key, val]) => key !== 'q' && key !== 'params' && val !== '')
        .reduce((acc, [key, val]) => {
          if (state.rangeKeys.includes(key) && val[0] === 0 && val[1] === 100) {
            return acc;
          } else if (state.rangeKeys.includes(key)) {
            acc.push(`${val[0]}-${val[1]}`);
          } else {
            acc.push(val);
          }

          return acc;
        }, [])
        .flat()
        .sort();
    },

    newFilters: (state) => () => {
      return Object.entries(state.formulationsAdvancedPref)
        .filter(([key, val]) => key !== 'q' && key !== 'params' && val !== '')
        .reduce((acc, [key, val]) => {
          if (state.rangeKeys.includes(key) && val[0] === 0 && val[1] === 100) {
            return acc;
          } else if (state.rangeKeys.includes(key)) {
            acc.push(`${val[0]}-${val[1]}`);
          } else {
            acc.push(val);
          }

          return acc;
        }, [])
        .flat()
        .sort();
    },
  },

  actions: {
    setFetchedDataFilters(value: boolean) {
      this.fetchedFiltersData = value;
    },

    setOpenedFilters(filters: string[]) {
      this.openedFilters = filters;
    },

    restoreFilters() {
      this.formulationsAdvancedPref = JSON.parse(
        JSON.stringify(this.backupFormulationsAdvancedPref)
      );
    },

    async getFormulations(reqObj: FormulationsReq) {
      this.noOfResults = null;
      const reqParams: ParamsOptions = {};
      reqObj.params.map((arr) => {
        reqParams[arr.name] = arr.value;
      });
      reqParams['q'] = this.formulationsSearch || '*';
      const responseData: FormulationsResponse = await fetchFormulations(
        reqParams
      );
      if (responseData) {
        this.updateFormulationsAndResults(
          responseData.formulations,
          responseData.noOfResults
        );
      }
    },

    async searchFormulations(reqObj: FormulationsReq) {
      const reqParams: ParamsOptions = {};
      reqObj.params.map((arr) => {
        reqParams[arr.name] = arr.value;
      });
      return fetchFormulationsSearch(reqParams);
    },

    async getFormulationsAdvanced(reqObj: FormulationAdvancedSearchReq) {
      this.noOfResults = null;
      const responseData: FormulationsResponse =
        await fetchFormulationsAdvanced(reqObj);
      if (responseData) {
        this.updateFormulationsAndResults(
          responseData.formulations,
          responseData.noOfResults
        );
      }
    },

    updateFormulationsAndResults(
      formulations: Formulation[],
      noOfResults: number
    ) {
      this.formulations = formulations;
      this.noOfResults = noOfResults;
    },

    setFormulationsSort(formulationsSort: string) {
      this.formulationsSort = formulationsSort;
    },

    setFormulationsPage(formulationsPage: number) {
      this.formulationsPage = formulationsPage;
    },

    setFormulationsSearch(formulationsSearch: string) {
      this.formulationsSearch = formulationsSearch;
    },

    setFormulationsAdvancedPref(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formulationsAdvancedPref: any,
      updateBackup = false
    ) {
      setItemStorage('FormulationsAdvancedPref', formulationsAdvancedPref);
      this.formulationsAdvancedPref = formulationsAdvancedPref;

      if (updateBackup) {
        this.backupFormulationsAdvancedPref = formulationsAdvancedPref;
      }
    },
  },
});
