import { ChinaProvinces } from '@/api/charts';

export default class Adapter {
  static chinaProvincesClicks(response: ChinaProvinces) {
    const datasets = response.datasets
      .filter((province) => province.label.id)
      .map((province) => {
        return {
          ...province,
          value: province.clicks,
        };
      });

    return { datasets };
  }
}
