import { computed } from 'vue';
import { useAppStore } from '@/stores/app';
import { useHeaderAndFooterStore } from '@/stores/headerAndFooter';

export const usePublicPages = () => {
  const appStore = useAppStore();
  const headerAndFooter = useHeaderAndFooterStore();

  const isMaintenanceMode = computed(() => appStore.isMaintenanceMode);
  const footerHeightPx = computed(() => `${headerAndFooter.footerHeight}px`);

  const changeMaintenanceMode = (isActive: boolean) => {
    appStore.setMaintenance(isActive);
  };

  return {
    isMaintenanceMode,
    footerHeightPx,
    changeMaintenanceMode,
  };
};
