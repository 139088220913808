/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { defineStore } from 'pinia';
import {
  IngredientsAdvancedSearchPref,
  IngredientsAdvancedSearchReq,
  BasfIngredient,
} from '@/api/types';
import { fetchIngredientsAdvanced } from '@/api';

interface State {
  ingredientList: BasfIngredient[];
  ingredientsSearch: string;
  ingredientsSort: string;
  ingredientsPage: number;
  noOfResults: number;
  openedFilters: string[];
  fetchedFiltersData: boolean;
  ingredientsAdvancedPref: IngredientsAdvancedSearchPref;
  backupIngredientsAdvancedPref: IngredientsAdvancedSearchPref;
  activeTab: string;
}

export const useIngredientStore = defineStore('ingredient', {
  state: (): State => ({
    ingredientList: [],
    ingredientsSearch: '',
    ingredientsSort: '',
    noOfResults: 0,
    fetchedFiltersData: false,
    ingredientsPage: 1,
    openedFilters: [],
    activeTab: '',
    ingredientsAdvancedPref: localStorage.getItem('IngredientsAdvancedPref')
      ? JSON.parse(localStorage.getItem('IngredientsAdvancedPref')!)
      : {
          params: [],
          categorySelection: [],
          subCategorySelection: [],
          functionSelection: [],
          regionsSelection: [],
        },
    backupIngredientsAdvancedPref: localStorage.getItem(
      'IngredientsAdvancedPref'
    )
      ? JSON.parse(localStorage.getItem('IngredientsAdvancedPref')!)
      : {
          params: [],
          categorySelection: [],
          subCategorySelection: [],
          functionSelection: [],
          regionsSelection: [],
        },
  }),

  getters: {
    appliedFilters: (state) => () => {
      return Object.entries(state.backupIngredientsAdvancedPref)
        .filter(([key]) => key !== 'q' && key !== 'params')
        .reduce((acc, [_key, val]) => {
          acc.push(val);
          return acc;
        }, [])
        .flat()
        .sort();
    },

    newFilters: (state) => () => {
      return Object.entries(state.ingredientsAdvancedPref)
        .filter(([key]) => key !== 'q' && key !== 'params')
        .reduce((acc, [_key, val]) => {
          acc.push(val);
          return acc;
        }, [])
        .flat()
        .sort();
    },
  },

  actions: {
    setFetchedDataFilters(value: boolean) {
      this.fetchedFiltersData = value;
    },

    setOpenedFilters(filters: string[]) {
      this.openedFilters = filters;
    },

    setIngredientsSort(ingredientsSort: string) {
      this.ingredientsSort = ingredientsSort;
    },

    setIngredientsPage(ingredientsPage: number) {
      this.ingredientsPage = ingredientsPage;
    },

    setIngredientsSearch(ingredientsSearch: string) {
      this.ingredientsSearch = ingredientsSearch;
    },

    updateIngredientsListAndResults(
      ingredients: BasfIngredient[],
      noOfResults: number
    ) {
      this.ingredientList = ingredients;
      this.noOfResults = noOfResults;
    },

    restoreFilters() {
      this.ingredientsAdvancedPref = JSON.parse(
        JSON.stringify(this.backupIngredientsAdvancedPref)
      );
    },

    setActiveTab(tabName: string) {
      this.activeTab = tabName;
    },

    setIngredientsAdvancedPref(
      ingredientsAdvancedPref: any,
      updateBackup = false
    ) {
      localStorage.setItem(
        'IngredientsAdvancedPref',
        JSON.stringify(ingredientsAdvancedPref)
      );
      this.ingredientsAdvancedPref = ingredientsAdvancedPref;

      if (updateBackup) {
        this.backupIngredientsAdvancedPref = ingredientsAdvancedPref;
      }
    },

    async getIngredientsAdvanced(reqObj: IngredientsAdvancedSearchReq) {
      const responseData = await fetchIngredientsAdvanced(reqObj);
      if (responseData) {
        this.updateIngredientsListAndResults(
          responseData.ingredients,
          responseData.noOfResults
        );
      }
      return {
        ingredients: responseData.ingredients,
        noOfResults: responseData.noOfResults,
      };
    },
  },
});
