/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { defineStore } from 'pinia';
import {
  ReferenceItem,
  TopBrand,
  TopBrandsAdvancedSearchPref,
  TopBrandsAdvancedSearchReq,
} from '@/api/types';
import { TopTopics, ConsumerInsightDates } from '@/typings/enums';
import { fetchTopBrands } from '@/api';

interface State {
  topBrandsAdvancedPref: TopBrandsAdvancedSearchPref;
  backupTopBrandsAdvancedPref: TopBrandsAdvancedSearchPref;
  topBrands: TopBrand[];
  sort: string;
  openedFilters: string[];
  fetchedFiltersData: boolean;
  topTopics: ReferenceItem[];
}

export const useTopBrandsStore = defineStore('topBrands', {
  state: (): State => ({
    topBrandsAdvancedPref: localStorage.getItem('TopBrandsAdvancedSearchPref')
      ? JSON.parse(localStorage.getItem('TopBrandsAdvancedSearchPref')!)
      : {
          params: [],
          categorySelection: [],
          subCategorySelection: [],
          regionSelection: [],
          dateSelection: [ConsumerInsightDates.LAST_MONTH],
          topicSelection: [TopTopics.ALL_NATURAL_PRODUCT],
        },
    backupTopBrandsAdvancedPref: localStorage.getItem(
      'TopBrandsAdvancedSearchPref'
    )
      ? JSON.parse(localStorage.getItem('TopBrandsAdvancedSearchPref')!)
      : {
          params: [],
          categorySelection: [],
          subCategorySelection: [],
          regionSelection: [],
          dateSelection: [ConsumerInsightDates.LAST_MONTH],
          topicSelection: [TopTopics.ALL_NATURAL_PRODUCT],
        },
    topBrands: [],
    sort: '',
    openedFilters: [],
    fetchedFiltersData: false,
    topTopics: [],
  }),

  getters: {
    appliedFilters: (state) => () => {
      return Object.entries(state.backupTopBrandsAdvancedPref)
        .filter(([key]) => key !== 'q' && key !== 'params')
        .reduce((acc, [_key, val]) => {
          acc.push(val);
          return acc;
        }, [])
        .flat()
        .sort();
    },

    newFilters: (state) => () => {
      return Object.entries(state.topBrandsAdvancedPref)
        .filter(([key]) => key !== 'q' && key !== 'params')
        .reduce((acc, [_key, val]) => {
          acc.push(val);
          return acc;
        }, [])
        .flat()
        .sort();
    },
  },

  actions: {
    setTopTopics(topics: ReferenceItem[]) {
      this.topTopics = topics;
    },

    setFetchedDataFilters(value: boolean) {
      this.fetchedFiltersData = value;
    },

    setOpenedFilters(filters: string[]) {
      this.openedFilters = filters;
    },

    setSort(sort: string) {
      this.sort = sort;
    },

    restoreFilters() {
      this.topBrandsAdvancedPref = JSON.parse(
        JSON.stringify(this.backupTopBrandsAdvancedPref)
      );
    },

    updateTopBrandsPreferences(
      topBrandsAdvancedPref: any,
      updateBackup = false
    ) {
      localStorage.setItem(
        'TopBrandsAdvancedSearchPref',
        JSON.stringify(topBrandsAdvancedPref)
      );
      this.topBrandsAdvancedPref = topBrandsAdvancedPref;

      if (updateBackup) {
        this.backupTopBrandsAdvancedPref = topBrandsAdvancedPref;
      }
    },

    async getTopBrands(reqObj: TopBrandsAdvancedSearchReq) {
      const responseData = await fetchTopBrands(reqObj);
      this.topBrands = responseData || [];
    },
  },
});
